import React from "react";
import InfoIcon from "../../../images/info.svg";
import "./BacktestingResultTable.css";
import {
  AccuracyData,
  BackTestingResultsTableProps,
  ForwardForcatResultsTableProps,
} from "../../../store/foreCastBackTestingDataSlice";
import { renderTableDynamicRow } from "../../../utils/functions";
import { ChartDataItem } from "../../../store/resultVisualizationExperimentation";
const uniqueKey = require("unique-key");
interface BackTestingRowData {
  rowData: string[];
  dataType: string;
  weekData: AccuracyData[];
}

interface ResultVisulizationBackTestingRowData {
  rowData: string[];
  dataType: string;
}

const ForwardForecastTable: React.FC<ForwardForcatResultsTableProps> = ({
  tableHeader,
  chartData,
  backTestingTableData,
  isResultVisulizationPage,
  resultVisulizationTableData,
  isExperimentationPage,
}) => {
  /**
   * Generate a finaly array to render inside table row from api response
   * @returns
   */
  const renderBackTestingTableData = () => {
    let tableData: BackTestingRowData[] = [];
    let dynamicHeaders: string[] = [];
    let isDataTypeFound = false;
    let weekCount = 0;
    let shouldWeekCounterStart = false;
 
    tableHeader?.forEach((item) => {
      if (item !== "DATA TYPE" && isDataTypeFound === false) {
        dynamicHeaders.push(item);
      } else if (item === "DATA TYPE") {
        isDataTypeFound = true;
        shouldWeekCounterStart = true;
      }
      if (shouldWeekCounterStart) {
        weekCount++;
      }
    });
    backTestingTableData?.forEach((row, index) => {
      tableData.push({
        rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
        weekData: [...row?.ltp?.statisticalForecastProduction],
        dataType: "Stat Forecast Production",
      });

      row?.ltp?.statisticalForecastProduction?.forEach(
        (dataForeast, dataBiasIndex) => {
          if (dataBiasIndex === 0) {
            tableData.push({
              rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
              weekData: [...row?.ltp?.statisticalForecastExperimentation],
              dataType: "StatisticalForecast Experimentation",
            });
          }
        }
      );

      row?.ltp?.historicalShipments?.forEach(
        (dataForeast, dataForcastIndex) => {
          if (dataForcastIndex === 0) {
            tableData.push({
              rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
              weekData: [...row?.ltp?.historicalShipments],
              dataType: "Historical Shipments",
            });
          }
        }
      );
    });

    return renderHtmlTableData(tableData,weekCount);
  };

  /**
   * Render the sub array week data from the parent array for backtesting
   * @param weekDataArray
   * @returns ReactElement
   */
  const getWeekData = (weekDataArray: AccuracyData[], weekCount: number): React.ReactElement[] => {
    const weekDataDifference = weekCount - weekDataArray?.length;
    for (let i = 0; i < weekDataDifference - 5; i++) {
      weekDataArray.push({
        weekNumber: "",
        value: 0,
      });
    }
    return weekDataArray?.map((item: AccuracyData, index: any) => {
      if (item?.weekNumber === "") {
        return (
          <td
            key={uniqueKey("key_")}
            className="backtestingResultDataRow border border-primary-border p-4"
          ></td>
        );
      } else {
        return (
          <td
            key={uniqueKey("key_")}
            className="backtestingResultDataRow border border-primary-border p-4"
          >
            {item?.value}
          </td>
        );
      }
    });
  };

  const renderHtmlTableData = (tableData: BackTestingRowData[], weekCount: number) => {
    if (tableData.length === 0) {
      return (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          <td
            key={`${uniqueKey("key_backtesting_rowdata")}`}
            className="border border-primary-border p-4 text-center"
            colSpan={tableHeader?.length}
          >
            No Record Found
          </td>
        </tr>
      );
    } else {
      return tableData?.map((data: BackTestingRowData) => (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          {data?.dataType === "Stat Forecast Production"
            ? data?.rowData?.map((item) => {
                return (
                  <td
                    key={`${uniqueKey("key_backtesting_rowdata")}`}
                    className="border border-primary-border p-4"
                  >
                    {item}
                  </td>
                );
              })
            : data?.rowData?.map(() => {
                return (
                  <td
                    key={`${uniqueKey("key_backtesting_rowdata-empty")}`}
                    className="border border-primary-border p-4"
                  ></td>
                );
              })}
          <td className="backtestingResultDataRow border border-primary-border p-4">
            {data?.dataType}
          </td>

          {getWeekData(data?.weekData, weekCount)}
        </tr>
      ));
    }
  };

  const retrunTheExperinmentData = (data: ChartDataItem, dataType: string) => {
    if (dataType === "Forecast Accuracy") {
      return data?.accuracy;
    }
    if (dataType === "Bias") {
      return data?.bias;
    }
    if (dataType === "Statistical Forecast") {
      return data?.statisticalForecast;
    }
    if (dataType === "Historical Shipments") {
      return data?.historicalShipment;
    }
    return "";
  };

  const renderResultVisulizationExperinementHtmlTableData = (
    tableData: ChartDataItem[],
    dataType: string,
    isEmptyCell: boolean
  ) => {
    if (tableData.length === 0) {
      return (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          <td
            key={`${uniqueKey("key_backtesting_rowdata")}`}
            className="border border-primary-border p-4 text-center"
            colSpan={tableHeader?.length}
          >
            No Record Found
          </td>
        </tr>
      );
    } else {
      return tableData?.map((data: ChartDataItem, index) => (
        <>
          {index === 0 && (
            <>
              <td className="border border-primary-border bg-zinc-700 p-4">
                <div className="flex items-center">
                  {!isEmptyCell && (
                    <>
                      <img src={InfoIcon} alt="info-img" />
                      <span className="pl-2">{dataType}</span>
                    </>
                  )}
                </div>
              </td>

              <td
                key={`${uniqueKey("key_backtesting_rowdata")}`}
                className="border border-primary-border bg-zinc-700 p-4"
              >
                {data?.experimentId}
              </td>
            </>
          )}
          <td
            key={`${uniqueKey("key_backtesting_rowdata")}`}
            className="border border-primary-border bg-zinc-700 p-4"
          >
            {retrunTheExperinmentData(data, dataType)}
          </td>
        </>
      ));
    }
  };

  const renderChartDataTable = () => {
    return renderResultVisulizationExperimentChartDataTable();
  };

  const renderResultVisulizationExperimentChartDataTable = () => {
    const statisticalForecastProduction: ChartDataItem[] = [];
    const statisticalForecastProductionRun: ChartDataItem[] = [];
    const historicalShipmentProduction: ChartDataItem[] = [];
    const historicalShipmentProductionRun: ChartDataItem[] = [];

    resultVisulizationTableData?.forEach((item, index) => {
      if (item.experimentId === "ProductionRun") {
        statisticalForecastProductionRun.push(item);
        historicalShipmentProductionRun.push(item);
      } else {
        statisticalForecastProduction.push(item);
        historicalShipmentProduction.push(item);
      }
    });

    return (
      <table className="text-xs font-bold">
        <tbody>
          {statisticalForecastProductionRun?.length > 0 && (
            <tr
              key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
              className="bg-ternary-bg"
            >
              {renderResultVisulizationExperinementHtmlTableData(
                statisticalForecastProductionRun,
                "Statistical Forecast",
                false
              )}
            </tr>
          )}
          {statisticalForecastProduction?.length > 0 && (
            <tr
              key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
              className="bg-ternary-bg"
            >
              {renderResultVisulizationExperinementHtmlTableData(
                statisticalForecastProduction,
                "Statistical Forecast",
                true
              )}
            </tr>
          )}
          {historicalShipmentProductionRun?.length > 0 && (
            <tr
              key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
              className="bg-ternary-bg"
            >
              {renderResultVisulizationExperinementHtmlTableData(
                historicalShipmentProductionRun,
                "Historical Shipments",
                false
              )}
            </tr>
          )}
          {historicalShipmentProduction?.length > 0 && (
            <tr
              key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
              className="bg-ternary-bg"
            >
              {renderResultVisulizationExperinementHtmlTableData(
                historicalShipmentProduction,
                "Historical Shipments",
                true
              )}
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <div className="p-6 mt-4 text-white table-holder-main">
      <div className="btr-table-holder">
        {renderChartDataTable()}
        {tableHeader?.length > 0 ? (
          <table className="text-xs font-bold table-auto">
            <tbody>
              <tr className="bg-ternary-bg">
                {tableHeader?.map((data) => {
                  return (
                    <td
                      key={`${uniqueKey("key_")}-forecast-backtesting-headers`}
                      className="backtestingResultDataRow border border-primary-border p-4 "
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
              {renderBackTestingTableData()}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
};

export default ForwardForecastTable;
