import { Series } from "./RcaSummaryCards";

const uniqueKey = require("unique-key");

interface KeymapProps {
    series: Series[]
  }

const KeyMap:React.FC<KeymapProps> = ({series}) => {
    
    return (
        <div style={{background:"#272727",padding:'15px',borderRadius:'8px',height:'100%'}} >
            <div className="text-xs bold">
                Key
            </div>
            <div>
                {
                series[0]?.data?.map((item)=> 
                     <div key={uniqueKey()} className="flex flex-row mt-6">
                        <div style={{width:'15px', height:'15px', background:item.color ,marginTop:'1px',marginRight:'5px'}}>
                        </div>
                        <div className="text-xs">
                            {item.x}
                        </div>   
                     </div>
                )
                }
            </div>
        </div>
    )
}

export default KeyMap