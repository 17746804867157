import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";

interface BarStackedChartProps {
  series: ApexOptions["series"];
  categories: string[];
}

const BarStackedChart: React.FC<BarStackedChartProps> = ({
  series,
  categories,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const options: ApexOptions = {
        series: series,
        chart: {
          height: 300,
          stacked: true,
          background: "#1D1D1D",
          zoom: {
            enabled: true,
            type: "xy",
            zoomedArea: {
              fill: {
                color: "#90CAF9",
                opacity: 0.4,
              },
              stroke: {
                color: "#90CAF9",
                opacity: 0.4,
                width: 1,
              },
            },
          },
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          hideEmptySeries: true,
          fillSeriesColor: false,
          theme: 'dark',
          style: {
            fontSize: '12px',
            fontFamily: undefined,
          },
          onDatasetHover: {
            highlightDataSeries: false,
          },
          x: {
            show: true,
            format: 'dd MMM',
            formatter: undefined,
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
          z: {
            formatter: undefined,
            title: 'Size: ',
          },
          marker: {
            show: true,
          },
          items: {
            display: 'flex',
          },
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        stroke: {
          width: [0, 0, 0, 0, 0, 0, 2],
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: categories,
          labels: {
            style: {
              colors: "white",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "white",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          labels: {
            colors: "white",
          },
        },
        grid: {
          borderColor: "#616161",
        },
        theme: {
          mode: "dark",
        },
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [series, categories]);

  return <div className="pt-3" ref={chartRef}></div>;
};

export default BarStackedChart;
