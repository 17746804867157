import { mapperForGroupByLableToKey } from "../api/forecastPageApi";
import { SIDEBAR_ROUTE_VALUES } from "./constant";

/**
 * Return the array of table data
 * @param rowColumnList
 * @param row
 * @returns string[]
 */
export const renderTableDynamicRow = (
  rowColumnList: string[],
  row: any
): string[] => {
  let rowData: string[] = [];
  let getRowKeys = mapperForGroupByLableToKey(rowColumnList);
  getRowKeys?.selectedGroupByOptions.pop();
  getRowKeys?.selectedGroupByOptions?.forEach((item: string) => {
    let itemFormat = item.replace(item[0], item[0].toLowerCase());
    rowData.push(row[itemFormat]);
  });
  return rowData;
};

export const findAndSortCurrentPathSnapshotDates = (
  data: {
    module: string;
    snapshotDates: string[];
  }[],
  currentPath: string
) => {
  type SidebarRouteKeys = keyof typeof SIDEBAR_ROUTE_VALUES;

  let currentPathData = data?.find(
    (i) => i.module === SIDEBAR_ROUTE_VALUES?.[currentPath as SidebarRouteKeys]
  );

  const sortedDates = currentPathData?.snapshotDates.sort(
    (a: string, b: string) => {
      return new Date(b).getTime() - new Date(a).getTime();
    }
  );

  return sortedDates;
};
