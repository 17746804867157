import { useEffect, useState } from "react";
import sortUp from "../../images/sortUp.svg";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Typography,
  createTheme,
  ThemeProvider,
  Popover,
  Tooltip,
} from "@mui/material";
import "./RcaTable.css";
import { Pagination } from "../../components/Pagination";
import SelectableList from "./PopOverComp";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { rcaWriteBackApi } from "../../api/forecastPageApi";
import { useOktaAuth } from "@okta/okta-react";
import { updateExecutionBackTestingPageNumber } from "../../store/rcaSlice";
import loadingSvg from "../../images/bouncing-circles.svg";
const uniqueKey = require("unique-key");

type DataRow = {
  upc: string;
  account: string;
  region: string;
  rca: string;
  finalRca: string;
  pfeAccuracy: number;
  pfeBias: number;
  forecast: number;
  error: number;
  dpAccuracy: number;
  id: string;
  absError: number;
};

type SortKey = keyof DataRow;

// Custom Toast component
const Toast = ({
  message,
  type,
  onClose,
}: {
  message: string;
  type: "success" | "error";
  onClose: () => void;
}) => {
  const toastClass =
    type === "success"
      ? "bg-secondary-bg outline outline-green-500"
      : "bg-secondary-bg outline outline-red-500";

  return (
    <div
      className={`fixed top-4 right-4 p-4 rounded shadow ${toastClass}`}
      style={{ zIndex: 1000 }}
    >
      <span>{message}</span>
      <button
        onClick={onClose}
        className="ml-4 font-bold text-xl focus:outline-none"
      >
        &times;
      </button>
    </div>
  );
};

const RCATable = () => {
  const [data, setTableData] = useState<any>([]);
  const [listOverride, setListItems] = useState<any>([
    { id: 1, label: "Select Override" },
  ]);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [sortKey, setSortKey] = useState<SortKey | null>(null);
  const [ascending, setAscending] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectValue, setSelectValue] = useState<{ [key: string]: string }>({});

  // Toast state for notifications
  const [toast, setToast] = useState<{
    visible: boolean;
    message: string;
    type: "success" | "error" | "";
  }>({ visible: false, message: "", type: "" });

  const tableData = useSelector((state: RootState) => state.rcaData.tableData);
  const rcaStats = useSelector((state: RootState) => state.rcaData.rcaStats);
  const isTableLoading = useSelector(
    (state: RootState) => state.rcaData.isTableDataLoading
  );
  const filter = useSelector((state: RootState) => state.rcaFilter);
  const { authState } = useOktaAuth();
  const rcaExecutionPaginationSlice = useSelector(
    (state: RootState) => state.rcaExecutionPaginationSlice
  );
  const dispatch = useDispatch();

  const showToast = (message: string, type: "success" | "error") => {
    setToast({ visible: true, message, type });
    setTimeout(() => {
      setToast({ visible: false, message: "", type: "" });
    }, 5000);
  };

  const extractTableData = async () => {
    const selectedRowsData = selectedRows.map((index) => sortedData[index]);

    const dropdownRowsData = sortedData.filter((row: any) => {
      const override = selectValue[row.id];
      return override && override !== "Select Override";
    });

    const uniqueRowsMap: { [id: string]: any } = {};
    selectedRowsData.forEach((row) => {
      uniqueRowsMap[row.id] = row;
    });
    dropdownRowsData.forEach((row: any) => {
      uniqueRowsMap[row.id] = row;
    });
    const rowsToProcess = Object.values(uniqueRowsMap);

    const totalApiCalls = rowsToProcess.length;

    const extractedData = rowsToProcess.map((row: any) => ({
      materialGlobalTradeItemNumber: row.upc,
      demandPlanningKeyAccountId: row.account,
      salesRegionCode: row.region,
      forecastTargetDate: row.snapshotDate
        ? [row.snapshotDate].toString()
        : null,
      shortTermMidTermCode: filter?.term.toString(),
      rcaStatOverride: selectValue[row.id] || null,
    }));

    for (const data of extractedData) {
      try {
        const response = await new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              const res = await rcaWriteBackApi(data, authState);
              resolve(res);
            } catch (err) {
              reject(err);
            }
          }, 1000);
        });

        // First, check if the response object has a code of 400.
        if (
          response &&
          typeof response === "object" &&
          "code" in response &&
          (response as any).code === 400
        ) {
          console.error("Write-back API call failed with code 400");
          showToast("No value selected for write back", "error");
          continue; // Skip further processing for this iteration.
        }

        // Extract a response message to check for failure conditions.
        let responseMessage = "";
        if (typeof response === "string") {
          responseMessage = response;
        } else if (
          response &&
          typeof response === "object" &&
          "message" in response
        ) {
          responseMessage = (response as any).message;
        }

        // If the message contains "trace id", consider it a failure.
        if (responseMessage.toLowerCase().includes("trace id")) {
          console.error("Write-back API call failed:", responseMessage);
          showToast(
            `Error in write-back API call: ${responseMessage}`,
            "error"
          );
        } else {
          showToast("Write-back API call succeeded", "success");
        }
      } catch (error: any) {
        console.error("Error in write-back API call:", error);
        showToast(`Error: ${error.message || error}`, "error");
      }
    }
  };

  const extractRcaNames = (data: any): any => {
    const uniqueRcaNames = new Set<string>();
    data?.forEach((item: any) => {
      uniqueRcaNames.add(item);
    });
    return Array.from(uniqueRcaNames).map((name, index) => ({
      id: (index + 1).toString(),
      label: name,
    }));
  };

  const transformResponseData = (responseData: any) => {
    return responseData?.map((item: any) => ({
      upc: item.materialGlobalTradeItemNumber,
      account: item.demandPlanningKeyAccountId,
      region: item.salesRegionCode,
      rca: item.rcaStat,
      finalRca: item.finalRcaName,
      pfeAccuracy: item.pfeAccuracy,
      pfeBias: item.pfeBias,
      forecast: Math.trunc(item.statisticalForecast),
      snapshotDate: item?.snapshotDate,
      absError: item?.absoluteErrorQuantity,
      id: uniqueKey(),
    }));
  };

  const sortData = (key: SortKey, ascending: boolean) => {
    return [...data].sort((a, b) => {
      if (ascending) {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });
  };

  const sortedData = sortKey ? sortData(sortKey, ascending) : data;

  useEffect(() => {
    setTableData(transformResponseData(tableData));
    setListItems(extractRcaNames(rcaStats));
  }, [tableData]);

  const theme = createTheme({
    palette: {
      background: { paper: "#272727" },
      text: { primary: "#ffff" },
    },
  });

  const handleSelectChange = (id: string, event: any) => {
    setSelectValue((prev) => ({
      ...prev,
      [id]: event?.target?.value,
    }));
  };

  const handleUpdate = (selectedValue: string | null) => {
    if (!selectedValue) return;
    const updatedValues = { ...selectValue };
    selectedRows.forEach((index) => {
      const rowId = data[index]?.id;
      if (rowId) {
        updatedValues[rowId] = selectedValue;
      }
    });
    setSelectValue(updatedValues);
    setAnchorEl1(null);
  };

  const handleSort = (key: SortKey) => {
    setAscending(sortKey === key ? !ascending : true);
    setSortKey(key);
  };

  const getColor = (value: number) => {
    if (value <= 70) return "bg-red-700 text-white";
    if (value >= 78) return "bg-green-bg text-white";
    return "bg-yellow-bg text-white";
  };

  const renderSortIcon = (key: SortKey) => {
    if (sortKey === key) {
      return ascending ? (
        <img src={sortUp} alt="sortUp" className="ml-2 w-3 h-3" />
      ) : (
        <img src={sortUp} alt="sortDown" className="ml-2 w-3 h-3" />
      );
    } else {
      return <img src={sortUp} alt="sortDown" className="ml-2 w-3 h-3" />;
    }
  };

  const handleCheckboxChange = (index: number) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((row) => row !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const handlePageNumberUpdateEvent = (currentPageNumber: number) => {
    dispatch(
      updateExecutionBackTestingPageNumber({
        pageLimit: rcaExecutionPaginationSlice?.pageLimit,
        pageNumber: currentPageNumber,
        totalPages: rcaExecutionPaginationSlice?.totalPages,
        isDataShownClicked: false,
        groupBySelectedField: [],
      })
    );
  };

  return (
    <div className="mt-4">
      {isTableLoading ? (
        <div className="flex justify-center">
          <img
            src={loadingSvg}
            alt="loading"
            className="mt-10"
            width={40}
            height={40}
          />
        </div>
      ) : (
        <div className="bg-ternary-bg text-white p-6 pb-10 rounded-lg shadow-lg">
          <div className="pb-4 flex justify-end items-center">
            <div className="flex space-x-2">
              <div className="pt-3 mr-3">
                <Typography variant="h6">Add</Typography>
              </div>
              <ThemeProvider theme={theme}>
                <div
                  style={{
                    minWidth: "195px",
                    backgroundColor: "#313131",
                    borderRadius: "100px",
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <Tooltip
                    title={
                      selectedRows.length > 0 ? "" : "Select rows to enable"
                    }
                  >
                    <Typography
                      onClick={(event) => {
                        if (selectedRows.length > 0) {
                          setAnchorEl1(event.currentTarget);
                        }
                      }}
                      className="p-4"
                      sx={{
                        color: selectedRows.length > 0 ? "#fff" : "#616161",
                        cursor:
                          selectedRows.length > 0 ? "pointer" : "not-allowed",
                        display: "block",
                        border: "none",
                      }}
                    >
                      {"RCA Stat Override"}
                    </Typography>
                  </Tooltip>

                  <Popover
                    open={Boolean(anchorEl1)}
                    anchorEl={anchorEl1}
                    onClose={() => setAnchorEl1(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <SelectableList
                      items={listOverride}
                      onCancel={() => setAnchorEl1(null)}
                      onUpdate={handleUpdate}
                    />
                  </Popover>
                </div>

                <div className="flex justify-end">
                  <button
                    className="write-back-button"
                    onClick={extractTableData}
                    disabled={
                      Object.keys(selectValue).length === 0 ||
                      Object.values(selectValue).every(
                        (value) => value === "Select Override"
                      )
                    }
                  >
                    Save
                  </button>
                </div>
              </ThemeProvider>
            </div>
          </div>

          {tableData?.length > 0 ? (
            <div className="RCA-table-holder">
              <table className="text-xs">
                <thead>
                  <tr className="bg-ternary-bg">
                    <th
                      className="border border-primary-border"
                      style={{ width: "40px" }}
                    >
                      <Checkbox
                        sx={{ color: "white", padding: "0px", width: "20px" }}
                        onChange={() => {
                          if (selectedRows?.length === sortedData?.length) {
                            setSelectedRows([]);
                          } else {
                            setSelectedRows(
                              sortedData?.map((_: DataRow, i: number) => i)
                            );
                          }
                        }}
                        checked={selectedRows?.length === sortedData?.length}
                      />
                    </th>
                    <th className="px-4 py-4 border border-primary-border">
                      UPC
                    </th>
                    <th className="px-4 py-4 border border-primary-border">
                      KEY ACCOUNT
                    </th>
                    <th className="px-4 py-4 border border-primary-border">
                      SALES REGION
                    </th>
                    <th className="px-4 py-4 border border-primary-border">
                      RCA STAT
                    </th>
                    <th className="px-4 py-4 border border-primary-border">
                      RCA STAT OVERRIDE
                    </th>
                    <th className="px-4 py-4 border border-primary-border">
                      RCA STAT FINAL
                    </th>
                    <th
                      className="px-4 py-4 border border-primary-border cursor-pointer"
                      onClick={() => handleSort("pfeAccuracy")}
                    >
                      <div className="flex items-center justify-between">
                        (PFE) FORECAST ACCURACY STAT{" "}
                        {renderSortIcon("pfeAccuracy")}
                      </div>
                    </th>
                    <th
                      className="px-4 py-4 border border-primary-border cursor-pointer"
                      onClick={() => handleSort("pfeBias")}
                    >
                      <div className="flex items-center justify-between">
                        (PFE) BIAS {renderSortIcon("pfeBias")}
                      </div>
                    </th>
                    <th className="px-4 py-4 border border-primary-border">
                      ABS ERROR
                    </th>
                    <th className="px-4 py-4 border border-primary-border">
                      STATISTICAL FORECAST
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((row: DataRow, idx: number) => {
                    const rowId = row?.id;
                    return (
                      <tr key={rowId} className="border border-gray-200">
                        <td
                          className="border border-primary-border"
                          style={{ width: "40px" }}
                        >
                          <Checkbox
                            sx={{
                              color: "white",
                              padding: "0px",
                              width: "20px",
                              height: "20px",
                            }}
                            checked={selectedRows.includes(idx)}
                            onChange={() => handleCheckboxChange(idx)}
                          />
                        </td>
                        <td className="px-4 py-4 border border-primary-border">
                          {row.upc}
                        </td>
                        <td className="px-4 py-4 border border-primary-border">
                          {row.account}
                        </td>
                        <td className="px-4 py-4 border border-primary-border">
                          {row.region}
                        </td>
                        <td className="px-4 py-4 border border-primary-border">
                          {row.rca}
                        </td>
                        <td className="border border-primary-border">
                          <ThemeProvider theme={theme}>
                            <FormControl
                              fullWidth
                              sx={{
                                minWidth: "80px",
                                backgroundColor: "#313131",
                                borderRadius: "0",
                              }}
                            >
                              <Select
                                value={selectValue[rowId] || "Select Override"}
                                onChange={(event) =>
                                  handleSelectChange(rowId, event)
                                }
                                sx={{
                                  height: "38px",
                                  "& .MuiInputBase-root": { color: "white" },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#616161",
                                  },
                                  "& .MuiSvgIcon-root": { color: "white" },
                                }}
                              >
                                <MenuItem
                                  value="Select Override"
                                  key="default-menu-item"
                                >
                                  Select Override
                                </MenuItem>
                                {listOverride?.map((item: any) => (
                                  <MenuItem
                                    value={item?.label.toString()}
                                    id={item?.id}
                                    key={`${uniqueKey("key_")}-menu-item-rca`}
                                  >
                                    {item?.label.toString()}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </ThemeProvider>
                        </td>
                        <td className="px-4 py-4 border border-primary-border">
                          {row.finalRca}
                        </td>
                        <td
                          className={`px-4 py-4 border border-primary-border ${getColor(
                            row.pfeAccuracy
                          )}`}
                        >
                          {row.pfeAccuracy}%
                        </td>
                        <td className="px-4 py-4 border border-primary-border">
                          {row.pfeBias}%
                        </td>
                        <td className="px-4 py-4 border border-primary-border">
                          {row.absError}
                        </td>
                        <td className="px-4 py-4 border border-primary-border">
                          {row.forecast}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-ternary-bg rounded p-2">
              <div className="flex justify-center">
                <p className="p-2 m-1">
                  No Data to show or no options selected, please choose from the
                  above filters.
                </p>
              </div>
            </div>
          )}
          {tableData?.length > 0 ? (
            <div className="mt-8 flex justify-center">
              <div className="pt-2">
                <Pagination
                  totalPages={rcaExecutionPaginationSlice?.totalPages}
                  activePageNumber={rcaExecutionPaginationSlice?.pageNumber}
                  updateBackTestingPageNumber={(data) =>
                    handlePageNumberUpdateEvent(data)
                  }
                />
              </div>
            </div>
          ) : null}
        </div>
      )}
      {/* Render Toast Notification */}
      {toast.visible && (
        <Toast
          message={toast.message}
          type={toast.type as "success" | "error"}
          onClose={() => setToast({ visible: false, message: "", type: "" })}
        />
      )}
    </div>
  );
};

export default RCATable;
