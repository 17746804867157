import RcaExecutionFilter from "../../components/RcaFilters/RcaExecutionFilter"
import RcaSummaryCards from "./RcaSummaryCards/RcaSummaryCards"
import RcaSummaryTable from "./RcaTableSummary"

const RcaSummary = () => {
  return (
    <div>
      <RcaExecutionFilter />
      <RcaSummaryCards />
      <RcaSummaryTable />
    </div>
  )
}

export default RcaSummary