// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #616161;
  background-color: #2c2c2c;
  max-width: fit-content;
}

.arrow-btn {
  background-color: transparent;
  border-radius: 4px;
  color: #0069d7;
  font-size: 18px;
  cursor: pointer;
  padding: 10px 15px;
}

.separator {
  width: 1px;
  height: 40px;
  background-color: #616161;
}

.page {
  background-color: #2c2c2c;
  color: #0069d7;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.page.active {
  background-color: #0069d7;
  color: white;
}

.page:hover {
  background-color: #3c3c3c;
  color: #0069d7;
}

.arrow-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: #3c3c3c;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pagination/pagination.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".pagination {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 4px;\n  border: 1px solid #616161;\n  background-color: #2c2c2c;\n  max-width: fit-content;\n}\n\n.arrow-btn {\n  background-color: transparent;\n  border-radius: 4px;\n  color: #0069d7;\n  font-size: 18px;\n  cursor: pointer;\n  padding: 10px 15px;\n}\n\n.separator {\n  width: 1px;\n  height: 40px;\n  background-color: #616161;\n}\n\n.page {\n  background-color: #2c2c2c;\n  color: #0069d7;\n  padding: 10px 20px;\n  font-weight: bold;\n  font-size: 16px;\n  cursor: pointer;\n}\n\n.page.active {\n  background-color: #0069d7;\n  color: white;\n}\n\n.page:hover {\n  background-color: #3c3c3c;\n  color: #0069d7;\n}\n\n.arrow-btn:disabled {\n  opacity: 0.5;\n  cursor: not-allowed;\n  color: #3c3c3c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
