import React from 'react';
import { CircularProgress } from '@mui/material';

const PageLoader: React.FC = () => {
  return (
    <div className="page-loader-container">
        <CircularProgress className="page-loader"/>
      </div>
  );
};

export default PageLoader;