import ComparisonResultExperimentation from "./ComparisonResultExperimentation"
import ResultVisualizationExperimentalFilter from "../../components/ResultVisualizationFilter/ResultVisualizationExperimentalFilter"
import TimeCardComponent from "../StatisticalForecastAnalysis/Timecard/TimeCardComponent"
import ExperimentationId from "./ExperimentationCard";
import ResultVisulizationExperimentBacktestingResults from "../../components/BackTesting/ResultVisulizationBackTesting/ResultVisulizationExperimentBacktestingResults";
import ResultVisulizationExperimentForwardForecast from "../../components/BackTesting/ResultVisulizationBackTesting/ResultVisulizationExperimentForwardForecast";
const Experimentation = () => {

  return (
    <>
      <div className="flex space-x-3 mt-5">
        <div style={{ width: '500px' }}>
          <TimeCardComponent lag={false} />
        </div>
        <div className="flex-auto">
          <ExperimentationId />
        </div>
      </div>
      <ResultVisualizationExperimentalFilter />
      <ComparisonResultExperimentation />
      <div className="mt-2">
        <ResultVisulizationExperimentBacktestingResults widthColumn="12%" isResultVisulizationPage={false} isExperimentationPage={true} />
      </div>
      <div className="mt-2">
        <ResultVisulizationExperimentForwardForecast widthColumn="12%" isResultVisulizationPage={false} isExperimentationPage={true} />
      </div>
      {/* <div className="mt-10">
        <ForecastAnalysis />
      </div> */}
    </>
  );
};

export default Experimentation;
