import { useQuery } from "@tanstack/react-query";
import { postAPI } from "../API";

const fetchUserData = async (authToken: string, endpoint: string, data: {}) => {
  try {
    const response = await postAPI(
      `${endpoint}`,
      data,
      authToken
    );
    if (response.status === 200) {
      return response?.data?.response;
    }
  } catch (error) {
    console.error(`Error fetching ${endpoint} data:`, error);
  }
};

export const useUserApiData = (authToken: string, endpoint: string, postData:{}) => {
  return useQuery({
    queryKey: ["userData", postData],
    queryFn: () => fetchUserData(authToken, endpoint, postData),
    staleTime: 600000,
  });
};

