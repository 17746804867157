import { Box, Modal } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackTestingAnalytics from "../../components/BackTesting/BackTestingAnalytics/BackTestingAnalytics";
import BacktestingResultTable from "../../components/BackTesting/BacktestingResultTable/BacktestingResultTable";
import LineColumnChart from "../../components/chart/LineColumn/LineColumnChart";
import { DataShownDialog } from "../../components/Datashow";
import arrowLogo from "../../images/arrow.svg";
import closeButton from "../../images/closeButton.svg";
import enlargeButton from "../../images/enlargeButton.svg";
import { borrowFiltersFromForecast } from "../../store/filterSlice";
import { RootState } from "../../store/store";
import loadingSvg from "../../images/bouncing-circles.svg";
import settingsIcon from "../../images/Settings.svg";
import {
  BackTestingChartData,
  BacktestingResultsProps,
  ChartData,
  foreCastBackTestingTableDataSetter,
  updateBackTestingTotalPages,
  updateBackTestingPageNumber,
} from "../../store/foreCastBackTestingDataSlice";
import { Pagination } from "../../components/Pagination";
import {
  calculateTotalPages,
  forecastBackTestingDefaultGroupBy,
  forecastBackTestingTableApi,
  mapperForGroupByLableToKey,
} from "../../api/forecastPageApi";
import { useOktaAuth } from "@okta/okta-react";
import { FIRST_PAGE, PAGE_LIMIT } from "../../utils/constant";

interface ChartDataset {
  name: string;
  data: number[];
  color: string;
  type?: string;
}

function BacktestingResults({
  widthColumn,
  isResultVisulizationPage,
  isExperimentationPage,
}: BacktestingResultsProps) {
  type DataPoint = {
    accuracy: number;
    bias: number;
    statisticalForecast: number;
    historicalShipment: number;
    timeFrameDate: string;
    periodWeekNumber: string;
    experimentId: string;
  };
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [expChartData, setChartDataExp] = useState<any>([]);
  const [expChartDataLabels, setChartDataExpLabels] = useState<any>([]);
  const [chartDataPredefined, setChartDataPreDefined] = useState<any>([]);
  const [chartDataPredefinedLabels, setChartDataPreDefinedLabels] =
    useState<any>([]);
  const { authState } = useOktaAuth();
  const [selectedDataShownOptionList, setSelectedDataShownOptionList] =
    useState<string[]>(forecastBackTestingDefaultGroupBy);
  const [selectedDataShownCategoryList, setSelectedDataShownCategoryList] =
    useState<string[]>([]);
  const foreCastBackTestingTableData = useSelector(
    (state: RootState) => state.foreCastBackTestingTableDataSlice
  );
  const foreCastBackTestingChartData = useSelector(
    (state: RootState) => state.foreCastBackTestingChartDataSlice
  );
  const foreCastBackTestingAverageData = useSelector(
    (state: RootState) => state.foreCastBackTestingAverageDataSlice
  );
  const foreCastBackTestingDataLoading = useSelector(
    (state: RootState) => state.foreCastBackTestingDataLoading
  );

  const resultVisualizationData = useSelector(
    (state: RootState) => state.resultVisualizationData
  );

  const expData = useSelector(
    (state: RootState) => state.resultVisualizationExperimentationData
  );

  const [openSettings, setOpenSettings] = useState(false);
  const [backTesingChartData, setBackTestingChartData] =
    useState<BackTestingChartData>({
      backTestingSeriesData: [],
      backTestingChartLabels: [],
    });
  const navigate = useNavigate();
  const filter = useSelector((state: RootState) => state.forecastFilter);

  const backTestingPagination = useSelector(
    (state: RootState) => state.foreCastBackTestingPagination
  );

  const extractData = (response: any, index: number, key: string) => {
    return (
      response?.[index]?.data?.map((item: any) => item?.[key] ?? null) ?? []
    );
  };

  const buildLabelsExp = (response: any[]) => {
    const labels = Array.from(
      new Set(response.map((item: any) => item?.periodWeekNumber))
    );

    return labels.sort((a: string, b: string) => {
      const [periodA, weekA, yearA] = a
        .match(/P(\d+)W(\d+) (\d{4})/)
        ?.slice(1, 4) ?? ["0", "0", "0"];
      const [periodB, weekB, yearB] = b
        .match(/P(\d+)W(\d+) (\d{4})/)
        ?.slice(1, 4) ?? ["0", "0", "0"];

      const periodNumA = parseInt(periodA, 10);
      const weekNumA = parseInt(weekA, 10);
      const yearNumA = parseInt(yearA, 10);

      const periodNumB = parseInt(periodB, 10);
      const weekNumB = parseInt(weekB, 10);
      const yearNumB = parseInt(yearB, 10);

      if (yearNumA !== yearNumB) return yearNumA - yearNumB;
      if (periodNumA !== periodNumB) return periodNumA - periodNumB;
      return weekNumA - weekNumB;
    });
  };

  const buildLabels = (response: any) => {
    const labels = Array.from(
      new Set(
        response.flatMap((rank: any) =>
          rank?.data?.map((item: any) => item?.periodWeekNumber)
        )
      )
    );

    return labels.sort((a: any, b: any) => {
      const periodA = parseInt(a.match(/P(\d+)/)?.[1] ?? "0", 10);
      const weekA = parseInt(a.match(/W(\d+)/)?.[1] ?? "0", 10);
      const periodB = parseInt(b.match(/P(\d+)/)?.[1] ?? "0", 10);
      const weekB = parseInt(b.match(/W(\d+)/)?.[1] ?? "0", 10);

      return periodA === periodB ? weekA - weekB : periodA - periodB;
    });
  };

  useEffect(() => {}, [expData?.chartData]);

  useEffect(() => {
    function extractExpChartData(data: DataPoint[]) {
      return [
        {
          name: "Statistical Forecast Production",
          data: data
            .filter((d) => d.experimentId === "Production")
            .map((d) => d.statisticalForecast),
          color: "#B992EB",
          type: "line",
        },
        {
          name: "Statistical Forecast Experiment",
          data: data
            .filter((d) => d.experimentId === "Production 1")
            .map((d) => d.statisticalForecast),
          color: "#66C4EE",
          type: "line",
        },
        {
          name: "Historical Shipment (Adjusted)",
          data: data.map((d) => d.historicalShipment),
          color: "#FFA800",
          type: "line",
        },
        {
          name: "Forecast Accuracy Production",
          data: data
            .filter((d) => d.experimentId === "Production")
            .map((d) => d.accuracy),
          color: "#6E3BB2",
          type: "bar",
        },
        {
          name: "Forecast Accuracy Experiment",
          data: data
            .filter((d) => d.experimentId === "Production 1")
            .map((d) => d.accuracy),
          color: "#33B1E8",
          type: "bar",
        },
      ];
    }
    setChartDataExp(extractExpChartData(expData?.chartData));
    setChartDataExpLabels(buildLabelsExp(expData?.chartData));
  }, [expData?.chartData]);

  useEffect(() => {
    const createChartData = (
      response: any
    ): { datasets: ChartDataset[]; labels: string[] } => {
      const datasets: ChartDataset[] = [
        {
          name: "Top Recipe",
          data: extractData(response, 0, "accuracy"),
          color: "#B992EB",
          type: "line",
        },
        {
          name: "Rank 2",
          data: extractData(response, 1, "accuracy"),
          color: "#33B1E8",
          type: "line",
        },
        {
          name: "Rank 3",
          data: extractData(response, 2, "accuracy"),
          color: "#994900",
          type: "line",
        },
        {
          name: "Top Recipe Bias",
          data: extractData(response, 0, "bias"),
          color: "#B992EB",
          type: "bar",
        },
        {
          name: "Rank 2 Bias",
          data: extractData(response, 1, "bias"),
          color: "#33B1E8",
          type: "bar",
        },
        {
          name: "Rank 3 Bias",
          data: extractData(response, 2, "bias"),
          color: "#994900",
          type: "bar",
        },
      ];

      const labels: any = buildLabels(response);

      return { datasets, labels };
    };
    if (resultVisualizationData?.chartData?.length > 0) {
      const { datasets, labels } = createChartData(
        resultVisualizationData?.chartData
      );
      setChartDataPreDefined(datasets);
      setChartDataPreDefinedLabels(labels);
    }
  }, [resultVisualizationData?.chartData]);

  useEffect(() => {
    if (foreCastBackTestingChartData?.chartData?.length > 0) {
      const forecastAccuracyChartData =
        foreCastBackTestingChartData?.chartData?.map(
          (data: ChartData) => data.accuracy
        );
      const statisticalForecastChartData =
        foreCastBackTestingChartData?.chartData?.map(
          (data: ChartData) => data.statisticalForecast
        );
      const historicalShipmentChartData =
        foreCastBackTestingChartData?.chartData?.map(
          (data: ChartData) => data.historicalShipment
        );
      setBackTestingChartData({
        backTestingSeriesData: [
          {
            name: "Forecast Accuracy",
            type: "column",
            data: forecastAccuracyChartData,
            color: "#6E3BB2",
            yAxisIndex: 0,
          },
          {
            name: "Historical Shipments (Adjusted)",
            type: "line",
            data: historicalShipmentChartData,
            color: "#FFA800",
            yAxisIndex: 1,
          },
          {
            name: "Statistical Forecast",
            type: "line",
            data: statisticalForecastChartData,
            color: "#B992EB",
            yAxisIndex: 1
          },
        ],
        backTestingChartLabels:
          foreCastBackTestingChartData?.backTestingChartLabels,
      });
    } else {
      setBackTestingChartData({
        backTestingSeriesData: [],
        backTestingChartLabels: [],
      });
    }
  }, [foreCastBackTestingChartData]);

  function sortChartLabels(labels: string[]): string[] {
    return [...labels].sort((a, b) => {
      const dateA = new Date(a).getTime();
      const dateB = new Date(b).getTime();

      // Compare the timeFrameDate values in ascending order
      return dateA - dateB;
    });
  }

  const handleSettingModal = () => {
    mapperForGroupByLableToKeyHandler(selectedDataShownOptionList);
    setOpenSettings(true);
  };

  const applyFilterAndRedirect = () => {
    dispatch(borrowFiltersFromForecast(filter));
    return navigate("/resultvisualization", { replace: true });
  };

  const renderBackTestingAnalyticsHtml = () => {
    return (
      <div className="pl-3 pt-1">
        {foreCastBackTestingAverageData?.isAverageDataLoading ? (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        ) : (
          <div className="mb-7">
            <BackTestingAnalytics
              overallAverage={foreCastBackTestingAverageData?.overallAverage}
              overallPercent={foreCastBackTestingAverageData?.overallPercent}
            />
          </div>
        )}
      </div>
    );
  };

  const renderBackTestingChartHtml = (): ReactElement => {
    let chartComponent = null;

    if (
      backTesingChartData?.backTestingSeriesData?.length > 0 &&
      backTesingChartData?.backTestingChartLabels?.length > 0
    ) {
      chartComponent = (
        <>
          {renderBackTestingAnalyticsHtml()}
          <LineColumnChart
            seriesData={backTesingChartData?.backTestingSeriesData}
            columnWidth={widthColumn}
            labels={sortChartLabels(
              backTesingChartData?.backTestingChartLabels
            )}
            leftTitle="Volume"
            rightTitle="Percentage"
          />
        </>
      );
    }

    return (
      <>
        {foreCastBackTestingChartData?.isChartDataLoading ? (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        ) : (
          chartComponent
        )}
      </>
    );
  };

  const renderBackTestingTableHtml = (): ReactElement => {
    return (
      <>
        {foreCastBackTestingTableData?.isTableDataLoading ? (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        ) : (
          <BacktestingResultTable
            tableHeader={foreCastBackTestingTableData?.tableHeader}
            chartData={foreCastBackTestingChartData?.chartData}
            backTestingTableData={
              foreCastBackTestingTableData?.backTestingTableData
            }
            isResultVisulizationPage={isResultVisulizationPage}
            resultVisulizationTableData={[]}
            isExperimentationPage={isExperimentationPage}
          />
        )}
      </>
    );
  };

  const mapperForGroupByLableToKeyHandler = (
    selectedItemList: string[] = []
  ) => {
    const { selectedGroupByOptions, selectedCategoryType } =
      mapperForGroupByLableToKey(selectedItemList);
    setSelectedDataShownOptionList(selectedItemList);
    setSelectedDataShownCategoryList(selectedCategoryType);

    return selectedGroupByOptions;
  };

  const updateBackTestingTableData = (selectedOptionList: string[]) => {
    const payloadFilters = {
      shortTermMidTermCode: filter?.term.toString(),
      snapshotDate: [],
      brandName: filter?.brand,
      subBrandName: filter?.subBrand,
      productLineName: filter?.productLine,
      productGroupName: filter?.productGroup,
      packageSizeName: filter?.size,
      productCategoryName: filter?.category,
      portfolioGroupName: filter?.mg4PortfolioGroup,
      packageName: filter?.container,
      caseCountValue: filter?.count,
      customerGlobalName: filter?.global,
      customerSectorNumber: filter?.customerSector,
      customerRegionName: filter?.customerRegion,
      customerGroupName: filter?.customerGroup,
      customerBusinessUnitName: filter?.businessUnit,
      customerMarketUnitName: filter?.marketUnit,
      ibpDemandGroup: filter?.ibpDemandGroup,
      customerTradeSegmentCode: filter?.cg1TrageSegment,
      salesSectorName: filter?.salesSector,
      salesOrganizationCode: filter?.businessOrg,
      salesDivisionName: filter?.division,
      forecastEngineClusterId: filter?.clusters,
    };

    const forecastBackTestingApiRequestBody = {
      ...payloadFilters,
      pageNumber: 1,
      pageSize: PAGE_LIMIT,
      groupBy: mapperForGroupByLableToKeyHandler(selectedOptionList),
    };

    setOpenSettings(false);
    dispatch(
      foreCastBackTestingTableDataSetter({
        tableHeader: [],
        backTestingTableData: [],
        isTableDataLoading: true,
      })
    );

    forecastBackTestingTableApi(forecastBackTestingApiRequestBody, authState)
      .then((response) => {
        const totalBackTestingPages = calculateTotalPages(
          PAGE_LIMIT,
          response?.totalCount
        );
        dispatch(
          updateBackTestingTotalPages({
            totalPages: totalBackTestingPages,
            pageLimit: PAGE_LIMIT,
            pageNumber: FIRST_PAGE,
            isDataShownClicked: true,
          })
        );
        dispatch(
          foreCastBackTestingTableDataSetter({
            tableHeader: response?.dynamicTableHeader,
            backTestingTableData: response?.dfuBasedGroupedData,
            isTableDataLoading: false,
          })
        );
      })
      .catch((error) => {
        console.log("forecastBackTestingTableApi error", error);
      });
  };

  const onModalClosed = () => {
    setOpenSettings(false);
  };

  const renderBackTestingDataShownModalHtml = (): ReactElement => {
    return (
      <>
        <DataShownDialog
          open={openSettings}
          defaultSelectedOptions={selectedDataShownOptionList}
          categoryList={selectedDataShownCategoryList}
          onClose={(itemList) => updateBackTestingTableData(itemList)}
          onModalClosed={onModalClosed}
        />
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute" as const,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "#272727",
              boxShadow: 24,
              borderRadius: 2,
            }}
          >
            <div className="m-4">
              <div className="flex justify-between p-4">
                <h6 className="font-bold text-white">Backtesting Results</h6>
                <button onClick={handleClose}>
                  <img src={closeButton} alt="close" />
                </button>
              </div>

              <div className="bg-ternary-bg rounded m-4">
                {backTesingChartData?.backTestingSeriesData?.length > 0 &&
                  backTesingChartData?.backTestingChartLabels?.length > 0 && (
                    <LineColumnChart
                      seriesData={backTesingChartData?.backTestingSeriesData}
                      columnWidth={widthColumn}
                      labels={sortChartLabels(
                        backTesingChartData?.backTestingChartLabels
                      )}
                      leftTitle="Volume"
                      rightTitle="Percentage"
                    />
                  )}
                <BacktestingResultTable
                  tableHeader={foreCastBackTestingTableData?.tableHeader}
                  chartData={foreCastBackTestingChartData?.chartData}
                  backTestingTableData={
                    foreCastBackTestingTableData?.backTestingTableData
                  }
                  isResultVisulizationPage={isResultVisulizationPage}
                  resultVisulizationTableData={[]}
                />
              </div>
            </div>
          </Box>
        </Modal>
      </>
    );
  };

  const renderBackTestingHtml = (): ReactElement => {
    const isDataEmpty =
      !foreCastBackTestingDataLoading?.isDataLoading &&
      foreCastBackTestingChartData?.chartData?.length === 0 &&
      foreCastBackTestingAverageData?.overallAverage &&
      foreCastBackTestingAverageData?.overallPercent?.length === 0 &&
      foreCastBackTestingTableData?.backTestingTableData?.length === 0 &&
      resultVisualizationData?.chartData?.length === 0 &&
      !isResultVisulizationPage;

    return isDataEmpty ? (
      <div className="bg-ternary-bg rounded p-2">
        <div className="flex justify-center">
          <p className="p-2 m-1">
            No Data to show or no options selected, please choose from the above
            filters.
          </p>
        </div>
      </div>
    ) : (
      <>
        <div className="bg-ternary-bg rounded">
          {renderBackTestingChartHtml()}
          {renderBackTestingTableHtml()}
        </div>
        {renderBackTestingDataShownModalHtml()}
        <div className="flex justify-end">
          {isResultVisulizationPage ? (
            <> </>
          ) : (
            <div
              onClick={() => applyFilterAndRedirect()}
              style={{ backgroundColor: "#0069D7", borderRadius: "50px" }}
              className="font-bold text-base px-4 py-2 mt-4 cursor-pointer flex"
            >
              <span>View Alternative Recipes</span>
              <img src={arrowLogo} className="pl-2" alt="arrow" />
            </div>
          )}
        </div>
      </>
    );
  };

  const handlePageNumberUpdateEvent = (currentPageNumber: number) => {
    dispatch(
      updateBackTestingPageNumber({
        pageLimit: backTestingPagination?.pageLimit,
        pageNumber: currentPageNumber,
        totalPages: backTestingPagination?.totalPages,
        isDataShownClicked: false,
        groupBySelectedField: selectedDataShownOptionList,
      })
    );
  };

  return (
    <div className="p-5 bg-secondary-bg mt-4 rounded">
      <div className="flex justify-between">
        <div className="flex">
          <h6 className="text-lg font-bold mb-4 mt-2">Backtesting Results</h6>
          <div className="m-2 rounded bg-zinc-600 pt-2">
            {filter?.term.includes("ST") ? (
              <span className="p-1">Lag 4</span>
            ) : (
              <span className="p-1">Lag 6</span>
            )}
          </div>
        </div>
        {!foreCastBackTestingDataLoading?.isDataLoading ||
        foreCastBackTestingChartData?.chartData?.length > 0 ||
        foreCastBackTestingTableData?.backTestingTableData?.length > 0 ? (
          <div>
            <button onClick={handleSettingModal}>
              <img
                className="mr-5"
                width={20}
                height={20}
                src={settingsIcon}
                alt="settingsIcon"
              />
            </button>
            <button onClick={handleOpen}>
              <img src={enlargeButton} alt="zoom" />
            </button>
          </div>
        ) : null}
      </div>
      <>
        {renderBackTestingHtml()}
        <div className="flex justify-center">
          {foreCastBackTestingTableData?.backTestingTableData?.length > 0 && (
            <Pagination
              totalPages={backTestingPagination?.totalPages}
              activePageNumber={backTestingPagination?.pageNumber}
              updateBackTestingPageNumber={(data) =>
                handlePageNumberUpdateEvent(data)
              }
            />
          )}
        </div>
      </>
    </div>
  );
}

export default BacktestingResults;
