import RcaExecutionFilter from "../../components/RcaFilters/RcaExecutionFilter"
import TimeCardComponent from "../StatisticalForecastAnalysis/Timecard/TimeCardComponent"
import ClusterCard from "../StatisticalForecastAnalysis/Clustercard/clusterCard"
import RCATable from "./RcaTable"
import Metrics from "./metrics"

const Rcaexecution = () => {
  return (
    <div>
      <div className="flex space-x-3 mt-5">
        <div style={{ width: "500px" }}>
          <TimeCardComponent lag={true} />
        </div>
        <div className="flex-auto">
          <ClusterCard />
        </div>
      </div>
      <RcaExecutionFilter />
      <Metrics />
      <RCATable />
    </div >
  )
}

export default Rcaexecution