import React from 'react';
import { CircularProgress } from '@mui/material';

const Loading: React.FC = () => {
  return (
    <div className="loader-container">
        <CircularProgress className="loader"/>
      </div>
  );
};

export default Loading;