import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet, useNavigate } from "react-router-dom";
import { Loading } from "../../baseComponent";

export const SecureRoute: React.FC = () => {
  const navigator = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      if (authState?.isAuthenticated === false) {
        navigator("/login");
      } else {

        oktaAuth.signInWithRedirect();
      }
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return <Loading />;
  }

  return <Outlet />;
};

export default SecureRoute;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
