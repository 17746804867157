const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID as string;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER as string;
const REDIRECT_URI =  `${process.env.REACT_APP_OKTA_REDIRECT_BASE_URI}/login/callback`;
console.log('REDIECT URL', {'ENV' : process.env, REDIRECT_URI});

const OKTA_CONFIG = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  // scopes: ['openid', 'profile', 'offline_access', 'IBP/Forecast'],
  scopes: ['openid', 'profile', 'IBP/Forecast'],
  pkce: true
};

export { OKTA_CONFIG };
