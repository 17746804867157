import React, { useState } from "react";
import sortUp from "../../images/sortUp.svg";
import settingsIcon from "../../images/Settings.svg";
import {
  FormControl,
  MenuItem,
  Select,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import "./RcaTable.css";
import { Pagination } from "../../components/Pagination";
const uniqueKey = require("unique-key");

type DataRow = {
  cluster: string;
  upc: string;
  account: string;
  region: string;
  rca: string;
  pfeAccuracy: number;
  pfeBias: number;
  forecast: number;
  error: number;
  dpAccuracy: number;
};

const generateRandomData = (count: number): DataRow[] => {
  const clusters = ["High Demand #AZ", "Medium Demand #CA", "Low Demand #TX"];
  const upcs = ["12 Pack 16.9 Oz", "24 Pack 12 Oz", "6 Pack 20 Oz"];
  const accounts = ["Kroger", "Walmart", "Target"];
  const regions = ["North", "South", "East", "West"];
  const rcas = [
    "1.1.1 Uncaptured Trend",
    "1.1.2 Uncaptured Seasonality",
    "1.2.1 Inaccurate Promotion Impact",
    "1.2.5 Inaccurate Holidays Impact",
    "3.2.1 Out of Stock/Allocation",
  ];

  return Array.from({ length: count }, () => ({
    cluster: clusters[Math.floor(Math.random() * clusters?.length)],
    upc: upcs[Math.floor(Math.random() * upcs?.length)],
    account: accounts[Math.floor(Math.random() * accounts?.length)],
    region: regions[Math.floor(Math.random() * regions?.length)],
    rca: rcas[Math.floor(Math.random() * rcas?.length)],
    pfeAccuracy: Math.floor(Math.random() * 100),
    pfeBias: Math.floor(Math.random() * 10),
    forecast: Math.floor(Math.random() * 1000) + 500,
    error: Math.floor(Math.random() * 500) + 100,
    dpAccuracy: Math.floor(Math.random() * 100),
  }));
};

const data: DataRow[] = generateRandomData(50);

type SortKey = keyof DataRow;

const sortData = (key: SortKey, ascending: boolean) => {
  return [...data].sort((a, b) => {
    const aValue = a[key];
    const bValue = b[key];
    const ascReturn = calCulateAsceding(ascending, aValue, bValue);

    if (typeof aValue === "number" && typeof bValue === "number") {
      return ascending ? aValue - bValue : bValue - aValue;
    }
    return ascReturn;
  });
};

const calCulateAsceding = (
  ascending: boolean,
  aValue: string | number,
  bValue: string | number
) => {
  let result;

  if (ascending) {
    result = aValue > bValue ? 1 : -1;
  } else {
    result = aValue < bValue ? 1 : -1;
  }

  return result;
};

const RcaSummaryTable = () => {
  const [sortKey, setSortKey] = useState<SortKey | null>(null);
  const [ascending, setAscending] = useState<boolean>(true);
  const [selectValue] = useState<string>("Select");

  const theme = createTheme({
    palette: {
      background: {
        paper: "#272727",
      },
      text: {
        primary: "#ffff",
      },
    },
  });

  const handleSort = (key: SortKey) => {
    setAscending(sortKey === key ? !ascending : true);
    setSortKey(key);
  };

  const sortedData = sortKey ? sortData(sortKey, ascending) : data;

  const getColor = (value: number) => {
    if (value >= 85) return "bg-green-600 text-white";
    if (value >= 70) return "bg-yellow-500 text-white";
    return "bg-red-700 text-white";
  };

  const renderSortIcon = (key: SortKey) => {
    if (sortKey === key) {
      return ascending ? (
        <img src={sortUp} alt="sortUp" className="ml-2 w-3 h-3" />
      ) : (
        <img src={sortUp} alt="sortDown" className="ml-2 w-3 h-3" />
      );
    } else {
      return <img src={sortUp} alt="sortDown" className="ml-2 w-3 h-3" />;
    }
  };

  return (
    <div className="mt-4">
      <div className="bg-ternary-bg text-white p-6 pb-10 rounded-lg shadow-lg">
        <div className="pb-4 flex justify-between items-center">
          <span className="text-lg font-bold">Title</span>

          <div className="flex space-x-2">
            <ThemeProvider theme={theme}>
              <FormControl
                fullWidth
                sx={{
                  minWidth: "195px",
                  backgroundColor: "#313131",
                  borderRadius: "100px",
                }}
              >
                <Select
                  value={selectValue}
                  sx={{
                    color: "#fff",
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  }}
                >
                  <MenuItem value="Select">Filter</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
            <img src={settingsIcon} alt="settings" />
          </div>
        </div>

        <div className="RCA-table-holder">
          <table className="text-xs">
            <thead>
              <tr className="bg-ternary-bg">
                <th className="px-4 py-4 border border-primary-border">
                  CLUSTER
                </th>
                <th className="px-4 py-4 border border-primary-border">UPC</th>
                <th className="px-4 py-4 border border-primary-border">
                  KEY ACCOUNT
                </th>
                <th className="px-4 py-4 border border-primary-border">
                  SALES REGION
                </th>
                <th className="px-4 py-4 border border-primary-border">
                  RCA STAT FINAL
                </th>
                <th
                  className="px-4 py-4 border border-primary-border cursor-pointer"
                  onClick={() => handleSort("pfeAccuracy")}
                >
                  <div className="flex items-center justify-between">
                    (PFE) FORECAST ACCURACY STAT
                    {renderSortIcon("pfeAccuracy")}
                  </div>
                </th>
                <th
                  className="px-4 py-4 border border-primary-border cursor-pointer"
                  onClick={() => handleSort("pfeBias")}
                >
                  <div className="flex items-center justify-between">
                    (PFE) BIAS
                    {renderSortIcon("pfeBias")}
                  </div>
                </th>
                <th
                  className="px-4 py-4 border border-primary-border cursor-pointer"
                  onClick={() => handleSort("forecast")}
                >
                  <div className="flex items-center justify-between">
                    STATISTICAL FORECAST
                    {renderSortIcon("forecast")}
                  </div>
                </th>
                <th
                  className="px-4 py-4 border border-primary-border cursor-pointer"
                  onClick={() => handleSort("error")}
                >
                  <div className="flex items-center justify-between">
                    ABSOLUTE ERROR
                    {renderSortIcon("error")}
                  </div>
                </th>
                <th className="px-4 py-4 border border-primary-border">
                  (DP) FORECAST ACCURACY STAT
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((row, idx) => (
                <tr
                  key={uniqueKey("rca_summary_key")}
                  className="border border-gray-200"
                >
                  <td className="px-4 py-4 border border-primary-border">
                    {row.cluster}
                  </td>
                  <td className="px-4 py-4 border border-primary-border">
                    {row.upc}
                  </td>
                  <td className="px-4 py-4 border border-primary-border">
                    {row.account}
                  </td>
                  <td className="px-4 py-4 border border-primary-border">
                    {row.region}
                  </td>
                  <td className="px-4 py-4 border border-primary-border">
                    {row.rca}
                  </td>
                  <td
                    className={`px-4 py-4 border border-primary-border ${getColor(
                      row.pfeAccuracy
                    )}`}
                  >
                    {row.pfeAccuracy}
                  </td>
                  <td className="px-4 py-4 border border-primary-border">
                    {row.pfeBias}
                  </td>
                  <td className="px-4 py-4 border border-primary-border">
                    {row.forecast}
                  </td>
                  <td className="px-4 py-4 border border-primary-border">
                    {row.error}
                  </td>
                  <td
                    className={`px-4 py-4 border border-primary-border ${getColor(
                      row.dpAccuracy
                    )}`}
                  >
                    {row.dpAccuracy}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-8 flex justify-center">
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default RcaSummaryTable;
