import React, { useEffect, useRef, useState } from "react";
import "./BacktestingResultTable.css";
import {
  AccuracyData,
  ForwardForcatResultsTableProps,
} from "../../../store/foreCastBackTestingDataSlice";
import {
  calculateDynamicCellPadding,
  renderTableDynamicRow,
} from "../../../utils/functions";
const uniqueKey = require("unique-key");
interface BackTestingRowData {
  rowData: string[];
  dataType: string;
  weekData: AccuracyData[];
}

interface ResultVisulizationBackTestingRowData {
  rowData: string[];
  dataType: string;
}

const ForwardForecastTable: React.FC<ForwardForcatResultsTableProps> = ({
  tableHeader,
  chartData,
  backTestingTableData,
  isResultVisulizationPage,
  resultVisulizationTableData,
  isExperimentationPage,
}) => {
  const backTestingTableRowRef = useRef<any>(null);
  const [tableCellWidth, setTableCellWidth] = useState(0);
  const [tableWeekCellWidth, setTableWeekCellWidth] = useState(0);
  const [isTableSpanUpdated, setTableSpanUpdared] = useState(false);

  useEffect(() => {
    setTableCellWidth(
      calculateDynamicCellPadding(backTestingTableRowRef, tableHeader)
        ?.calculatedRightPadding
    );
    setTableWeekCellWidth(
      calculateDynamicCellPadding(backTestingTableRowRef, tableHeader)
        ?.calculateWeekColumnWidth
    );
    setTimeout(() => {
      setTableSpanUpdared(true);
    }, 1000);
  }, [backTestingTableRowRef]);
  /**
   * Generate a finaly array to render inside table row from api response
   * @returns
   */
  const renderBackTestingTableData = () => {
    let tableData: BackTestingRowData[] = [];
    let dynamicHeaders: string[] = [];
    let isDataTypeFound = false;
    let weekCount = 0;
    let shouldWeekCounterStart = false;

    tableHeader?.forEach((item) => {
      if (item !== "DATA TYPE" && isDataTypeFound === false) {
        dynamicHeaders.push(item);
      } else if (item === "DATA TYPE") {
        isDataTypeFound = true;
        shouldWeekCounterStart = true;
      }
      if (shouldWeekCounterStart) {
        weekCount++;
      }
    });
    // Helper function to process the data for different ranks (1, 2, 3)
    const processRankData = (
      row: any,
      dataKey: string,
      dataTypePrefix: string
    ) => {
      const ranks = [1, 2, 3];
      ranks.forEach((rank) => {
        const isRankPresent = row?.ltp?.[dataKey]?.some(
          (item: any) => item?.rank === rank
        );
        if (isRankPresent) {
          tableData.push({
            rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
            weekData: [
              ...row?.ltp?.[dataKey]?.filter(
                (item: any) => item?.rank === rank
              ),
            ],
            dataType: `${dataTypePrefix} Rank ${rank}`,
          });
        }
      });
    };

    // Main function
    backTestingTableData?.forEach((row) => {
      // Process for statistical forecast production
      if (row?.ltp?.statisticalForecastProduction !== undefined) {
        tableData.push({
          rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
          weekData: [...row?.ltp?.statisticalForecastProduction],
          dataType: "Stat Forecast Production",
        });
      }

      // Process for statistical forecast experimentation
      if (row?.ltp?.statisticalForecastExperimentation !== undefined) {
        processRankData(
          row,
          "statisticalForecastExperimentation",
          "Stat Forecast"
        );
      }

      // Process for historical shipments
      if (row?.ltp?.historicalShipments !== undefined) {
        tableData.push({
          rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
          weekData: [...row?.ltp?.historicalShipments],
          dataType: "Historical Shipments",
        });
        processRankData(row, "historicalShipments", "Historical Shipments");
      }
    });

    return renderHtmlTableData(tableData, weekCount);
  };

  /**
   * Render the sub array week data from the parent array for backtesting
   * @param weekDataArray
   * @returns ReactElement
   */
  const getWeekData = (
    weekDataArray: AccuracyData[],
    weekCount: number
  ): React.ReactElement[] => {
    const weekDataDifference = weekCount - weekDataArray?.length;
    for (let i = 0; i < weekDataDifference - 1; i++) {
      weekDataArray.push({
        weekNumber: "",
        value: 0,
      });
    }
    return weekDataArray?.map((item: AccuracyData, index: any) => {
      if (item?.weekNumber === "") {
        return (
          <td
            key={uniqueKey("key_")}
            className="backtestingResultDataRow border border-primary-border p-4"
          ></td>
        );
      } else {
        return (
          <td
            key={uniqueKey("key_")}
            className="backtestingResultDataRow border border-primary-border p-4"
            style={{ paddingRight: `${tableWeekCellWidth}` }}
          >
            {item?.value}
          </td>
        );
      }
    });
  };

  const renderHtmlTableData = (
    tableData: BackTestingRowData[],
    weekCount: number
  ) => {
    if (tableData.length === 0) {
      return (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          <td
            key={`${uniqueKey("key_backtesting_rowdata")}`}
            className="border border-primary-border p-4 text-center"
            colSpan={tableHeader?.length}
          >
            No Record Found
          </td>
        </tr>
      );
    } else {
      return tableData?.map((data: BackTestingRowData) => (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
          ref={backTestingTableRowRef}
        >
          {data?.dataType === "Stat Forecast Production"
            ? data?.rowData?.map((item) => {
                return (
                  <td
                    key={`${uniqueKey("key_backtesting_rowdata")}`}
                    className="border border-primary-border p-4"
                  >
                    {item}
                  </td>
                );
              })
            : data?.rowData?.map(() => {
                return (
                  <td
                    key={`${uniqueKey("key_backtesting_rowdata-empty")}`}
                    className="border border-primary-border p-4"
                  ></td>
                );
              })}
          <td className="backtestingResultDataRow border border-primary-border p-4">
            {data?.dataType}
          </td>

          {getWeekData(data?.weekData, weekCount)}
        </tr>
      ));
    }
  };

  const renderChartDataTable = () => {
    return renderResultVisulizationExperimentChartDataTable();
  };

  const renderResultVisulizationExperimentChartDataTable = () => {
    const statisticalForecast: {
      productionRun: ResultVisulizationBackTestingRowData;
      rank1: ResultVisulizationBackTestingRowData;
      rank2: ResultVisulizationBackTestingRowData;
      rank3: ResultVisulizationBackTestingRowData;
    } = {
      productionRun: {
        dataType: "",
        rowData: [],
      },
      rank1: {
        dataType: "",
        rowData: [],
      },
      rank2: {
        dataType: "",
        rowData: [],
      },
      rank3: {
        dataType: "",
        rowData: [],
      },
    };
    const historicalShipment: {
      productionRun: ResultVisulizationBackTestingRowData;
      rank1: ResultVisulizationBackTestingRowData;
      rank2: ResultVisulizationBackTestingRowData;
      rank3: ResultVisulizationBackTestingRowData;
    } = {
      productionRun: {
        dataType: "",
        rowData: [],
      },
      rank1: {
        dataType: "",
        rowData: [],
      },
      rank2: {
        dataType: "",
        rowData: [],
      },
      rank3: {
        dataType: "",
        rowData: [],
      },
    };
    
    let firstExperimentStartDate = resultVisulizationTableData?.filter(
      (item) => item?.experimentId !== "ProductionRun"
    )?.[0]?.timeFrameStartDate;
    let experimentStartDate = new Date(firstExperimentStartDate);

    resultVisulizationTableData?.forEach((item, index) => {
      if (
        item?.experimentId === "ProductionRun" &&
        new Date(item?.timeFrameStartDate) < experimentStartDate
      ) {
        statisticalForecast.rank1.dataType = "Rank 1";
        statisticalForecast?.rank1.rowData.push("0");
        statisticalForecast.rank2.dataType = "Rank 2";
        statisticalForecast?.rank2.rowData.push("0");
        statisticalForecast.rank3.dataType = "Rank 3";
        statisticalForecast?.rank3.rowData.push("0");
      }
      if (item?.experimentId === "FFExperiment 1") {
        if (item.rank === 1) {
          statisticalForecast.rank1.dataType = "Rank 1";
          statisticalForecast?.rank1.rowData.push(
            `${item.statisticalForecast}`
          );
          historicalShipment.rank1.dataType = "Rank 1";
          historicalShipment?.rank1.rowData.push(
            `${Math.trunc(item.historicalShipment)}`
          );
        }
        if (item.rank === 2) {
          statisticalForecast.rank2.dataType = "Rank 2";
          statisticalForecast?.rank2.rowData.push(
            `${Math.trunc(item.statisticalForecast)}`
          );
          historicalShipment.rank2.dataType = "Rank 2";
          historicalShipment?.rank2.rowData.push(
            `${Math.trunc(item.historicalShipment)}`
          );
        }
        if (item.rank === 3) {
          statisticalForecast.rank3.dataType = "Rank 3";
          statisticalForecast?.rank3.rowData.push(
            `${Math.trunc(item.statisticalForecast)}`
          );
          historicalShipment.rank3.dataType = "Rank 3";
          historicalShipment?.rank3.rowData.push(
            `${Math.trunc(item.historicalShipment)}`
          );
        }
      } else {
        statisticalForecast.productionRun.dataType = "Statistical Forecast";
        statisticalForecast?.productionRun.rowData.push(
          `${Math.trunc(item.statisticalForecast)}`
        );
        historicalShipment.productionRun.dataType = "Historical Shipment";
        historicalShipment?.productionRun.rowData.push(
          `${Math.trunc(item.historicalShipment)}`
        );
      }
    });
    return (
      <table className="text-xs font-bold">
        <tbody>
          {renderExperiemtnResultVisulizationHtmlTableData(
            [statisticalForecast?.productionRun],
            "Statistical Forecast",
            "Production Run",
            true
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [statisticalForecast?.rank1],
            "Statistical Forecast",
            "Rank 1",
            statisticalForecast?.productionRun?.rowData?.length === 0
              ? true
              : false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [statisticalForecast?.rank2],
            "Statistical Forecast",
            "Rank 2",
            false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [statisticalForecast?.rank3],
            "Statistical Forecast",
            "Rank 3",
            false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [historicalShipment?.productionRun],
            "Historical Shipment",
            "",
            true
          )}
        </tbody>
      </table>
    );
  };

  const renderExperiemtnResultVisulizationHtmlTableData = (
    tableData: ResultVisulizationBackTestingRowData[],
    dataType: string,
    experimentLableText: string,
    isProductionRunEmpty: boolean
  ) => {
    if (tableData.length === 0) {
      return (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          <td
            key={`${uniqueKey("key_backtesting_rowdata")}`}
            className="border border-primary-border p-4 text-center"
            colSpan={tableHeader?.length}
          >
            No Record Found
          </td>
        </tr>
      );
    } else {
      return tableData?.map((data: ResultVisulizationBackTestingRowData) => (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          {data?.dataType === dataType ? (
            <>
              <td
                className="border border-primary-border bg-zinc-700 p-4"
                style={{
                  paddingRight: `${tableCellWidth}`,
                }}
              >
                <div className="flex items-center">
                  <span className="pl-2">{dataType}</span>
                </div>
              </td>

              <td
                key={`${uniqueKey("key_backtesting_rowdata")}`}
                className="border border-primary-border bg-zinc-700 p-4"
              >
                {experimentLableText}
              </td>
              {data?.rowData?.map((item) => {
                return (
                  <>
                    <td
                      key={`${uniqueKey("key_backtesting_rowdata")}`}
                      className="border border-primary-border bg-zinc-700 p-4"
                    >
                      {item}
                    </td>
                  </>
                );
              })}
            </>
          ) : (
            data?.rowData?.map((item, index) => {
              return (
                <>
                  {index === 0 && (
                    <>
                      {isProductionRunEmpty ? (
                        <td className="border border-primary-border bg-zinc-700 p-4">
                          <div className="flex items-center">
                            <span className="pl-2">{dataType}</span>
                          </div>
                        </td>
                      ) : (
                        <td
                          key={`${uniqueKey("key_backtesting_rowdata")}`}
                          className="border border-primary-border bg-zinc-700 p-4"
                        ></td>
                      )}
                      <td
                        key={`${uniqueKey("key_backtesting_rowdata")}`}
                        className="border border-primary-border bg-zinc-700 p-4"
                      >
                        {experimentLableText}
                      </td>
                    </>
                  )}
                  <td
                    key={`${uniqueKey("key_backtesting_rowdata")}`}
                    className="border border-primary-border bg-zinc-700 p-4"
                  >
                    {item}
                  </td>
                </>
              );
            })
          )}
        </tr>
      ));
    }
  };

  return (
    <div className="p-6 mt-4 text-white table-holder-main">
      <div className="btr-table-holder">
        {isTableSpanUpdated && renderChartDataTable()}
        {tableHeader?.length > 0 ? (
          <table className="text-xs font-bold table-auto">
            <tbody>
              <tr className="bg-ternary-bg">
                {tableHeader?.map((data) => {
                  return (
                    <td
                      key={`${uniqueKey("key_")}-forecast-backtesting-headers`}
                      className="backtestingResultDataRow border border-primary-border p-4 "
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
              {renderBackTestingTableData()}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
};

export default ForwardForecastTable;
