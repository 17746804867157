import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { RootState } from '../../store/store';
import { resetRcaState } from '../../store/filterSlice';
import MultiInputSelect from '../../baseComponent/inputSelectType/multiInputSelectRca';
import { useCusomtersApiData, useItemsApiData, usePfeApiData, useSalesGeoApiData } from '../../api/filtersApi';
import loadingSvg from '../../images/bouncing-circles.svg';
import { Box, Modal, Typography } from '@mui/material';

const style = {
    display:"flex",
    flexFlow:"column",
    alignItems:"center",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    borderRadius:"10px",
    bgcolor: '#1D1D1D',
    border: '8px solid #3A3A3A',
    boxShadow: 24,
    p: 4,
};

const RcaExecutionFilter: React.FC = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { authState } = useOktaAuth();
    const dispatch = useDispatch();
    const filter = useSelector((state: RootState) => state.rcaFilter);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const { data:itemsApiData, isLoading: isItemsApiDataLoading } = useItemsApiData(authState?.accessToken?.accessToken!,"items");
    const { data:customersApiData, isLoading:isCustomersApiDataLoading } = useCusomtersApiData(authState?.accessToken?.accessToken!,"customers");
    const { data:salesGeoApiData, isLoading:isSalesGeoApiDataLoading } = useSalesGeoApiData(authState?.accessToken?.accessToken!,"salesgeo");
    const { data:pfeApiData, isLoading:ispfeApiDataLoading } = usePfeApiData(authState?.accessToken?.accessToken!,"pfe");

    const resetFilter = () => {
        dispatch(resetRcaState());
        handleClose();
    };

    const applyFilters = () => {
        console.log(filter);
    };

    return (
        <div className="p-5 bg-secondary-bg mt-4 rounded">
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
            <Typography id="modal-modal-title" style={{fontWeight:"bold",fontSize:"28px",color:"white"}}>
                Are you sure you want to reset filters?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 1,color:"white" }}>
                This cannot be undone and you will loose all you filters selections
            </Typography>
            <div className='flex w-full justify-end mt-6'>
                <button onClick={handleClose} className='cancelFilterResetModalButton'>Cancel</button>
                <button onClick={resetFilter} className='resetFilterModalButton'>Reset Filters</button>
            </div>
            </Box>
        </Modal>
        <div className="flex justify-between mb-4">
            <span className="text-lg font-bold mb-4 ">Filters</span>
            <div className='flex'>
                <button onClick={handleOpen} className='resetFilterButton'>Reset Filters</button>
                <button onClick={applyFilters} className='applyFilterButton'>Apply Filters</button>
            </div>
        </div>
        <div className="flex flex-row">
            <div className="basis-3/4 ">
                <h6 className='text-lg'>Item</h6>
                {
                    isItemsApiDataLoading ?<div className='flex justify-center'>
                        <img src={loadingSvg} alt="loading" className="mt-10" width={40} height={40}/>
                    </div>  :
                    <div className="grid grid-cols-3 pt-3">
                        <div>
                            <div className='pb-2 select-label'>Category</div>
                            <MultiInputSelect itemList={itemsApiData?.categories} itemType="category" />
                            <div className='pb-2 mt-4 select-label'>Product Line</div>
                            <MultiInputSelect itemList={itemsApiData?.productLines} itemType="productLine" />
                            <div className='pb-2 mt-4 select-label'>Product Group</div>
                            <MultiInputSelect itemList={itemsApiData?.productGroups} itemType="productGroup" />
                            <div className='pb-2 mt-4 select-label'>MG4 Portfolio Group</div>
                            <MultiInputSelect itemList={itemsApiData?.mg4PortfolioGroups} itemType="mg4PortfolioGroup" />
                        </div>
                        <div>
                            <div className='pb-2 select-label'>Brand</div>
                            <MultiInputSelect itemList={itemsApiData?.brands} itemType="brand" />
                            <div className='pb-2 mt-4 select-label'>Sub Brand</div>
                            <MultiInputSelect itemList={itemsApiData?.subBrands} itemType="subBrand" />
                            <div className='pb-2 mt-4 select-label'>Container</div>
                            <MultiInputSelect itemList={itemsApiData?.containers} itemType="container" />
                            <div className='pb-2 mt-4 select-label'>PPG</div>
                            <MultiInputSelect itemList={itemsApiData?.ppg} itemType="ppg" />
                        </div>
                        <div>
                            <div className='pb-2 select-label'>Size</div>
                            <MultiInputSelect itemList={itemsApiData?.size} itemType="size" />
                            <div className='pb-2 mt-4 select-label'>Count</div>
                            <MultiInputSelect itemList={itemsApiData?.count} itemType="count" />
                            <div className='pb-2 mt-4 select-label'>Planning Item</div>
                            <MultiInputSelect itemList={itemsApiData?.planningItems} itemType="planningItem" />
                        </div>
                    </div>
                }

            </div>
            <div className="basis-2/4">
                <h6 className='text-lg'>Customer</h6>
                {
                    isCustomersApiDataLoading ?<div className='flex justify-center'>
                        <img src={loadingSvg} alt="loading" className="mt-10" width={40} height={40}/>
                    </div>  :
                    <div className="grid grid-cols-2 pt-3">
                        <div>
                            <div className='pb-2 select-label'>Key Account</div>
                            <MultiInputSelect itemList={customersApiData?.keyAccount} itemType="keyAccount" />
                            <div className='pb-2 mt-4 select-label'>Global</div>
                            <MultiInputSelect itemList={customersApiData?.global} itemType="global" />
                            <div className='pb-2 mt-4 select-label'>Sector</div>
                            <MultiInputSelect itemList={customersApiData?.sector} itemType="customerSector" />
                            <div className='pb-2 mt-4 select-label'>Region</div>
                            <MultiInputSelect itemList={customersApiData?.regions} itemType="customerRegion" />
                            <div className='pb-2 mt-4 select-label'>Customer Group</div>
                            <MultiInputSelect itemList={customersApiData?.customerGroups} itemType="customerGroup" />
                        </div>
                        <div>
                            <div className='pb-2 select-label'>Business Unit</div>
                            <MultiInputSelect itemList={customersApiData?.businessUnits} itemType="businessUnit" />
                            <div className='pb-2 mt-4 select-label'>Market Unit</div>
                            <MultiInputSelect itemList={customersApiData?.marketUnits} itemType="marketUnit" />
                            <div className='pb-2 mt-4 select-label'>IBP Demand Group</div>
                            <MultiInputSelect itemList={customersApiData?.ibpDemandGroup} itemType="ibpDemandGroup" />
                            <div className='pb-2 mt-4 select-label'>CGI Trade Segment</div>
                            <MultiInputSelect itemList={customersApiData?.cgiTradeSegment} itemType="cg1TrageSegment" />
                        </div>
                    </div>
                }
            </div>
            <div className="basis-1/4">
                <h6 className='text-lg'>Sales Geo</h6>
                {
                    isSalesGeoApiDataLoading ?<div className='flex justify-center'>
                        <img src={loadingSvg} alt="loading" className="mt-10" width={40} height={40}/>
                    </div>  :
                    <div className="grid grid-cols-1 pt-3">
                        <div>
                            <div className='pb-2 select-label'>Sector</div>
                            <MultiInputSelect itemList={salesGeoApiData?.sector} itemType="salesSector" />
                            <div className='pb-2 mt-4 select-label'>Country</div>
                            <MultiInputSelect itemList={salesGeoApiData?.countries} itemType="country" />
                            <div className='pb-2 mt-4 select-label'>Sales Region</div>
                            <MultiInputSelect itemList={salesGeoApiData?.salesRegion} itemType="salesRegion" />
                            <div className='pb-2 mt-4 select-label'>Sales Business Org</div>
                            <MultiInputSelect itemList={salesGeoApiData?.salesBusinessOrg} itemType="businessOrg" />
                            <div className='pb-2 mt-4 select-label'>Sales Division</div>
                            <MultiInputSelect itemList={salesGeoApiData?.salesDivision} itemType="division" />
                        </div>
                    </div>
                }               
            </div>
            <div className="basis-1/4">
                <h6 className='text-lg'>PFE</h6>
                {
                    ispfeApiDataLoading ?<div className='flex justify-center'>
                        <img src={loadingSvg} alt="loading" className="mt-10" width={40} height={40}/>
                    </div>  :
                    <div className="grid grid-cols-1 pt-3">
                        <div>
                            <div className='pb-2 select-label'>Segment</div>
                            <MultiInputSelect itemList={pfeApiData?.segment} itemType="segment" />
                            <div className='pb-2 mt-4 select-label'>AC1/AC2</div>
                            <MultiInputSelect itemList={pfeApiData?.acOneTwo} itemType="ac1ac2" />
                            <div className='pb-2 mt-4 select-label'>Recipe Runs</div>
                            <MultiInputSelect itemList={pfeApiData?.recipeRuns} itemType="recipeRuns" />
                            
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
    );
};

export default RcaExecutionFilter;