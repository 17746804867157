import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";

interface SeriesData {
  name: string;
  type: "column" | "line";
  data: number[];
  color: string;
  icon?: string; // URL or icon name
}

interface LineColumnChartProps {
  seriesData?: SeriesData[];
  columnWidth?: string;
  labels?: string[];
  leftTitle?: string;
  rightTitle?: string;
}

const LineColumnChart: React.FC<LineColumnChartProps> = ({
  seriesData = [],
  columnWidth = "50%",
  labels = [],
  leftTitle,
  rightTitle,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const reversedSeriesData = seriesData.map((series) => ({
        ...series,
        data: [...series.data],
      }));

      const options = {
        series: reversedSeriesData,
        chart: {
          height: 250,
          type: "line",
          background: "#1D1D1D",
        },
        plotOptions: {
          bar: {
            columnWidth: columnWidth,
            borderRadius: 3,
            borderRadiusApplication: "top",
          },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: labels,
        },
        yaxis: [
          {
            labels: {
              style: {
                colors: "white",
              },
              formatter: (value: number) => `${value}%`,
            },
            title: {
              text: rightTitle,
              style: {
                color: "white",
              },
            },
            opposite: true,
          },
          {
            labels: {
              style: {
                colors: "white",
              },
              formatter: (value: number) =>
                leftTitle === "Bias" ? `${value}%` : `${value}`,
            },
            title: {
              text: leftTitle,
              style: {
                color: "white",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: (
              value: number,
              { seriesIndex, w }: { seriesIndex: number; w: any }
            ) => {
              const seriesYAxisIndex = w.config.series[seriesIndex].yAxisIndex;
              if (seriesYAxisIndex === 0) {
                return `${value}%`;
              }
              return value;
            },
          },
        },
        theme: {
          mode: "dark",
        },
        grid: {
          borderColor: "#616161",
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          labels: {
            colors: "white",
          },
        },
        // Removed the custom tooltip configuration
      };

      try {
        const chart = new ApexCharts(chartRef.current, options);
        chart.render();

        return () => {
          chart.destroy();
        };
      } catch (error) {
        console.error("Error initializing chart:", error);
      }
    }
  }, [seriesData, columnWidth, labels, leftTitle, rightTitle]);

  return (
    <div className="pt-3">
      <div ref={chartRef}></div>
    </div>
  );
};

export default LineColumnChart;
