import React from "react";
import InfoIcon from "../../../images/info.svg";
import "./BacktestingResultTable.css";
import {
  AccuracyData,
  BackTestingResultsTableProps,
} from "../../../store/foreCastBackTestingDataSlice";
import { renderTableDynamicRow } from "../../../utils/functions";
const uniqueKey = require("unique-key");
interface BackTestingRowData {
  rowData: string[];
  dataType: string;
  weekData: AccuracyData[];
}

interface ResultVisulizationBackTestingRowData {
  rowData: string[];
  dataType: string;
}

const BacktestingResultTable: React.FC<BackTestingResultsTableProps> = ({
  tableHeader,
  chartData,
  backTestingTableData,
  isResultVisulizationPage,
  resultVisulizationTableData,
}) => {
  /**
   * Generate a finaly array to render inside table row from api response
   * @returns
   */
  const renderBackTestingTableData = () => {
    let tableData: BackTestingRowData[] = [];
    let dynamicHeaders: string[] = [];
    let isDataTypeFound = false;
    tableHeader?.forEach((item) => {
      if (item !== "DATA TYPE" && isDataTypeFound === false) {
        dynamicHeaders.push(item);
      } else if (item === "DATA TYPE") {
        isDataTypeFound = true;
      }
    });
    backTestingTableData?.forEach((row, index) => {
      tableData.push({
        rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
        weekData: [...row?.ltp?.forecastAccuracy],
        dataType: "Forecast Accuracy",
      });

      row?.ltp?.bias?.forEach((dataForeast, dataBiasIndex) => {
        if (dataBiasIndex === 0) {
          tableData.push({
            rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
            weekData: [...row?.ltp?.bias],
            dataType: "Bias",
          });
        }
      });
      if (!isResultVisulizationPage) {
        row?.ltp?.statisticalForecast?.forEach(
          (dataForeast, dataForecastIndex) => {
            if (dataForecastIndex === 0) {
              tableData.push({
                rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
                weekData: [...row?.ltp?.statisticalForecast],
                dataType: "Statistical Forecast",
              });
            }
          }
        );

        row?.ltp?.historicalShipments?.forEach(
          (dataForeast, dataForcastIndex) => {
            if (dataForcastIndex === 0) {
              tableData.push({
                rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
                weekData: [...row?.ltp?.historicalShipments],
                dataType: "Historical Shipments",
              });
            }
          }
        );
      }
    });

    return renderHtmlTableData(tableData);
  };

  /**
   * Render the sub array week data from the parent array for backtesting
   * @param weekDataArray
   * @returns ReactElement
   */
  const getWeekData = (weekDataArray: AccuracyData[]): React.ReactElement[] => {
    const weekDataDifference = tableHeader?.length - weekDataArray?.length;
    for (let i = 0; i < weekDataDifference - 6; i++) {
      weekDataArray.push({
        weekNumber: "",
        value: 0,
      });
    }
    return weekDataArray?.map((item: AccuracyData, index: any) => {
      if (item?.weekNumber === "") {
        return (
          <td
            key={uniqueKey("key_")}
            className="backtestingResultDataRow border border-primary-border p-4"
          ></td>
        );
      } else {
        return (
          <td
            key={uniqueKey("key_")}
            className="backtestingResultDataRow border border-primary-border p-4"
          >
            {item?.value}%
          </td>
        );
      }
    });
  };

  const renderHtmlTableData = (tableData: BackTestingRowData[]) => {
    if (tableData.length === 0) {
      return (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          <td
            key={`${uniqueKey("key_backtesting_rowdata")}`}
            className="border border-primary-border p-4 text-center"
            colSpan={tableHeader?.length}
          >
            No Record Found
          </td>
        </tr>
      );
    } else {
      return tableData?.map((data: BackTestingRowData) => (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          {data?.dataType === "Forecast Accuracy"
            ? data?.rowData?.map((item) => {
              return (
                <td
                  key={`${uniqueKey("key_backtesting_rowdata")}`}
                  className="border border-primary-border p-4"
                >
                  {item}
                </td>
              );
            })
            : data?.rowData?.map(() => {
              return (
                <td
                  key={`${uniqueKey("key_backtesting_rowdata-empty")}`}
                  className="border border-primary-border p-4"
                ></td>
              );
            })}
          <td className="backtestingResultDataRow border border-primary-border p-4">
            {data?.dataType}
          </td>

          {getWeekData(data?.weekData)}
        </tr>
      ));
    }
  };

  const renderResultVisulizationHtmlTableData = (
    tableData: ResultVisulizationBackTestingRowData[],
    dataType: string
  ) => {
    if (tableData.length === 0) {
      return (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          <td
            key={`${uniqueKey("key_backtesting_rowdata")}`}
            className="border border-primary-border p-4 text-center"
            colSpan={tableHeader?.length}
          >
            No Record Found
          </td>
        </tr>
      );
    } else {
      return tableData?.map((data: ResultVisulizationBackTestingRowData) => (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          {data?.dataType === dataType
            ? data?.rowData?.map((item, index: number) => {
              return (
                <>
                  {index === 0 && (
                    <td className="border border-primary-border bg-zinc-700 p-4">
                      <div className="flex items-center">
                        <img src={InfoIcon} alt="info-img" />
                        <span className="pl-2">{dataType}</span>
                      </div>
                    </td>
                  )}

                  <td
                    key={`${uniqueKey("key_backtesting_rowdata")}`}
                    className="border border-primary-border bg-zinc-700 p-4"
                  >
                    {item}
                  </td>
                </>
              );
            })
            : data?.rowData?.map((item, index) => {
              return (
                <>
                  {index === 0 && (
                    <td className="border border-primary-border  bg-zinc-700 p-4"></td>
                  )}

                  <td
                    key={`${uniqueKey("key_backtesting_rowdata")}`}
                    className="border border-primary-border bg-zinc-700 p-4"
                  >
                    {item}
                  </td>
                </>
              );
            })}
          <td className="backtestingResultDataRow border border-primary-border p-4">
            {data?.dataType}
          </td>
        </tr>
      ));
    }
  };

  const renderChartDataTable = () => {
    const forecastAccuracy: any[] = [];
    const bias: any[] = [];
    const historicalShipment: any[] = [];
    const statisticalForecast: any[] = [];
    if (isResultVisulizationPage) {
      return renderResultVisulizationChartDataTable();
    } else {
      chartData?.forEach((data) => {
        forecastAccuracy.push(data.accuracy);
        bias.push(data.bias);
        historicalShipment.push(data.historicalShipment);
        statisticalForecast.push(data.statisticalForecast);
      });
      if (
        forecastAccuracy?.length === 0 &&
        bias?.length === 0 &&
        historicalShipment?.length === 0 &&
        statisticalForecast?.length === 0
      ) {
        return null;
      }
      return (
        <table className="text-xs font-bold">
          <tbody>
            {forecastAccuracy?.length > 0 && (
              <tr className="btr-accurey-table-tr">
                <td className="border border-primary-border p-4">
                  <div className="flex items-center">
                    <img src={InfoIcon} alt="info-img" />
                    <span className="pl-2">Forecast Accuracy</span>
                  </div>
                </td>
                {forecastAccuracy?.map((data) => {
                  return (
                    <td
                      key={`${uniqueKey("key_")}-forecast-backtesting-accuracy`}
                      className="backtestingResultDataRow border border-primary-border p-4"
                    >
                      {data}%
                    </td>
                  );
                })}
              </tr>
            )}
            {bias?.length > 0 && (
              <tr className="btr-accurey-table-tr ">
                <td className="border border-primary-border p-4 w-2/3">
                  <div className="flex w-2/3 items-center">
                    <img src={InfoIcon} alt="info-img" />
                    <span className="pl-2">Bias</span>
                  </div>
                </td>
                {bias?.map((data) => {
                  return (
                    <td
                      key={`${uniqueKey("key_")}-forecast-backtesting-bias`}
                      className="backtestingResultDataRow border border-primary-border p-4"
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
            )}
            {statisticalForecast?.length > 0 && (
              <tr className="btr-accurey-table-tr ">
                <td className="border border-primary-border p-4 w-2/3">
                  <div className="flex w-2/3 items-center">
                    <img src={InfoIcon} alt="info-img" />
                    <span className="pl-2"> Statistical Forecast</span>
                  </div>
                </td>
                {statisticalForecast?.map((data) => {
                  return (
                    <td
                      key={`${uniqueKey(
                        "key_"
                      )}-forecast-backtesting-statistical-forecast`}
                      className="backtestingResultDataRow border border-primary-border p-4"
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
            )}
            {historicalShipment?.length > 0 && (
              <tr className="btr-accurey-table-tr ">
                <td className="border border-primary-border p-4 w-2/3">
                  <div className="flex w-2/3 items-center">
                    <img src={InfoIcon} alt="info-img" className="h-5 w-5" />
                    <span className="pl-2"> Historical Shipment</span>
                  </div>
                </td>
                {historicalShipment?.map((data) => {
                  return (
                    <td
                      key={`${uniqueKey(
                        "key_"
                      )}-forecast-backtesting-historical-shipment`}
                      className="backtestingResultDataRow border border-primary-border p-4"
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
            )}
          </tbody>
        </table>
      );
    }
  };

  const renderResultVisulizationChartDataTable = () => {
    const forecastAccuracy: ResultVisulizationBackTestingRowData[] = [];
    const bias: ResultVisulizationBackTestingRowData[] = [];
    resultVisulizationTableData?.forEach((item, index) => {
      const rankString = index === 0 ? "Top Rank" : `Rank ${item.rank}`;
      if (index === 0) {
        forecastAccuracy.push({
          rowData: [
            rankString,
            ...item?.data?.map((item: any) => item.accuracy),
          ],
          dataType: "Forecast Accuracy",
        });
        bias.push({
          rowData: [rankString, ...item?.data?.map((item: any) => item.bias)],
          dataType: "Bias",
        });
      } else {
        forecastAccuracy.push({
          rowData: [
            rankString,
            ...item?.data?.map((item: any) => item.accuracy),
          ],
          dataType: rankString,
        });
        bias.push({
          rowData: [rankString, ...item?.data?.map((item: any) => item.bias)],
          dataType: rankString,
        });
      }
    });
    if (forecastAccuracy?.length === 0 && bias?.length === 0) {
      return null;
    }

    return (
      <table className="text-xs font-bold">
        <tbody>
          {renderResultVisulizationHtmlTableData(forecastAccuracy, 'Forecast Accuracy')}
          {renderResultVisulizationHtmlTableData(bias, "Bias")}
        </tbody>
      </table>
    );
  };

  return (
    <div className="p-6 mt-4 text-white table-holder-main">
      <div className="btr-table-holder">
        {renderChartDataTable()}
        {tableHeader?.length > 0 ? (
          <table className="text-xs font-bold table-auto">
            <tbody>
              <tr className="bg-ternary-bg">
                {tableHeader?.map((data) => {
                  return (
                    <td
                      key={`${uniqueKey("key_")}-forecast-backtesting-headers`}
                      className="backtestingResultDataRow border border-primary-border p-4 "
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
              {renderBackTestingTableData()}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
};

export default BacktestingResultTable;
