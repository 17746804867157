export const BUTTON_VARIANT = {
  text: "text",
  outlined: "outlined",
  contained: "contained",
};

export const PAGE_LIMIT = 50;
export const FIRST_PAGE = 1;

export const DATA_SHOWN_CATEGORY_LIST_NAMES = {
  experimentation: "Experimentation",
  item: "Item",
  customer: "Customer",
  salesGeo: "Sales Geo",
  pfe: "PFE",
};

export const DATA_SHOWN_CATEGORY_LIST = [
  {
    category: "Item",
    options: [
      "Item Category",
      "Product Line",
      "Product Group",
      "Portfolio Group",
      "Brand Name",
      "Sub Brand Name",
      "Package Name",
      "PPG",
      "Size",
      "Count",
      "Planning Item",
      "UPC",
    ],
  },
  {
    category: "Customer",
    options: [
      "Key Account",
      "Key Account Name",
      "Global",
      "Customer Sector",
      "Customer Region",
      "Group",
      "Business Unit",
      "Market Unit",
      "IBP Demand Group",
      "CGI Trade Segment",
    ],
  },
  {
    category: "Sales Geo",
    options: [
      "Sales Sector",
      "Sales Country",
      "Sales Region",
      "Sales Business Org Unit",
      "Sales Division",
    ],
  },
  { category: "PFE", options: ["Segment", "AC1/AC2", "Cluster Id"] },
  // { category: "Experimentation", options: ["Experiment Id"] },
];

export const DATA_SHOWN_CATEGORY_KEY_VALUE_MAPPING = {
  items: {
    "Item Category": "ProductCategoryName",
    "Product Line": "ProductLineName",
    "Product Group": "ProductGroupName",
    "Portfolio Group": "PortfolioGroupName",
    "Brand Name": "BrandName",
    "Sub Brand Name": "SubBrandName",
    "Package Name": "PackageName",
    PPG: "PackageTypeName",
    Size: "PackageSizeName",
    Count: "CaseCountValue",
    "Planning Item": "MaterialGlobalTradeItemNumberDescription",
    UPC: "MaterialGlobalTradeItemNumber",
  },
  customer: {
    "Key Account": "DemandPlanningKeyAccountId",
    "Key Account Name": "DemandPlanningKeyAccountName",
    Global: "CustomerGlobalName",
    "Customer Sector": "CustomerSectorNumber",
    "Customer Region": "CustomerRegionName",
    Group: "CustomerGroupName",
    "Business Unit": "CustomerBusinessUnitName",
    "Market Unit": "CustomerMarketUnitName",
    "IBP Demand Group": "IbpDemandGroup",
    "CGI Trade Segment": "CustomerTradeSegmentCode",
  },
  salesGeo: {
    "Sales Sector": "SalesSectorName",
    "Sales Country": "CountryRegionKeyCode",
    "Sales Region": "SalesRegionName",
    "Sales Business Org Unit": "SalesOrganizationCode",
    "Sales Division": "SalesDivisionName",
  },
  pfe: {
    Segment: "ForecastEngineSegmentationId",
    "AC1/AC2": "ForecastModuleCode",
    "Cluster Id": "ForecastEngineClusterId",
  },
};

export const SIDEBAR_ROUTE_VALUES = {
  statisticalforecastanalysis: "ForecastAnalysis",
  resultvisualization: "ResultVisualization",
  rootcauseanalysis: "RootCauseAnalysis",
} as const;
