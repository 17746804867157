import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Autocomplete, createTheme, ThemeProvider } from "@mui/material";
import { setResultVisualizationFilter } from "../../store/filterSlice";
import { RootState } from "../../store/store";
import { resultVisualisationFilterExperimentaionApi } from "../../api/forecastPageApi";
import { useOktaAuth } from "@okta/okta-react";
import { setExperimentId } from "../../store/resultVisualizationExperimentation";
import loadingSvg from "../../images/bouncing-circles.svg";

const ExperimentationId: React.FC<{ lag?: boolean }> = ({ lag }) => {
    const dispatch = useDispatch();
    const { authState } = useOktaAuth();

    const [experimentIds, setExperimentIds] = useState<string[]>([]);
    const [selectedExperimentIds, setSelectedExperimentIds] = useState<string[]>([]);
    const filter = useSelector((state: RootState) => state.resultVisualizationFilter);
    const expData = useSelector((state: RootState) => state.resultVisualizationExperimentationData);

    const expermentationPayload = {
        shortTermMidTermCode: filter?.term.toString(),
    };


    useEffect(() => {
        resultVisualisationFilterExperimentaionApi(expermentationPayload, authState)
            .then(data => {
                dispatch(setExperimentId(data))
                setExperimentIds(data?.experimentIds)
            });
    }, []);

    const handleSelectionChange = (event: React.SyntheticEvent, value: string[]) => {
        setSelectedExperimentIds(value);
        dispatch(setResultVisualizationFilter({ key: "experimentId", value }));
    };


    const selectedCount = filter?.experimentId.length;

    const darkTheme = createTheme({
        palette: {
            mode: "dark",
            background: {
                paper: "#272727",
            },
            text: {
                primary: "#ffffff",
            },
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <div className="p-2 bg-secondary-bg rounded h-64">
                <div className="flex justify-between">
                    <h3 className="m-2 p-1 font-bold">Experiment ID</h3>
                </div>
                {expData?.loader ? (

                    <div className="flex justify-center">
                        <img
                            src={loadingSvg}
                            alt="loading"
                            className="mt-10"
                            width={40}
                            height={40}
                        />
                    </div>
                ) : (
                    <Autocomplete
                        multiple
                        className="p-2"
                        options={experimentIds}
                        value={filter?.experimentId}
                        onChange={handleSelectionChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {selectedCount > 0 && (

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        backgroundColor: "#94D6F0",
                                                        borderRadius: "4px",
                                                        padding: "0 8px",
                                                        height: "30px",
                                                        color: "black",
                                                        fontSize: "12px",
                                                        marginLeft: "8px",
                                                    }}
                                                >
                                                    {selectedCount}
                                                </div>
                                            )}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        color: "white",
                                        borderColor: "#616161",
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: "#909090",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#616161",
                                    },
                                }}
                            />
                        )}
                        sx={{
                            "& .MuiAutocomplete-popupIndicator": {
                                color: "white",
                            },
                            "& .MuiAutocomplete-clearIndicator": {
                                color: "white",
                            },
                        }}
                    />
                )}
            </div>
        </ThemeProvider>
    );
};

export default ExperimentationId;
