import { useSelector } from "react-redux";
import BasicTabs from "../../../components/Tabs/customTabPanel"
import Treemap from "../../../components/chart/Treemap/Treemap";
import KeyMap from "./KeyMap";
import { RootState } from "../../../store/store";
import { useEffect, useState } from "react";
import { group } from "console";

interface DataPoint {
    x: string;
    y: number;
    color: string
}

export interface Series {
    data: DataPoint[];
}

const RcaSummaryCards = () => {
    const [chartData, setChartData] = useState<any>([])

    let totalColors = [
        "#007EB5",
        "#66C4EE",
        "#6E3BB2",
        "#B992EB",
        "#006841",
        "#339B74"
    ]
    const rcaDataStatLabel = useSelector((state: RootState) => state.rcaData.rcaStats)
    const rcaDataStatGraph = useSelector((state: RootState) => state.rcaData.rcaTreeGraph)

    useEffect(() => { setChartData(transformData(rcaDataStatGraph, totalColors)) }, [rcaDataStatGraph])



    function transformData(rcaTreeGraph: any, predefinedColors: any) {
        const colors = predefinedColors || [
            "#007EB5",
            "#66C4EE",
            "#6E3BB2",
            "#B992EB",
            "#006841",
            "#339B74",
        ];

        // Helper to generate a random color
        const generateRandomColor = () =>
            `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, "0")}`;

        return [{
            data: rcaTreeGraph?.map((item: any, index: any) => ({
                x: item.rcaStat,
                y: item.errorQuantity,
                color: colors[index % colors.length] || generateRandomColor(),
            })),
            group: "apexcharts-axis-0"
        }]
    }

    let statColors = [
        "#007EB5",
        "#66C4EE",
        "#6E3BB2",
        "#B992EB",
        "#006841",
        "#339B74",
        "#FFA800"
    ]


    return (
        <div className="bg-ternary-bg p-3 mt-4 rounded">
            <BasicTabs
                tabLabels={["RCA Stat"]}
                tabStyles={{ color: "white" }}
            >
                <div className="flex flex-row">
                    <div className="basis-9/12">
                        {chartData[0]?.data?.length > 0 ? <Treemap colors={statColors} series={chartData} /> : <div className="flex justify-center">
                            <p className="p-2 pl-40 m-1">
                                No Data to show or no options selected, please choose from the above filters.
                            </p>
                        </div>}
                    </div>
                    <div className="basis-3/12">
                        {chartData[0]?.data?.length > 0 ? <KeyMap series={chartData} /> : <></>}
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="basis-9/12">
                        {/* <Treemap colors={totalColors} series={totalSeries} /> */}
                    </div>
                    <div className="basis-3/12">
                        {/* <KeyMap series={totalSeries} /> */}
                    </div>
                </div>
            </BasicTabs>
        </div>
    )
}

export default RcaSummaryCards