import React, { useState, useEffect } from "react";
import close from "../../images/closeButton.svg";
import arrowUp from "../../images/up.svg";
import arrowDown from "../../images/down.svg";
import searchIcon from "../../images/searchIcon.svg";
import replay from "../../images/replay.svg";
import closeBlue from "../../images/closeBlue.svg";
import check from "../../images/check.svg";
import {
  Modal,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  TextField,
  IconButton,
  Box,
  InputAdornment,
} from "@mui/material";
import "./dataShow.css";
import { DATA_SHOWN_CATEGORY_LIST } from "../../utils/constant";

interface DataShownDialogProps {
  open: boolean;
  defaultSelectedOptions?: string[];
  categoryList?: string[];
  onClose: (selectedItems: string[]) => void;
  onModalClosed: () => void;
}

const DataShownDialog: React.FC<DataShownDialogProps> = ({
  open,
  onClose,
  defaultSelectedOptions = [],
  categoryList = [],
  onModalClosed,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState<string[]>(defaultSelectedOptions);
  const [, setSelectAll] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      setSelectedItems(defaultSelectedOptions);
      setExpandedCategories([...defaultSelectedOptions, ...categoryList]);
    }
  }, [open, defaultSelectedOptions, categoryList]);

  const style = {
    alignItems: "center",
    height: "550px",
    position: "absolute",
    top: "50%",
    left: "50%",
    backgroundColor: "black",
    transform: "translate(-50%, -50%)",
    width: 450,
    borderRadius: "10px",
    bgcolor: "#1D1D1D",
    border: "8px solid #3A3A3A",
    boxShadow: 24,
  };

  const handleToggle = (item: string) => () => {
    const currentIndex = selectedItems.indexOf(item);
    const newChecked = [...selectedItems];

    if (currentIndex === -1) {
      if (selectedItems?.length < 5) {
        newChecked.push(item);
      }
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedItems(newChecked);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const toggleCategory = (category: string) => {
    if (expandedCategories.includes(category)) {
      setExpandedCategories(
        expandedCategories.filter((cat) => cat !== category)
      );
    } else {
      setExpandedCategories([...expandedCategories, category]);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose(selectedItems)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <DialogTitle className="text-xl text-white flex justify-between items-center">
          Data Shown
          <IconButton className="text-white" onClick={onModalClosed}>
            <img src={close} alt="close" />
          </IconButton>
        </DialogTitle>

        <div className="p-4 flex flex-col h-full">
          <div className="flex space-x-12 round">
            <TextField
              variant="outlined"
              placeholder="Search"
              fullWidth
              onChange={handleSearchChange}
              InputProps={{
                className: "text-white bg-secondary-bg",
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={searchIcon}
                      alt="search"
                      style={{ width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
                sx: {
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                    color: "white",
                  },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                },
              }}
            />
          </div>
          <div className="flex justify-center mt-4 text-white">
            <span>Select up to 5 columns to be shown</span>
          </div>
          <div className="overflow-auto flex-1 max-h-72 table-holder">
            {DATA_SHOWN_CATEGORY_LIST
              .map((group) => ({
                ...group,
                options: group.options.filter((option) =>
                  option.toLowerCase().includes(searchQuery)
                ),
              }))
              .filter((group) => group?.options?.length > 0)
              .map((group) => (
                <div key={group.category}>
                  <ListItem
                    onClick={() => toggleCategory(group.category)}
                    className="p-2 mt-4 bg-secondary-bg"
                  >
                    <ListItemText
                      primary={group.category}
                      className="text-white"
                    />
                    <IconButton>
                      <img
                        src={
                          expandedCategories.includes(group.category)
                            ? arrowUp
                            : arrowDown
                        }
                        alt="toggle-arrow"
                      />
                    </IconButton>
                  </ListItem>

                  {expandedCategories.includes(group.category) && (
                    <List disablePadding>
                      {group.options?.map((option) => (
                        <ListItem key={option} className="pl-8">
                          <Checkbox
                            sx={{ color: "white" }}
                            checked={selectedItems.includes(option)}
                            onChange={handleToggle(option)}
                            className="text-white"
                            disabled={
                              selectedItems?.length >= 5 &&
                              !selectedItems.includes(option)
                            }
                          />
                          <ListItemText
                            primary={option}
                            className="text-white"
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </div>
              ))}
          </div>

          <div className="flex justify-end mt-4">
            <button
              onClick={() => setSelectedItems(defaultSelectedOptions)}
              className="reset-button"
            >
              <img src={replay} alt="reset" className="p-1" />
              Reset
            </button>
            <button onClick={onModalClosed} className="cancel-button">
              <img src={closeBlue} alt="cancel" className="p-1" />
              Cancel
            </button>
            <button onClick={() => onClose(selectedItems)} className="save-button">
              <img src={check} alt="save" className="p-1" />
              Save
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DataShownDialog;
