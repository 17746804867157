// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.anot-hold::-webkit-scrollbar {
    height: 10px; /* Height of horizontal scrollbar */
    background-color: grey; /* Background color of the scrollbar track */
    width: 10px;
    border-radius: 10px;
  }
  
  .anot-hold::-webkit-scrollbar-corner {background-color: #1D1D1D;}
  
  .anot-hold::-webkit-scrollbar-thumb {
    background-color: rgb(55, 55, 55); /* Color of the scrollbar thumb */
    border-radius: 10px; 
    border: 2px solid grey;
  }`, "",{"version":3,"sources":["webpack://./src/components/chart/ScatterLine/anotattionsCss.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,mCAAmC;IACjD,sBAAsB,EAAE,4CAA4C;IACpE,WAAW;IACX,mBAAmB;EACrB;;EAEA,sCAAsC,yBAAyB,CAAC;;EAEhE;IACE,iCAAiC,EAAE,iCAAiC;IACpE,mBAAmB;IACnB,sBAAsB;EACxB","sourcesContent":[".anot-hold::-webkit-scrollbar {\n    height: 10px; /* Height of horizontal scrollbar */\n    background-color: grey; /* Background color of the scrollbar track */\n    width: 10px;\n    border-radius: 10px;\n  }\n  \n  .anot-hold::-webkit-scrollbar-corner {background-color: #1D1D1D;}\n  \n  .anot-hold::-webkit-scrollbar-thumb {\n    background-color: rgb(55, 55, 55); /* Color of the scrollbar thumb */\n    border-radius: 10px; \n    border: 2px solid grey;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
