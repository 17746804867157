import React, { useImperativeHandle, useRef } from "react";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  ChartOptions,
  ChartData,
  BarElement,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  BarElement,
  annotationPlugin,
  zoomPlugin
);

export interface ChartAnnotation {
  type: "line";
  borderColor: string;
  borderWidth: number;
  value: string;
  scaleID: string;
  label: {
    backgroundColor: string;
    display: boolean;
    color: string;
    content: string;
    rotation?: number;
  };
}

export interface TooltipData {
  promoDiscount: number;
  maxDiscount: number;
  avgDiscount: number;
  minDiscount: number;
  periodWeekNumber: string;
  historicalShipment?: number;
  statisticalForecast?: number;
}

interface LinechartProps {
  chartRef: React.RefObject<any>;
  chartData: ChartData<"line" | "scatter" | "bar", { x: string; y: number }[]>;
  chartAnnotations: ChartAnnotation[];
  labels: string[];
  customTooltipData: TooltipData[];
}

const Linechart: React.FC<LinechartProps> = ({
  chartRef,
  chartData,
  chartAnnotations,
  labels,
  customTooltipData,
}) => {
  const internalChartRef = useRef<any>(null);
  useImperativeHandle(chartRef, () => ({
    resetZoom: () => {
      internalChartRef.current?.resetZoom();
    },
  }));

  const filteredChartData = {
    ...chartData,
    datasets: chartData.datasets?.map((dataset) => ({
      ...dataset,
      data: dataset.data.filter((point) => point.y >= 0),
      order: dataset.label === "Promo Discount" ? 1 : 10,
    })),
  };

  const options: ChartOptions<"line" | "scatter" | "bar"> = {
    scales: {
      x: {
        type: "category",
        labels: labels,
        title: {
          display: true,
          color: "#FFFFFF",
        },
        ticks: {
          color: "#FFFFFF",
        },
      },
      y: {
        type: "linear",
        title: {
          display: true,
          text: "Volume",
          color: "#FFFFFF",
        },
        ticks: {
          color: "#FFFFFF",
        },
      },
      y1: {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "Percentage",
          color: "#FFFFFF",
        },
        ticks: {
          color: "#FFFFFF",
          callback: (value) => `${Number(value).toFixed(0)}%`,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const dataset =
              filteredChartData.datasets[tooltipItems[0]?.datasetIndex];
            // Show title only for datasets other than "Previous Year"
            if (dataset?.label === "Previous Year") return "";
            return tooltipItems[0]?.label || ""; // Use default label for others
          },
          label: (tooltipItem) => {
            const dataset =
              filteredChartData.datasets[tooltipItem.datasetIndex];
            const dataPoint = dataset?.data[tooltipItem.dataIndex];
            const tooltipData = customTooltipData[tooltipItem.dataIndex];

            if (dataset?.label === "Promo Discount" && tooltipData) {
              const {
                maxDiscount,
                avgDiscount,
                minDiscount,
                promoDiscount,
                periodWeekNumber,
              } = tooltipData;
              return [
                `${periodWeekNumber} ${Math.trunc(promoDiscount)}% of Volume`,
                `Max Discount: ${Math.trunc(maxDiscount)}%`,
                `Average Discount: ${Math.trunc(avgDiscount)}%`,
                `Min Discount: ${Math.trunc(minDiscount)}%`,
              ];
            }

            if (dataset?.label === "Statistical Forecast") {
              return `Statistical Forecast: ${Math.trunc(dataPoint?.y)}`;
            }

            if (dataset?.label === "Historical Shipments (Adjusted)") {
              return `Historical Shipment: ${Math.trunc(dataPoint?.y)}`;
            }

            if (dataset?.label === "Previous Year") {
              return `Previous Year Volume: ${Math.trunc(dataPoint?.y)}`;
            }

            // Default tooltip for other datasets
            return `(${dataPoint?.x}, ${Math.trunc(dataPoint?.y)})`;
          },
        },
        backgroundColor: "#000000",
        titleColor: "#FFFFFF",
        bodyColor: "#FFFFFF",
        borderColor: "rgba(255,255,255,0.5)",
        borderWidth: 1,
        displayColors: true,
      },

      legend: {
        position: "top",
        align: "start",
        labels: {
          color: "#FFFFFF",
          usePointStyle: true,
        },
      },
      annotation: {
        annotations: chartAnnotations?.map((annotation) => ({
          ...annotation,
          label: {
            color: "#FFFFFF",
            display: true,

            rotation: 90,
            font: { size: 12 },
          },
        })),
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
            speed: 0.1,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
        },
        pan: {
          enabled: true,
          mode: "xy",
        },
      },
    },
  };

  const chartContainerStyle: React.CSSProperties = {
    width: "90%",
    height: "380px",
  };

  return (
    <div style={chartContainerStyle} className="flex flex-col space-y-3">
      <Chart
        ref={internalChartRef}
        type="line"
        options={options}
        data={filteredChartData}
      />
    </div>
  );
};

export default Linechart;
