// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.fd-table-holder::-webkit-scrollbar {
    height: 10px; /* Height of horizontal scrollbar */
    background-color: grey; /* Background color of the scrollbar track */
    width: 10px;
    border-radius: 10px;
  }
  
  .fd-table-holder::-webkit-scrollbar-corner {background-color: #1D1D1D;}
  
  .fd-table-holder::-webkit-scrollbar-thumb {
    background-color: rgb(55, 55, 55); /* Color of the scrollbar thumb */
    border-radius: 10px; 
    border: 2px solid grey;
  }

  
  .fd-table-holder {
    overflow: auto;
    height: 60.5vh;
    width: 100%;
  }
  
  table {
    width: 100%;
    table-layout: fixed;
  }
  
  th,
  td {
    text-align: left;
    padding: 8px;
    width: 195px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ForecastDecomposeTable/ForecastDecomposeTable.css"],"names":[],"mappings":";AACA;IACI,YAAY,EAAE,mCAAmC;IACjD,sBAAsB,EAAE,4CAA4C;IACpE,WAAW;IACX,mBAAmB;EACrB;;EAEA,4CAA4C,yBAAyB,CAAC;;EAEtE;IACE,iCAAiC,EAAE,iCAAiC;IACpE,mBAAmB;IACnB,sBAAsB;EACxB;;;EAGA;IACE,cAAc;IACd,cAAc;IACd,WAAW;EACb;;EAEA;IACE,WAAW;IACX,mBAAmB;EACrB;;EAEA;;IAEE,gBAAgB;IAChB,YAAY;IACZ,YAAY;EACd","sourcesContent":["\n.fd-table-holder::-webkit-scrollbar {\n    height: 10px; /* Height of horizontal scrollbar */\n    background-color: grey; /* Background color of the scrollbar track */\n    width: 10px;\n    border-radius: 10px;\n  }\n  \n  .fd-table-holder::-webkit-scrollbar-corner {background-color: #1D1D1D;}\n  \n  .fd-table-holder::-webkit-scrollbar-thumb {\n    background-color: rgb(55, 55, 55); /* Color of the scrollbar thumb */\n    border-radius: 10px; \n    border: 2px solid grey;\n  }\n\n  \n  .fd-table-holder {\n    overflow: auto;\n    height: 60.5vh;\n    width: 100%;\n  }\n  \n  table {\n    width: 100%;\n    table-layout: fixed;\n  }\n  \n  th,\n  td {\n    text-align: left;\n    padding: 8px;\n    width: 195px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
