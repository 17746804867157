import { Box, Modal } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BacktestingResultTable from "../../components/BackTesting/BacktestingResultTable/BacktestingResultTable";
import LineColumnChart from "../../components/chart/LineColumn/LineColumnChart";
import { DataShownDialog } from "../../components/Datashow";
import closeButton from "../../images/closeButton.svg";
import enlargeButton from "../../images/enlargeButton.svg";
import { RootState } from "../../store/store";
import loadingSvg from "../../images/bouncing-circles.svg";
import rectangleMarker from "../../images/rectangle-purple.svg";
import rectanglePinkLight from "../../images/rectanglePinkLight.svg";
import rectangleBrown from "../../images/rectangleBrown.svg";
import ovalDarkPink from "../../images/ovalDarkPink.svg";
import ovalLightBlue from "../../images/ovalLightBlue.svg";
import ovalDarkBlue from "../../images/ovalDarkBlue.svg";
import settingsIcon from "../../images/Settings.svg";
import {
  updateBackTestingTotalPages,
  updateBackTestingPageNumber,
  resultVisulizationBackTestingTableDataSetter,
} from "../../store/resultVisualizationPredefined";
import { Pagination } from "../../components/Pagination";
import {
  calculateTotalPages,
  forecastBackTestingDefaultGroupBy,
  mapperForGroupByLableToKey,
  resultVisualisationTableAPI,
} from "../../api/forecastPageApi";
import { useOktaAuth } from "@okta/okta-react";
import { PAGE_LIMIT } from "../../utils/constant";
import { BacktestingResultsProps } from "../../store/foreCastBackTestingDataSlice";
import RankDropdownForward from "../../components/BackTesting/ResultVisulizationBackTesting/RankDropDown";

interface ChartDataset {
  name: string;
  data: number[];
  color: string;
  type?: string;
  icon: any;
  yAxisIndex: number;
}

function ResultVisulizationBacktestingResults({
  isResultVisulizationPage,
}: BacktestingResultsProps) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [chartDataPredefined, setChartDataPreDefined] = useState<any>([]);
  const [chartDataPredefinedLabels, setChartDataPreDefinedLabels] =
    useState<any>([]);
  const { authState } = useOktaAuth();
  const [selectedDataShownOptionList, setSelectedDataShownOptionList] =
    useState<string[]>(forecastBackTestingDefaultGroupBy);
  const [selectedDataShownCategoryList, setSelectedDataShownCategoryList] =
    useState<string[]>([]);

  const resultVisulizationBackTestingTableData = useSelector(
    (state: RootState) => state.resultVisulizationBackTestingTableDataSlice
  );

  const foreCastBackTestingDataLoading = useSelector(
    (state: RootState) => state.foreCastBackTestingDataLoading
  );

  const resultVisualizationData = useSelector(
    (state: RootState) => state.resultVisualizationData
  );

  const loader = useSelector(
    (state: RootState) => state.resultVisualizationData
  );

  const [openSettings, setOpenSettings] = useState(false);
  const filter = useSelector(
    (state: RootState) => state.resultVisualizationFilter
  );
  const resultVisulizationBackTestingPaginationDate = useSelector(
    (state: RootState) => state.resultVisulizationBackTestingPagination
  );

  const extractData = (
    response: any,
    index: number,
    key: string,
    buildLabels: string[]
  ) => {
    const dataMap =
      response?.[index]?.data?.reduce((acc: any, item: any) => {
        acc[item?.periodWeekNumber] = item?.[key] ?? 0;
        return acc;
      }, {}) || {};

    return buildLabels.map((label) => dataMap[label] || 0);
  };

  const buildLabels = (response: any): string[] => {
    // Flatten the response to extract periodWeekNumber and timeFrameDate pairs
    const pairs: { periodWeekNumber: string; timeFrameDate: string }[] =
      response.flatMap((rank: any) =>
        rank?.data?.map((item: any) => ({
          periodWeekNumber: item?.periodWeekNumber,
          timeFrameDate: item?.timeFrameDate,
        }))
      );

    // Remove duplicates based on periodWeekNumber, preserving the earliest timeFrameDate
    const uniquePairs = Array.from(
      pairs.reduce((map, pair) => {
        if (
          !map.has(pair.periodWeekNumber) ||
          new Date(pair.timeFrameDate).getTime() <
            new Date(map.get(pair.periodWeekNumber)!).getTime()
        ) {
          map.set(pair.periodWeekNumber, pair.timeFrameDate);
        }
        return map;
      }, new Map<string, string>())
    );

    // Sort the unique pairs by timeFrameDate
    uniquePairs.sort(
      ([, dateA], [, dateB]) =>
        new Date(dateA).getTime() - new Date(dateB).getTime()
    );

    // Extract and return the sorted periodWeekNumber
    return uniquePairs.map(([periodWeekNumber]) => periodWeekNumber);
  };

  useEffect(() => {
    const createChartData = (
      response: any
    ): { datasets: ChartDataset[]; labels: string[] } => {
      const datasets: ChartDataset[] = [
        {
          name: "Top Recipe Forecast Bias",
          data: extractData(response, 0, "bias", buildLabels(response)),
          color: "#6E3BB2",
          type: "line",
          icon: rectanglePinkLight,
          yAxisIndex: 0,
        },
        {
          name: "Rank 2 Bias",
          data: extractData(response, 1, "bias", buildLabels(response)),
          color: "#8bf0fa",
          type: "line",
          icon: rectangleMarker,
          yAxisIndex: 0,
        },
        {
          name: "Rank 3 Bias",
          data: extractData(response, 2, "bias", buildLabels(response)),
          color: "#fd9882",
          type: "line",
          icon: rectangleBrown,
          yAxisIndex: 0,
        },
        {
          name: "Top Recipe Forecast Accuracy",
          data: extractData(response, 0, "accuracy", buildLabels(response)),
          color: "#B992EB",
          type: "bar",
          icon: ovalDarkPink,
          yAxisIndex: 0,
        },
        {
          name: "Rank 2 Forecast Accuracy",
          data: extractData(response, 1, "accuracy", buildLabels(response)),
          color: "#33B1E8",
          type: "bar",
          icon: ovalLightBlue,
          yAxisIndex: 0,
        },
        {
          name: "Rank 3 Forecast Accuracy",
          data: extractData(response, 2, "accuracy", buildLabels(response)),
          color: "#994900",
          type: "bar",
          icon: ovalDarkBlue,
          yAxisIndex: 0,
        },
      ];

      const labels: any = buildLabels(response);

      return { datasets, labels };
    };

    if (resultVisualizationData?.chartData?.length > 0) {
      const { datasets, labels } = createChartData(
        resultVisualizationData?.chartData
      );
      setChartDataPreDefined(datasets);
      setChartDataPreDefinedLabels(labels);
    }
  }, [resultVisualizationData?.chartData]);

  const renderResultVisualizationChartHtml = (): ReactElement => {
    if (chartDataPredefinedLabels?.length < 0) {
      return (
        <div className="flex justify-center">
          <img
            src={loadingSvg}
            alt="loading"
            className="mt-10"
            width={40}
            height={40}
          />
        </div>
      );
    }

    if (chartDataPredefined?.length > 0) {
      return (
        <LineColumnChart
          seriesData={chartDataPredefined}
          columnWidth={"4"}
          labels={chartDataPredefinedLabels}
          rightTitle="Bias"
          leftTitle="Forecast Accuracy"
        />
      );
    }

    return <></>;
  };

  const handleSettingModal = () => {
    mapperForGroupByLableToKeyHandler(selectedDataShownOptionList);
    setOpenSettings(true);
  };

  const renderBackTestingTableHtml = (): ReactElement => {
    return (
      <>
        {resultVisulizationBackTestingTableData?.isTableDataLoading ? (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        ) : (
          <BacktestingResultTable
            tableHeader={resultVisulizationBackTestingTableData?.tableHeader}
            chartData={[]}
            backTestingTableData={
              resultVisulizationBackTestingTableData?.backTestingTableData
            }
            isResultVisulizationPage={isResultVisulizationPage}
            resultVisulizationTableData={resultVisualizationData.chartData}
          />
        )}
      </>
    );
  };

  const mapperForGroupByLableToKeyHandler = (
    selectedItemList: string[] = []
  ) => {
    const { selectedGroupByOptions, selectedCategoryType } =
      mapperForGroupByLableToKey(selectedItemList);
    setSelectedDataShownOptionList(selectedItemList);
    setSelectedDataShownCategoryList(selectedCategoryType);

    return selectedGroupByOptions;
  };

  const updateBackTestingTableData = (selectedOptionList: string[]) => {
    const payloadFilters = {
      shortTermMidTermCode: filter?.term.toString(),
      snapshotDate: [],
      brandName: filter?.brand,
      subBrandName: filter?.subBrand,
      productLineName: filter?.productLine,
      productGroupName: filter?.productGroup,
      materialGlobalTradeItemNumberDescription: filter?.planningItem,
      packageSizeName: filter?.size,
      productCategoryName: filter?.category,
      portfolioGroupName: filter?.mg4PortfolioGroup,
      packageName: filter?.count,
      packTypeName: filter.ppg,
      keyAccount: filter?.keyAccount,
      customerGlobalName: filter?.global,
      customerSectorNumber: filter?.customerSector,
      customerRegionName: filter?.customerRegion,
      customerGroupName: filter?.customerGroup,
      customerBusinessUnitName: filter?.businessUnit,
      customerMarketUnitName: filter?.marketUnit,
      ibpDemandGroup: filter?.ibpDemandGroup,
      customerTradeSegmentDescription: filter?.cg1TrageSegment,
      salesSectorName: filter?.salesSector,
      salesRegionName: filter?.salesRegion,
      salesCountryCode: filter?.country,
      salesOrganizationCode: filter?.businessOrg,
      salesDivisionName: filter?.division,
      forecastEngineClusterId: filter?.clusters,
      forecastModuleCode: filter?.ac1ac2,
      forecastEngineSegmentationId: filter?.segment,
      packageTypeName: filter?.container,
    };

    const forecastBackTestingApiRequestBody = {
      ...payloadFilters,
      pageNumber: 1,
      pageSize: PAGE_LIMIT,
      groupBy: mapperForGroupByLableToKeyHandler(selectedOptionList),
    };

    setOpenSettings(false);
    dispatch(
      resultVisulizationBackTestingTableDataSetter({
        tableHeader: [],
        backTestingTableData: [],
        isTableDataLoading: true,
      })
    );

    resultVisualisationTableAPI(forecastBackTestingApiRequestBody, authState)
      .then((response) => {
        const totalBackTestingPages = calculateTotalPages(
          PAGE_LIMIT,
          response?.totalCount
        );
        dispatch(
          updateBackTestingTotalPages({
            totalPages: totalBackTestingPages,
            pageLimit: resultVisulizationBackTestingPaginationDate.pageLimit,
            pageNumber: resultVisulizationBackTestingPaginationDate.pageNumber,
            isDataShownClicked:
              resultVisulizationBackTestingPaginationDate.isDataShownClicked,
          })
        );
        dispatch(
          resultVisulizationBackTestingTableDataSetter({
            tableHeader: response?.dynamicTableHeader,
            backTestingTableData: response?.dfuBasedGroupedData,
            isTableDataLoading: false,
          })
        );
      })
      .catch((error) => {
        console.log("forecastBackTestingTableApi error", error);
      });
  };

  const onModalClosed = () => {
    setOpenSettings(false);
  };

  const renderBackTestingDataShownModalHtml = (): ReactElement => {
    return (
      <>
        <DataShownDialog
          open={openSettings}
          defaultSelectedOptions={selectedDataShownOptionList}
          categoryList={selectedDataShownCategoryList}
          onClose={(itemList) => updateBackTestingTableData(itemList)}
          onModalClosed={onModalClosed}
          showModel={true}
        />
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute" as const,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "#272727",
              boxShadow: 24,
              borderRadius: 2,
            }}
          >
            <div className="m-4">
              <div className="flex justify-between p-4">
                <h6 className="font-bold text-white">Backtesting Results</h6>
                <button onClick={handleClose}>
                  <img src={closeButton} alt="close" />
                </button>
              </div>
              <div className="bg-ternary-bg rounded m-4">
                {chartDataPredefined?.length > 0 && (
                  <>
                    <LineColumnChart
                      seriesData={chartDataPredefined}
                      columnWidth={"4"}
                      labels={chartDataPredefinedLabels}
                      rightTitle="Bias"
                      leftTitle="Forecast Accuracy"
                    />
                    <BacktestingResultTable
                      tableHeader={
                        resultVisulizationBackTestingTableData?.tableHeader
                      }
                      chartData={[]}
                      backTestingTableData={
                        resultVisulizationBackTestingTableData?.backTestingTableData
                      }
                      isResultVisulizationPage={isResultVisulizationPage}
                      resultVisulizationTableData={
                        resultVisualizationData.chartData
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </>
    );
  };

  const renderBackTestingHtml = (): ReactElement => {
    const isDataEmpty =
      !foreCastBackTestingDataLoading?.isDataLoading &&
      resultVisualizationData?.average &&
      resultVisulizationBackTestingTableData?.backTestingTableData?.length ===
        0 &&
      resultVisualizationData?.chartData?.length === 0;

    if (isDataEmpty) {
      if (loader) {
        return (
          <div className="bg-ternary-bg rounded p-2">
            <div className="flex justify-center">
              <p className="p-2 m-1">
                No Data to show or no options selected, please choose from the
                above filters.
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        );
      }
    }

    return (
      <>
        <div className="bg-ternary-bg rounded">
          {renderResultVisualizationChartHtml()}
          {renderBackTestingTableHtml()}
        </div>
        {renderBackTestingDataShownModalHtml()}
      </>
    );
  };

  const handlePageNumberUpdateEvent = (currentPageNumber: number) => {
    dispatch(
      updateBackTestingPageNumber({
        pageLimit: resultVisulizationBackTestingPaginationDate?.pageLimit,
        pageNumber: currentPageNumber,
        totalPages: resultVisulizationBackTestingPaginationDate?.totalPages,
        isDataShownClicked: false,
        groupBySelectedField: selectedDataShownOptionList,
      })
    );
  };

  return (
    <div className="p-5 bg-secondary-bg mt-4 rounded">
      <div className="flex justify-between">
        <div className="flex">
          <h6 className="text-lg font-bold mb-4 mt-2">Backtesting Results</h6>
          <div className="m-2 rounded bg-zinc-600 pt-2 mb-6">
            {filter?.term.includes("ST") ? (
              <span className="p-1">Lag 4</span>
            ) : (
              <span className="p-1">Lag 6</span>
            )}
          </div>
          <RankDropdownForward rankType="rankBackTestingPredefined" />
        </div>
        {!foreCastBackTestingDataLoading?.isDataLoading ||
        resultVisulizationBackTestingTableData?.backTestingTableData?.length >
          0 ? (
          <div>
            <button onClick={handleSettingModal}>
              <img
                className="mr-5"
                width={20}
                height={20}
                src={settingsIcon}
                alt="settingsIcon"
              />
            </button>
            <button onClick={handleOpen}>
              <img src={enlargeButton} alt="zoom" />
            </button>
          </div>
        ) : null}
      </div>
      <>
        {renderBackTestingHtml()}
        <div className="flex justify-center">
          {resultVisulizationBackTestingTableData?.backTestingTableData
            ?.length > 0 && (
            <div className="pt-2">
              <Pagination
                totalPages={
                  resultVisulizationBackTestingPaginationDate?.totalPages
                }
                activePageNumber={
                  resultVisulizationBackTestingPaginationDate?.pageNumber
                }
                updateBackTestingPageNumber={(data) =>
                  handlePageNumberUpdateEvent(data)
                }
              />
            </div>
          )}
        </div>
      </>
    </div>
  );
}

export default ResultVisulizationBacktestingResults;
