import BasicTabs from "../../../components/Tabs/customTabPanel"
import Treemap from "../../../components/chart/Treemap/Treemap";
import KeyMap from "./KeyMap";

interface DataPoint {
    x: string;
    y: number;
    color: string
}
  
export interface Series {
    data: DataPoint[];
}

const RcaSummaryCards = () => {

    let totalColors = [
        "#007EB5",
        "#66C4EE",
        "#6E3BB2",
        "#B992EB",
        "#006841",
        "#339B74"
    ]

    const totalSeries: Series[] = [
        {
          data: [
            { x: "2.1.2 Customer Collaboration Adjustment Not Done", y: 350, color: "#007EB5" },
            { x: "3.1.3 Wrong Actuals Data", y: 90, color: "#66C4EE" },
            { x: "3.1.1 Wrong Master Data", y: 90, color: "#6E3BB2" },
            { x: "2.2.2 Inaccurate Channel Trend Impact", y: 120, color: "#B992EB" },
            { x: "1.1.1 Uncaptured Trend", y: 150, color: "#006841"},
            { x: "1.1.1 Uncaptured Trend", y: 100, color: "#339B74"},
          ]
        }
    ];

    let statColors = [
        "#007EB5",
        "#66C4EE",
        "#6E3BB2",
        "#B992EB",
        "#006841",
        "#339B74",
        "#FFA800"
    ]

    const statSeries: Series[] = [
        {
          data: [
            { x: "1.1.1 Uncaptured Trend", y: 350, color: "#007EB5" },
            { x: "1.2.1 Inaccurate Promotion Impact on Forecast", y: 250, color: "#66C4EE" },
            { x: "1.2.5 Inaccurate Holidays Impact on Forecast", y: 190, color: "#6E3BB2" },
            { x: "1.1.2 Uncaptured Seasonality", y: 150, color: "#B992EB" },
            { x: "3.2.1 Out of Stock/Allocation", y: 130, color: "#006841"},
            { x: "2.4.4 Inaccurate NPD Phasing", y: 100, color: "#339B74"},
            { x: "2.4.1 Inaccurate Network Changes Phasing", y: 200, color: "#FFA800"},
          ]
        }
    ];

    return (
    <div className="bg-ternary-bg p-3 mt-4 rounded">
        <BasicTabs
        tabLabels={["RCA Stat", "RCA Total"]}
        tabStyles={{ color: "white" }}
    >
        <div className="flex flex-row">
            <div className="basis-9/12">
                <Treemap colors={statColors} series={statSeries}/>
            </div>
            <div className="basis-3/12">
                <KeyMap series={statSeries}/>
            </div>
        </div>
        <div className="flex flex-row">
            <div className="basis-9/12">
                <Treemap colors={totalColors} series={totalSeries}/>
            </div>
            <div className="basis-3/12">
                <KeyMap series={totalSeries}/>
            </div>
        </div>
    </BasicTabs>
    </div>
    )
}

export default RcaSummaryCards