import React, { useEffect, useState } from "react";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import loadingSvg from "../../images/bouncing-circles.svg";
import { getAccuracyColor, getBiasColor } from "../../utils/functions";
import RankDropdownForward from "../../components/BackTesting/ResultVisulizationBackTesting/RankDropDown";
interface RankData {
  local: number;
  global: number;
}

interface OverallAverage {
  accuracy: number;
  bias: number;
  historicalShipments: number;
  statisticalForecast: number;
}

interface ComparisonData {
  rankBasedPercent: {
    rank: number;
    data: RankData[];
  }[];
  overallAverage: {
    rank: number;
    data: OverallAverage;
  }[];
  hierarchyComparisonResult: {
    H1: number;
    H2: number;
    H3: number;
  };
}

interface ComparisonResultPredefinedRunsProps {
  data?: ComparisonData; // Make data optional
}

const ComparisonResultPredefinedRuns: React.FC<
  ComparisonResultPredefinedRunsProps
> = ({ data }) => {
  const rankBasedPercent = data?.rankBasedPercent || [];
  const overallAverage = data?.overallAverage || [];
  const hierarchyComparisonResult = data?.hierarchyComparisonResult || {
    H1: 0,
    H2: 0,
    H3: 0,
  };

  const [h1Width, setH1Width] = useState("50");
  const [h2Width, setH2Width] = useState("50");
  const [h3Width, setH3Width] = useState("0");

  const uniqueKey = require("unique-key");
  const filter = useSelector((state: RootState) => state.rcaFilter);
  const chartDataLength = useSelector(
    (state: RootState) => state?.resultVisualizationData?.chartData?.length
  );
  const loader = useSelector(
    (state: RootState) => state?.resultVisualizationData?.loader
  );

  useEffect(() => {
    const totalSections = filter?.term?.includes("MT") ? 3 : 2; // If "MT" is included, show H3

    const equalWidth = Math.floor(100 / totalSections).toString();

    setH1Width(equalWidth);
    setH2Width(equalWidth);
    setH3Width(filter?.term?.includes("MT") ? equalWidth : "0");
  }, [filter?.term]);

  const getPercentage = (value: number | undefined): string =>
    value !== undefined ? `${value.toFixed(1)}%` : "0%";

  const formatWithCommas = (value: number | undefined): string =>
    value !== undefined ? value.toLocaleString() : "0";

  return (
    <div className="p-5 bg-secondary-bg mt-4 rounded">
      <div className="flex mb-2">
        <p className="text-lg font-medium mb-4 mt-2 pt-2">Comparison Results</p>
        <RankDropdownForward rankType="rankPredefined" />
      </div>

      {loader && (
        <div className="flex justify-center">
          <img
            src={loadingSvg}
            alt="loading"
            className="mt-10"
            width={40}
            height={40}
          />
        </div>
      )}

      {!loader && chartDataLength > 0 && (
        <div className="mt-1">
          <div className="w-full flex mt-5">
            <div
              className="bg-ternary-bg p-3 rounded-l-lg"
              style={{ width: `${h1Width}%` }}
            >
              <p className="text-2xl font-bold">
                {getPercentage(hierarchyComparisonResult.H1)}
              </p>
              <p className="text-sm font-normal text-secondary-text">
                H1: UPC / KA / SR
              </p>
            </div>

            <div
              className="bg-ternary-bg p-3 border-l border-x-gray-700"
              style={{ width: `${h2Width}%` }}
            >
              <p className="text-2xl font-bold">
                {getPercentage(hierarchyComparisonResult.H2)}
              </p>
              <p className="text-sm font-normal text-secondary-text">
                H2: L3 / KA / SR
              </p>
            </div>

            {filter?.term?.includes("MT") && (
              <div
                className="bg-ternary-bg p-3 border-l border-x-gray-700 rounded-r-lg"
                style={{ width: `${h3Width}%` }}
              >
                <p className="text-2xl font-bold">
                  {getPercentage(hierarchyComparisonResult.H3)}
                </p>
                <p className="text-sm font-normal text-secondary-text">
                  H3: MG4/KA/SR
                </p>
              </div>
            )}
          </div>
          {rankBasedPercent.map((rankItem) => {
            const overallItem = overallAverage.find(
              (item) => item.rank === rankItem.rank
            );

            return (
              <div key={rankItem.rank} className="mt-8">
                <p className="text-lg font-bold text-secondary-text">
                  {rankItem.rank === 1 ? "Top Recipe" : `Rank ${rankItem.rank}`}
                </p>
                {rankItem.data?.map((dataItem) => (
                  <div
                    key={uniqueKey("key_")}
                    className="flex flex-wrap gap-4 text-base text-secondary-text"
                  >
                    {Object.entries(dataItem).map(([key, value]) => (
                      <div key={key} className="capitalize">
                        {`${
                          key.charAt(0).toUpperCase() + key.slice(1)
                        }: ${getPercentage(value)}`}
                      </div>
                    ))}
                  </div>
                ))}

                {overallItem && (
                  <div className="w-full grid grid-cols-4 gap-0 mt-5">
                    <div
                      className={`${
                        getAccuracyColor(overallItem.data.accuracy).bg
                      } ${
                        getAccuracyColor(overallItem.data.accuracy).text
                      } p-3 rounded-l-lg`}
                    >
                      <p className="text-2xl font-bold">
                        {`${overallItem.data.accuracy || 0}%`}
                      </p>
                      <p className="text-sm font-normal">Forecast Accuracy</p>
                    </div>
                    <div
                      className={`${getBiasColor(overallItem.data.bias).bg} ${
                        getBiasColor(overallItem.data.bias).text
                      } p-3 border-l border-black`}
                    >
                      <p className="text-2xl font-bold">
                        {getPercentage(overallItem.data.bias)}
                      </p>
                      <p className="text-sm font-normal">Bias</p>
                    </div>
                    <div className="bg-ternary-bg p-3 border-l border-x-gray-700">
                      <p className="text-2xl font-bold">
                        {formatWithCommas(overallItem.data.statisticalForecast)}
                      </p>
                      <p className="text-sm font-normal">Forecast</p>
                    </div>
                    <div className="bg-ternary-bg p-3 rounded-r-lg border-l border-x-gray-700">
                      <p className="text-2xl font-bold">
                        {formatWithCommas(overallItem.data.historicalShipments)}
                      </p>
                      <p className="text-sm font-normal">
                        Historical Shipments
                      </p>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {!loader && chartDataLength === 0 && (
        <div className="bg-ternary-bg rounded p-2">
          <div className="flex justify-center">
            <p className="p-2 m-1">
              No Data to show or no options selected, please choose from the
              above filters.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComparisonResultPredefinedRuns;
