import React, { useState } from 'react'
import './SideNavbar.css'
import NavIcon from '../../images/NavOpenClose.svg';
import HamburgIcon from '../../images/Hamburger.svg';
import LabelIcon from '../../images/Label.svg';
import ChartIcon from '../../images/Chart.svg';
import PepsiNavIcon from '../../images/Floating Button.svg';

import { Link } from 'react-router-dom';

interface SideNavbarProps {
  height: number
}

const SideNavbar:React.FC<SideNavbarProps> = ({height}) => {
  const [open,setOpen] = useState(false);
  return (
          <div className={`pt-3 ${open?  "w-72" : "w-16"} transition-all absolute z-50 bg-ternary-bg flex flex-col justify-between`} style={{height:height}}>
            <div className='flex flex-col'>
              {
                !open?<img className='cursor-pointer sideNavIcon mt-2'  src={HamburgIcon} alt="NavIcon" onClick={()=>{setOpen(!open)}} />:
                <div className='flex justify-between font-bold '><span></span> <img src={NavIcon} className='rotate-180 cursor-pointer sideNavIcon mr-2 mb-2' alt="Close icon" onClick={()=>{setOpen(!open)}}  /></div>
              }
              <div className='mt-12'>
                <Link to="/statisticalforecastanalysis" style={{color:"gainsboro",fontWeight:"bold"}}>
                  <div className="flex">
                    <img className='cursor-pointer sideNavIcon'  src={LabelIcon} alt="LabelIcon"/> 
                    <p className={`pl-3 ${open?  "" : "hidden"}`}>Statistical Forecast Analysis</p>
                  </div>
                  
                </Link>
              </div>
              <div className='mt-12'>
                <Link to="/resultvisualization" style={{color:"gainsboro",fontWeight:"bold"}}>
                  <div className="flex">
                    <img className='cursor-pointer sideNavIcon'  src={ChartIcon} alt="ChartIcon"/>
                    <p className={`pl-3 ${open?  "" : "hidden"}`}>Result Visualization</p>
                  </div>

                </Link>
              </div>
              <div className='mt-12'>
                <Link to="/rootcauseanalysis" style={{color:"gainsboro",fontWeight:"bold"}}>
                  <div className="flex">
                    <img className='cursor-pointer sideNavIcon'  src={ChartIcon} alt="ChartIcon"/>
                    <p className={`pl-3 ${open?  "" : "hidden"}`}>Root cause analysis</p>
                  </div>

                </Link>
              </div>
            </div>
            <div className='flex flex-col items-center mb-3'>
              <img src={PepsiNavIcon} alt="PepsiNavIcon" width={50} height={50}/>
            </div>
          </div>
  )
}

export default SideNavbar