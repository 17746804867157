import React, { useEffect, useState } from 'react';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import loadingSvg from '../../images/bouncing-circles.svg';

interface RankData {
    local: number;
    global: number;
}

interface OverallAverage {
    accuracy: number;
    bias: number;
    historicalShipments: number;
    statisticalForecast: number;
}

interface ComparisonData {
    rankBasedPercent: {
        rank: number;
        data: RankData[];
    }[];
    overallAverage: {
        rank: number;
        data: OverallAverage;
    }[];
    hierarchyComparisonResult: {
        H1: number;
        H2: number;
    };
}

interface ComparisonResultPredefinedRunsProps {
    data: ComparisonData;
}

const ComparisonResultPredefinedRuns: React.FC<ComparisonResultPredefinedRunsProps> = ({ data }) => {
    const { rankBasedPercent, overallAverage, hierarchyComparisonResult } = data;
    const [h1Width, setH1Width] = useState('50');
    const [h2Width, setH2Width] = useState('50');
    const chartDataLength = useSelector((state: RootState) => state.resultVisualizationData.chartData.length);
    const loader = useSelector((state: RootState) => state.resultVisualizationData.loader);

    useEffect(() => {
        const total = hierarchyComparisonResult.H1 + hierarchyComparisonResult.H2;
        if (total > 0) {
            const h1WidthCalculated = Math.trunc((hierarchyComparisonResult.H1 / total) * 100);
            const h2WidthCalculated = Math.trunc((hierarchyComparisonResult.H2 / total) * 100);
            setH1Width(h1WidthCalculated.toString());
            setH2Width(h2WidthCalculated.toString());
        } else {
            setH1Width('50');
            setH2Width('50');
        }
    }, [hierarchyComparisonResult]);

    const getPercentage = (value: number | undefined): string => {
        return value !== undefined ? `${Math.trunc(value)}%` : '0%';
    };

    const getAccuracyBackgroundColorClass = (value: number) => {
        if (value > 78) return 'bg-red-700';
        if (value < 70) return 'bg-green-bg';
        return 'bg-yellow-500';
    };

    const getBiasBackgroundColorClass = (value: number) => {
        if (value >= -2 && value <= 2) return 'bg-green-bg';
        if ((value <= -4 && value >= -3) || (value >= 3 && value <= 4)) return 'bg-yellow-bg';
        if (value > -4 || value > 5) return 'bg-red-700';
        return 'bg-gray-300';
    };

    return (
        <div className="p-5 bg-secondary-bg mt-4 rounded">
            {loader ? (
                <div className='flex justify-center'>
                    <img src={loadingSvg} alt="loading" className="mt-10" width={40} height={40} />
                </div>
            ) : chartDataLength > 0 ? (
                <div className='mt-1'>
                    <p className='text-2xl font-medium'>Comparison Result</p>
                    <div className="w-full flex mt-5">
                        <div
                            className='bg-ternary-bg p-3 rounded-l-lg'
                            style={{ width: `${h1Width}%` }}
                        >
                            <p className='text-2xl font-bold'>{hierarchyComparisonResult.H1 ? Math.trunc(hierarchyComparisonResult.H1) : '0'}%</p>
                            <p className='text-sm font-normal text-secondary-text'>H1: UPC / KA / SR</p>
                        </div>
                        <div
                            className='bg-ternary-bg p-3 border-l border-x-gray-700 rounded-r-lg'
                            style={{ width: `${h2Width}%` }}
                        >
                            <p className='text-2xl font-bold'>{hierarchyComparisonResult.H2 ? Math.trunc(hierarchyComparisonResult.H2) : '0'}%</p>
                            <p className='text-sm font-normal text-secondary-text'>H2: L3 / KA / SR</p>
                        </div>
                    </div>

                    {rankBasedPercent.map((rankItem) => {
                        const overallItem = overallAverage.find((item) => item.rank === rankItem.rank);

                        return (
                            <div key={rankItem.rank} className='mt-8'>
                                {/* Rank-Based Percent Section */}
                                <p className='text-lg font-bold text-secondary-text'>
                                    {rankItem.rank === 1 ? 'Top Recipe' : `Rank ${rankItem.rank}`}
                                </p>
                                {rankItem.data.map((dataItem, index) => (
                                    <div key={index} className="flex flex-wrap gap-4 text-base text-secondary-text">
                                        {Object.entries(dataItem).map(([key, value]) => (
                                            <div key={key} className="capitalize">
                                                {`${key.charAt(0).toUpperCase() + key.slice(1)}: ${getPercentage(value)}`}
                                            </div>
                                        ))}
                                    </div>
                                ))}


                                {overallItem && (
                                    <div className="w-full grid grid-cols-4 gap-0 mt-5">
                                        <div className={`${getAccuracyBackgroundColorClass(overallItem.data.accuracy)} p-3 rounded-l-lg`}>
                                            <p className='text-2xl font-bold text-black'>{getPercentage(overallItem.data.accuracy)}</p>
                                            <p className='text-sm text-black font-normal'>Forecast Accuracy</p>
                                        </div>
                                        <div className={`${getBiasBackgroundColorClass(overallItem.data.bias)} p-3 border-l border-black`}>
                                            <p className='text-2xl font-bold'>{getPercentage(overallItem.data.bias)}</p>
                                            <p className='text-sm font-normal'>Bias</p>
                                        </div>
                                        <div className='bg-ternary-bg p-3 border-l border-x-gray-700'>
                                            <p className='text-2xl font-bold'>{overallItem.data.statisticalForecast || 0}</p>
                                            <p className='text-sm font-normal'>Forecast</p>
                                        </div>
                                        <div className='bg-ternary-bg p-3 rounded-r-lg border-l border-x-gray-700'>
                                            <p className='text-2xl font-bold'>{overallItem.data.historicalShipments || 0}</p>
                                            <p className='text-sm font-normal'>Historical Shipments</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}

                </div>
            ) : (
                <div className="bg-ternary-bg rounded p-2">
                    <div className="flex justify-center">
                        <p className="p-2 m-1">
                            No Data to show or no options selected, please choose from the above filters.
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ComparisonResultPredefinedRuns;
