import { configureStore } from "@reduxjs/toolkit";
import {
  forecastFilterSlice,
  rcaFilterSlice,
  resultVisualizationExpFilter,
  resultVisualizationFilter,
} from "./filterSlice";
import {
  ffchartSlice,
  ResultVisualizationExperimentation,
  ResultVisulizationExperimentBackTestingPagination,
  ResultVisulizationExperimentBackTestingTableDataSlice,
  ResultVisulizationExperimentForwardForecastDataLoadingSlice,
  ResultVisulizationExperimentForwardForecastDataSlice,
  ResultVisulizationExperimentForwardForecastPagination,
  ResultVisulizationExperimentTestingDataLoadingSlice,
} from "./resultVisualizationExperimentation";
import {
  foreCastAnalysisPagination,
  forecastDataSlice,
} from "./foreCastDataSlice";
import {
  foreCastDecompositionPagination,
  forecastDecompositionSlice,
} from "./foreCastDecomSlice";
import {
  ResultVisualizationSlice,
  resultVisulizationBackTestingTableDataSlice,
  resultVisulizationBackTestingPagination,
} from "./resultVisualizationPredefined";
import {
  foreCastBackTestingDataLoadingSlice,
  foreCastBackTestingPagination,
  foreCastBackTestingChartDataSlice,
  foreCastBackTestingAverageDataSlice,
  foreCastBackTestingTableDataSlice,
} from "./foreCastBackTestingDataSlice";
import {
  rcaExecutionPaginationSlice,
  rcaSlice,
  rcaSummaryPaginationSlice,
} from "./rcaSlice";

export const store = configureStore({
  reducer: {
    foreCastDecomp: forecastDecompositionSlice.reducer,
    forecastFilter: forecastFilterSlice.reducer,
    rcaFilter: rcaFilterSlice.reducer,
    rcaExecutionPaginationSlice: rcaExecutionPaginationSlice.reducer,
    rcaSummaryPaginationSlice: rcaSummaryPaginationSlice.reducer,
    resultVisualizationFilter: resultVisualizationFilter.reducer,
    resultVisualizationExpFilter: resultVisualizationExpFilter.reducer,
    resultVisualizationData: ResultVisualizationSlice.reducer,
    resultVisualizationExperimentationData:
      ResultVisualizationExperimentation.reducer,
    resultVisulizationBackTestingTableDataSlice:
      resultVisulizationBackTestingTableDataSlice.reducer,
    resultVisulizationExperimentForwardForecastDataSlice:
      ResultVisulizationExperimentForwardForecastDataSlice.reducer,
    resultVisulizationForewardForecastPagination:
      ResultVisulizationExperimentForwardForecastPagination.reducer,
    resultVisulizationBackTestingPagination:
      resultVisulizationBackTestingPagination.reducer,
    resultVisulizationExperimentBackTestingTableDataSlice:
      ResultVisulizationExperimentBackTestingTableDataSlice.reducer,
    resultVisulizationExperimentBackTestingPagination:
      ResultVisulizationExperimentBackTestingPagination.reducer,
    resultVisulizationExperimentTestingDataLoadingSlice:
      ResultVisulizationExperimentTestingDataLoadingSlice.reducer,
    resultVisulizationExperimentForwardForecastDataLoadingSlice:
      ResultVisulizationExperimentForwardForecastDataLoadingSlice.reducer,
    forecastChartData: forecastDataSlice.reducer,
    forecastAnalysisPaginationData: foreCastAnalysisPagination.reducer,
    foreCastBackTestingTableDataSlice:
      foreCastBackTestingTableDataSlice.reducer,
    foreCastBackTestingChartDataSlice:
      foreCastBackTestingChartDataSlice.reducer,
    foreCastBackTestingAverageDataSlice:
      foreCastBackTestingAverageDataSlice.reducer,
    foreCastBackTestingDataLoading: foreCastBackTestingDataLoadingSlice.reducer,
    foreCastBackTestingPagination: foreCastBackTestingPagination.reducer,
    foreCastDecompositionPaginationData:
      foreCastDecompositionPagination.reducer,
    rcaData: rcaSlice.reducer,
    ffChart: ffchartSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
