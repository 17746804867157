import { Box, Modal } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BacktestingResultTable from "../BacktestingResultTable/BacktestingResultTable";
import { DataShownDialog } from "../../Datashow";
import closeButton from "../../../images/closeButton.svg";
import enlargeButton from "../../../images/enlargeButton.svg";
import { RootState } from "../../../store/store";
import loadingSvg from "../../../images/bouncing-circles.svg";
import settingsIcon from "../../../images/Settings.svg";

import {
  updateExperinemtBackTestingTotalPages,
  updateExperinemtBackTestingPageNumber,
  resultVisulizationExperimentBackTestingTableDataSetter,
} from "../../../store/resultVisualizationExperimentation";
import { Pagination } from "../../Pagination";
import {
  calculateTotalPages,
  resultVisulizationExperimentDefaultGroupBy,
  mapperForGroupByLableToKey,
  resultVisualisationChartExperimentaionTableApi,
} from "../../../api/forecastPageApi";
import { useOktaAuth } from "@okta/okta-react";
import { PAGE_LIMIT } from "../../../utils/constant";
import { BacktestingResultsProps } from "../../../store/foreCastBackTestingDataSlice";
import { useNavigate } from "react-router-dom";
import LagDropdown from "./LagDropDown";
import LineColumnChartExp from "../../chart/LineColumn/LineColumnChartExp";
import RankDropdownForward from "./RankDropDown";

type DataPoint = {
  accuracy: number;
  bias: number;
  statisticalForecast: number;
  historicalShipment: number;
  timeFrameDate: string;
  periodWeekNumber: string;
  experimentId: string;
  rank: number;
};

function ResultVisulizationExperimentBacktestingResults({
  isResultVisulizationPage,
}: BacktestingResultsProps) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [expChartData, setChartDataExp] = useState<any>([]);
  const [expChartDataLabels, setChartDataExpLabels] = useState<any>([]);
  const { authState } = useOktaAuth();
  const [selectedDataShownOptionList, setSelectedDataShownOptionList] =
    useState<string[]>(resultVisulizationExperimentDefaultGroupBy);
  const [selectedDataShownCategoryList, setSelectedDataShownCategoryList] =
    useState<string[]>([]);

  const expData = useSelector(
    (state: RootState) => state.resultVisualizationExperimentationData
  );
  const resultVisulizationExperimentBackTestingTableDataSlice = useSelector(
    (state: RootState) =>
      state.resultVisulizationExperimentBackTestingTableDataSlice
  );

  const resultVisulizationExperimentTestingDataLoadingSlice = useSelector(
    (state: RootState) =>
      state.resultVisulizationExperimentTestingDataLoadingSlice
  );

  const resultVisualizationExperimentationData = useSelector(
    (state: RootState) => state.resultVisualizationExperimentationData
  );

  const loader = useSelector(
    (state: RootState) => state.resultVisualizationData
  );

  const [openSettings, setOpenSettings] = useState(false);

  const filter = useSelector((state: RootState) => state.forecastFilter);

  const resultVisulizationBackTestingPaginationDate = useSelector(
    (state: RootState) =>
      state.resultVisulizationExperimentBackTestingPagination
  );

  const buildLabelsExp = (response: any[]) => {
    // Create a map of periodWeekNumber to timeFrameDate for sorting
    const labelMap = new Map(
      response.map((item: any) => [
        item?.periodWeekNumber,
        new Date(item?.timeFrameDate),
      ])
    );

    const labels = Array.from(
      new Set(response.map((item: any) => item?.periodWeekNumber))
    );

    return labels.sort((a, b) => {
      const dateA = labelMap.get(a) || new Date(0); // Default to epoch if missing
      const dateB = labelMap.get(b) || new Date(0);

      return dateA.getTime() - dateB.getTime(); // Sort by timeFrameDate ascending
    });
  };

  useEffect(() => {
    function extractExpChartData(data: DataPoint[]) {
      return [
        {
          name: "Forecast Accuracy Production",
          data: data
            ?.filter((d) => d.experimentId === "ProductionRun")
            .map((d) => d.accuracy),
          color: "#6E3BB2",
          type: "bar",
          yAxisIndex: 0
        },
        {
          name: "Statistical Forecast Production",
          data: data
            ?.filter((d) => d.experimentId === "ProductionRun")
            .map((d) => d.statisticalForecast),
          color: "#B992EB",
          type: "line",
          yAxisIndex: 1
        },
        {
          name: "Historical Shipment (Adjusted)",
          data: data
            ?.filter((d) => d.experimentId === "ProductionRun")
            .map((d) => d.historicalShipment)
            .filter((shipment) => shipment !== 0),
          color: "#FFA800",
          type: "line",
        },
        {
          name: "Statistical Forecast Rank 1",
          data: data
            ?.filter((d) => d.experimentId !== "ProductionRun" && d.rank === 1)
            .map((d) => d.statisticalForecast),
          color: "#33ABDA",
          type: "line",
          yAxisIndex: 1
        },
        {
          name: "Statistical Forecast Rank 2",
          data: data
            ?.filter((d) => d.experimentId !== "ProductionRun" && d.rank === 2)
            .map((d) => d.statisticalForecast),
          color: "#B05788",
          type: "line",
          yAxisIndex: 1
        },
        {
          name: "Statistical Forecast Rank 3",
          data: data
            ?.filter((d) => d.experimentId !== "ProductionRun" && d.rank === 3)
            .map((d) => d.statisticalForecast),
          color: "#1078A7",
          type: "line",
          yAxisIndex: 1
        },
        {
          name: "Forecast Accuracy Rank 1",
          data: data
            ?.filter((d) => d.experimentId !== "ProductionRun" && d.rank === 1)
            .map((d) => d.accuracy),
          color: "#33ABDA",
          type: "bar",
          yAxisIndex: 0
        },
        {
          name: "Forecast Accuracy Rank 2",
          data: data
            ?.filter((d) => d.experimentId !== "ProductionRun" && d.rank === 2)
            .map((d) => d.accuracy),
          color: "#B05788",
          type: "bar",
          yAxisIndex: 0
        },
        {
          name: "Forecast Accuracy Rank 3",
          data: data
            ?.filter((d) => d.experimentId !== "ProductionRun" && d.rank === 3)
            .map((d) => d.accuracy),
          color: "#B05760",
          type: "bar",
          yAxisIndex: 0
        },
      ];
    }

    setChartDataExp(extractExpChartData(expData?.chartData));
    setChartDataExpLabels(buildLabelsExp(expData?.chartData));
  }, [expData?.chartData]);
  const renderResultVisualizationChartHtml = (): ReactElement => {
    return expChartDataLabels?.length < 0 ? (
      <div className="flex justify-center">
        <img
          src={loadingSvg}
          alt="loading"
          className="mt-10"
          width={40}
          height={40}
        />
      </div>
    ) : expChartData[0]?.data?.length > 0 ? (
      <LineColumnChartExp
        seriesData={expChartData}
        columnWidth={"4"}
        labels={expChartDataLabels}
      />
    ) : (
      <></>
    );
  };

  const handleSettingModal = () => {
    mapperForGroupByLableToKeyHandler(selectedDataShownOptionList);
    setOpenSettings(true);
  };

  const renderBackTestingAnalyticsHtml = () => {
    return (
      <>
        {/* {resultVisualizationData?.isAverageDataLoading ? (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        ) : ( */}
        {/* <div className="mb-7">
            <BackTestingAnalytics
              overallAverage={resultVisualizationData?.overallAverage}
              overallPercent={resultVisualizationData?.overallPercent}
            />
          </div> */}
        {/* )} */}
      </>
    );
  };

  const renderBackTestingTableHtml = (): ReactElement => {
    return (
      <>
        {resultVisulizationExperimentBackTestingTableDataSlice?.isTableDataLoading ? (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        ) : (
          <BacktestingResultTable
            tableHeader={
              resultVisulizationExperimentBackTestingTableDataSlice?.tableHeader
            }
            chartData={[]}
            backTestingTableData={
              resultVisulizationExperimentBackTestingTableDataSlice?.backTestingTableData
            }
            isResultVisulizationPage={isResultVisulizationPage}
            resultVisulizationTableData={
              resultVisualizationExperimentationData.chartData
            }
            isExperimentationPage={true}
          />
        )}
      </>
    );
  };

  const mapperForGroupByLableToKeyHandler = (
    selectedItemList: string[] = []
  ) => {
    const { selectedGroupByOptions, selectedCategoryType } =
      mapperForGroupByLableToKey(selectedItemList);
    setSelectedDataShownOptionList(selectedItemList);
    setSelectedDataShownCategoryList(selectedCategoryType);

    return selectedGroupByOptions;
  };

  const updateBackTestingTableData = (selectedOptionList: string[]) => {
    const payloadFilters = {
      shortTermMidTermCode: filter?.term.toString(),
      snapshotDate:
        filter?.term.toString() === "ST"
          ? filter?.shortSnapshotDate
          : filter?.midSnapshotDate,
      brandName: filter?.brand,
      subBrandName: filter?.subBrand,
      productLineName: filter?.productLine,
      productGroupName: filter?.productGroup,
      packageSizeName: filter?.size,
      productCategoryName: filter?.category,
      portfolioGroupName: filter?.mg4PortfolioGroup,
      packageName: filter?.container,
      caseCountValue: filter?.count,
      customerGlobalName: filter?.global,
      customerSectorNumber: filter?.customerSector,
      customerRegionName: filter?.customerRegion,
      customerGroupName: filter?.customerGroup,
      customerBusinessUnitName: filter?.businessUnit,
      customerMarketUnitName: filter?.marketUnit,
      ibpDemandGroup: filter?.ibpDemandGroup,
      customerTradeSegmentCode: filter?.cg1TrageSegment,
      salesSectorName: filter?.salesSector,
      salesOrganizationCode: filter?.businessOrg,
      salesDivisionName: filter?.division,
      forecastEngineClusterId: filter?.clusters,
    };

    const forecastBackTestingApiRequestBody = {
      ...payloadFilters,
      pageNumber: 1,
      pageSize: PAGE_LIMIT,
      groupBy: mapperForGroupByLableToKeyHandler(selectedOptionList),
      rank: filter?.rankBackTestingChart,
    };

    setOpenSettings(false);
    dispatch(
      resultVisulizationExperimentBackTestingTableDataSetter({
        tableHeader: [],
        backTestingTableData: [],
        isTableDataLoading: true,
      })
    );

    resultVisualisationChartExperimentaionTableApi(
      forecastBackTestingApiRequestBody,
      authState
    )
      .then((response) => {
        const totalBackTestingPages = calculateTotalPages(
          PAGE_LIMIT,
          response?.totalCount
        );
        dispatch(
          updateExperinemtBackTestingTotalPages({
            totalPages: totalBackTestingPages,
            pageLimit: resultVisulizationBackTestingPaginationDate.pageLimit,
            pageNumber: resultVisulizationBackTestingPaginationDate.pageNumber,
            isDataShownClicked:
              resultVisulizationBackTestingPaginationDate.isDataShownClicked,
          })
        );
        dispatch(
          resultVisulizationExperimentBackTestingTableDataSetter({
            tableHeader: response?.dynamicTableHeader,
            backTestingTableData: response?.dfuBasedGroupedData,
            isTableDataLoading: false,
          })
        );
      })
      .catch((error) => {
        console.log("forecastBackTestingTableApi error", error);
      });
  };

  const onModalClosed = () => {
    setOpenSettings(false);
  };

  const renderBackTestingDataShownModalHtml = (): ReactElement => {
    return (
      <>
        <DataShownDialog
          open={openSettings}
          defaultSelectedOptions={selectedDataShownOptionList}
          categoryList={selectedDataShownCategoryList}
          onClose={(itemList) => updateBackTestingTableData(itemList)}
          onModalClosed={onModalClosed}
        />
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute" as const,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "#272727",
              boxShadow: 24,
              borderRadius: 2,
            }}
          >
            <div className="m-4">
              <div className="flex justify-between p-4">
                <h6 className="font-bold text-white">Backtesting Results</h6>
                <button onClick={handleClose}>
                  <img src={closeButton} alt="close" />
                </button>
              </div>
              <div className="bg-ternary-bg rounded m-4">
                {expChartData?.length > 0 && (
                  <>
                    <LineColumnChartExp
                      seriesData={expChartData}
                      columnWidth={"4"}
                      labels={expChartDataLabels}
                    />
                    <BacktestingResultTable
                      tableHeader={
                        resultVisulizationExperimentBackTestingTableDataSlice?.tableHeader
                      }
                      chartData={[]}
                      backTestingTableData={
                        resultVisulizationExperimentBackTestingTableDataSlice?.backTestingTableData
                      }
                      isResultVisulizationPage={isResultVisulizationPage}
                      resultVisulizationTableData={
                        resultVisualizationExperimentationData.chartData
                      }
                      isExperimentationPage={true}
                    />
                  </>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </>
    );
  };

  const renderBackTestingHtml = (): ReactElement => {
    const isDataEmpty =
      !resultVisulizationExperimentTestingDataLoadingSlice?.isDataLoading &&
      resultVisulizationExperimentBackTestingTableDataSlice
        ?.backTestingTableData?.length === 0 &&
      resultVisualizationExperimentationData?.chartData?.length === 0;

    return isDataEmpty ? (
      loader ? (
        <div className="bg-ternary-bg rounded p-2">
          <div className="flex justify-center">
            <p className="p-2 m-1">
              No Data to show or no options selected, please choose from the
              above filters.
            </p>
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <img
            src={loadingSvg}
            alt="loading"
            className="mt-10"
            width={40}
            height={40}
          />
        </div>
      )
    ) : (
      <>
        {renderBackTestingAnalyticsHtml()}
        <div className="bg-ternary-bg rounded">
          {renderResultVisualizationChartHtml()}
          {renderBackTestingTableHtml()}
        </div>
        {renderBackTestingDataShownModalHtml()}
      </>
    );
  };

  const handlePageNumberUpdateEvent = (currentPageNumber: number) => {
    dispatch(
      updateExperinemtBackTestingPageNumber({
        pageLimit: resultVisulizationBackTestingPaginationDate?.pageLimit,
        pageNumber: currentPageNumber,
        totalPages: resultVisulizationBackTestingPaginationDate?.totalPages,
        isDataShownClicked: false,
        groupBySelectedField: selectedDataShownOptionList,
      })
    );
  };

  return (
    <div className="p-5 bg-secondary-bg mt-4 rounded">
      <div className="flex justify-between">
        <div className="flex mb-2">
          <h6 className="text-lg font-bold mb-4 mt-2 pt-2">
            Backtesting Results
          </h6>
          <LagDropdown />
          <RankDropdownForward rankType="rankBackTestingChart" />
        </div>
        {!resultVisulizationExperimentTestingDataLoadingSlice?.isDataLoading ||
        resultVisulizationExperimentBackTestingTableDataSlice
          ?.backTestingTableData?.length > 0 ? (
          <div>
            <button onClick={handleSettingModal}>
              <img
                className="mr-5"
                width={20}
                height={20}
                src={settingsIcon}
                alt="settingsIcon"
              />
            </button>
            <button onClick={handleOpen}>
              <img src={enlargeButton} alt="zoom" />
            </button>
          </div>
        ) : null}
      </div>
      <>
        {renderBackTestingHtml()}
        <div className="flex justify-center">
          {resultVisulizationExperimentBackTestingTableDataSlice
            ?.backTestingTableData?.length > 0 && (
            <div className="pt-2">
              <Pagination
                totalPages={
                  resultVisulizationBackTestingPaginationDate?.totalPages
                }
                activePageNumber={
                  resultVisulizationBackTestingPaginationDate?.pageNumber
                }
                updateBackTestingPageNumber={(data) =>
                  handlePageNumberUpdateEvent(data)
                }
              />
            </div>
          )}
        </div>
      </>
    </div>
  );
}

export default ResultVisulizationExperimentBacktestingResults;
