import { ChangeEvent, useEffect, useState } from "react";
import Input from "@mui/material/Input";

type InputTypeProps = {
  id?: string;
  value: string;
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (Event: ChangeEvent<HTMLInputElement>) => void;
};

const InputType = (props: InputTypeProps) => {
  const [inputFieldValue, setInputFieldValue] = useState(props?.value);

  useEffect(() => {
    setInputFieldValue(props?.value);
  }, [props?.value]);

  return (
    <Input
      value={inputFieldValue || props?.defaultValue}
      placeholder={props?.placeholder}
      onChange={props?.onChange}
      className={props?.className} 
      sx={{
        borderRadius: "4px",
        borderColor: 'none',
        border: "1px solid  #616161",
        textDecoration: "none",
        background: '#272727',
        color: 'white',
        width: '200px',
        ":hover" : {
            textDecoration: 'none'
        },
      }}
    />
  );
};

export default InputType;
