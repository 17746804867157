import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Metrics = () => {
  const rcaData = useSelector((state: RootState) => state.rcaData);

  const isAllZero =
    rcaData?.accuracy === 0 &&
    rcaData?.bias === 0 &&
    rcaData?.dpForecastAccuracy === 0 &&
    rcaData?.dpForecastBias === 0 &&
    rcaData?.dpForecastValueAdd === 0;

  return (
    <div className="mt-4">
      {isAllZero ? (
        <div className="bg-ternary-bg rounded p-2">
          <div className="flex justify-center">
            <p className="p-2 m-1">
              No Data to show or no options selected, please choose from the
              above filters.
            </p>
          </div>
        </div>
      ) : (
        <div className="bg-ternary-bg text-white p-6 pb-10 rounded-lg shadow-lg">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Metrics</h2>
          </div>

          <div className="flex justify-around">
            <div className="text-center pb-2">
              <p className="text-4xl font-bold">{rcaData.accuracy}%</p>
              <p className="uppercase text-sm tracking-wider">
                PFE Forecast Accuracy
              </p>
            </div>

            <div className="border-l border-gray-700"></div>

            <div className="text-center pb-2">
              <p className="text-4xl font-bold">{rcaData.bias}%</p>
              <p className="uppercase text-sm tracking-wider">
                PFE Forecast Bias
              </p>
            </div>

            <div className="border-l border-gray-700"></div>

            <div className="text-center pb-2">
              <p className="text-4xl font-bold">
                {rcaData.dpForecastAccuracy}%
              </p>
              <p className="uppercase text-sm tracking-wider">
                DP Forecast Accuracy
              </p>
            </div>

            <div className="border-l border-gray-700"></div>

            <div className="text-center pb-2">
              <p className="text-4xl font-bold">{rcaData.dpForecastBias}%</p>
              <p className="uppercase text-sm tracking-wider">
                DP Forecast Bias
              </p>
            </div>

            <div className="border-l border-gray-700"></div>

            <div className="text-center pb-2">
              <p className="text-4xl font-bold">
                {rcaData.dpForecastValueAdd > 0
                  ? `+${rcaData.dpForecastValueAdd}`
                  : rcaData.dpForecastValueAdd}
              </p>
              <p className="uppercase text-sm tracking-wider">
                DP Forecast Value Add
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Metrics;
