import { configureStore } from "@reduxjs/toolkit";
import {
  forecastFilterSlice,
  rcaFilterSlice,
  resultVisualizationFilter,
} from "./filterSlice";
import { foreCastAnalysisPagination, forecastDataSlice } from "./foreCastDataSlice";
import { foreCastDecompositionPagination, forecastDecompositionSlice } from "./foreCastDecomSlice";
import { ResultVisualizationSlice, resultVisulizationBackTestingTableDataSlice, resultVisulizationBackTestingPagination } from "./resultVisualizationBackTesting";
import {
  foreCastBackTestingDataLoadingSlice,
  foreCastBackTestingPagination,
  foreCastBackTestingChartDataSlice,
  foreCastBackTestingAverageDataSlice,
  foreCastBackTestingTableDataSlice,
} from "./foreCastBackTestingDataSlice";
export const store = configureStore({
  reducer: {
    foreCastDecomp: forecastDecompositionSlice.reducer,
    forecastFilter: forecastFilterSlice.reducer,
    rcaFilter: rcaFilterSlice.reducer,
    resultVisualizationFilter: resultVisualizationFilter.reducer,
    resultVisualizationData: ResultVisualizationSlice.reducer,
    resultVisulizationBackTestingTableDataSlice: resultVisulizationBackTestingTableDataSlice
    .reducer,
    resultVisulizationTestingPagination: resultVisulizationBackTestingPagination.reducer,
    resultVisulizationBackTestingPagination: resultVisulizationBackTestingPagination.reducer,
    forecastChartData: forecastDataSlice.reducer,
    forecastAnalysisPaginationData:foreCastAnalysisPagination.reducer,
    foreCastBackTestingTableDataSlice: foreCastBackTestingTableDataSlice.reducer,
    foreCastBackTestingChartDataSlice: foreCastBackTestingChartDataSlice.reducer,
    foreCastBackTestingAverageDataSlice: foreCastBackTestingAverageDataSlice.reducer,
    foreCastBackTestingDataLoading: foreCastBackTestingDataLoadingSlice.reducer,
    foreCastBackTestingPagination: foreCastBackTestingPagination.reducer,
    foreCastDecompositionPaginationData: foreCastDecompositionPagination.reducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
