import BacktestingResults from "../StatisticalForecastAnalysis/BacktestingResults"
import ForecastAnalysis from "../StatisticalForecastAnalysis/ForecastAnalysis"
import ComparisonResultExperimentation from "./ComparisonResultExperimentation"
import ResultVisualizationExperimentalFilter from "../../components/ResultVisualizationFilter/ResultVisualizationExperimentalFilter"
import TimeCardComponent from "../StatisticalForecastAnalysis/Timecard/TimeCardComponent"

const Experimentation = () => {

  return (
    <>
      <div className="flex space-x-3 mt-5">
        <div style={{ width: '500px' }}>
          <TimeCardComponent lag={false} />
        </div>
        <div className="flex-auto">
          <TimeCardComponent lag={false}/>
        </div>
      </div>
      <ResultVisualizationExperimentalFilter />
      <ComparisonResultExperimentation />
      <div className="mt-10">
        <BacktestingResults widthColumn="12%" isResultVisulizationPage={true} />
      </div>
      <div className="mt-10">
        <ForecastAnalysis />
      </div>
    </>
  );
};

export default Experimentation;
