import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    useUserApiData,
} from "../../api/userApi";
import { useOktaAuth } from "@okta/okta-react";
import { setCurrentSectorFilter, setUserData } from "../../store/userSlice";

export const Sector = () => {
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedMarket, setSelectedMarket] = useState('');
    const [defaultMarket, setDefaultMarket] = useState('');
    const [postData, setPostData] = useState({});
    const [sector, setSector] = useState<string[]>([]);
    const [market, setMarket] = useState<string[]>([]);

    const dispatch = useDispatch();
    const { authState } = useOktaAuth();

    const { data: userApiData, isLoading: userApiDataLoading } =
        useUserApiData(authState?.accessToken?.accessToken!, "userdata", postData);

    useEffect(() => {
        if (!userApiData) return;

        dispatch(setUserData(userApiData));

        const { filterData, userSectorData } = userApiData;
        setSelectedSector(userSectorData?.defaultSector || '');
        setSector(userSectorData?.sectors || []);
        setMarket(userSectorData?.markets || []);
        setDefaultMarket(userSectorData?.defaultMarket || '');

        if (filterData) {
            localStorage.setItem(`userData-${selectedSector ? selectedSector : userSectorData?.defaultSector}`, JSON.stringify(userApiData));
        }

        // Dispatch default sector's filterData
        const sectorData = userApiData?.userSectorData?.defaultSector;
        const marketData = userApiData?.userSectorData?.defaultMarket;
        if (sectorData && marketData) {
            const currentData = { sector: sectorData, market: marketData };
            dispatch(setCurrentSectorFilter(currentData));
        }
    }, [userApiData]);

    const sectorChange = async (sector: string) => {
        setSelectedSector(sector);
        setPostData({ sector, market: defaultMarket });

        // Get sector filterData from localStorage
        const stored = selectedSector ? localStorage.getItem(`userData-${selectedSector}`) : null;
        if (stored) {
            const currntData = { sector: sector, market: selectedMarket ? selectedMarket : defaultMarket };
            dispatch(setCurrentSectorFilter(currntData));
        }
    };

    const marketChange = async (market: string) => {
        setSelectedMarket(market);
        setPostData({ sector: selectedSector, market: market });
    };

    return (
        <div className="flex items-center space-x-2">
            <span className="font-medium">Sector:</span>
            <select
                className="px-2 py-1 text-sm bg-[#131313]"
                value={selectedSector}
                onChange={(e) => sectorChange(e.target.value)}
            >
                {sector?.map((data: string) => (
                    <option key={data} value={data}>
                        {data}
                    </option>
                ))}
            </select>
            <span className="ml-4">Market: </span>
            <select
                className="px-2 py-1 text-sm bg-[#131313]"
                value={selectedMarket}
                onChange={(e) => marketChange(e.target.value)}
            >
                {market?.map((data: string) => (
                    <option key={data} value={data}>
                        {data}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Sector;