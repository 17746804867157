import { useEffect, useRef, useState } from "react";
import HorizontalNavbar from "./components/HorizontalNavbar/HorizontalNavbar";
import SideNavbar from "./components/SideNavbar/SideNavbar";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [divHeight, setDivHeight] = useState(0);

  const [open, setOpen] = useState(false);
  const handleSidebar = () => setOpen(!open);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0].target === divRef.current) {
        setDivHeight(entries[0].contentRect.height);
      }
    });

    if (divRef.current) {
      observer.observe(divRef.current);
      setDivHeight(divRef.current.clientHeight);
    }
  }, [children]);

  return (
    <>
      <HorizontalNavbar />
      <SideNavbar
        height={divHeight}
        open={open}
        handleSidebar={handleSidebar}
      />
      <div
        ref={divRef}
        style={{
          width: open ? "calc(95.5vw - 245px)" : "95.5vw",
          marginLeft: open ? "auto" : "63px",
        }}
      >
        {children}
      </div>
    </>
  );
};
