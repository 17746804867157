import { ReactElement } from "react";
import Button from "@mui/material/Button";
import { BUTTON_VARIANT } from "../../utils/constant";

type ButtonTypeProps = {
  id?: string;
  btnText: string;
  variantType: string;
  className?: string;
  showIcon?: boolean;
  iconElement?: ReactElement<any>;
  onClick?: (Event: React.MouseEvent<HTMLButtonElement>) => void;
};

const ButtonType = (props: ButtonTypeProps) => {

  /**
   * Render the button based on props variantType and with
   * @returns React Button Element
   */
  const renderButtonWithVairant = (): React.ReactElement => {
    if (props?.variantType === BUTTON_VARIANT?.contained) {
      return (
        <Button
          variant="contained"
          onClick={props?.onClick}
         // startIcon={props?.showIcon ? IconElement : null}
        >
          {props?.btnText}
        </Button>
      );
    }
    if (props?.variantType === BUTTON_VARIANT?.outlined) {
      return (
        <Button
          variant="outlined"
          onClick={props?.onClick}
        //  startIcon={props?.showIcon ? IconElement : null}
        >
          {props?.btnText}
        </Button>
      );
    }

    return (
      <Button
        variant="text"
        onClick={props?.onClick}
      //  startIcon={props?.showIcon ? IconElement : null}
      >
        {props?.btnText}
      </Button>
    );
  };

  return renderButtonWithVairant();
};

export default ButtonType;
