import BasicTabs from "../../components/Tabs/customTabPanel";
import RcaExecution from "./RcaExecution";
import RcaSummary from "./RcaSummary";
import {
  resetExecutionBackTesting,
  resetMetrics,
  resetSummaryBackTestingPagination,
  resetTableData,
  resetTreeChartData,
} from "../../store/rcaSlice";
import { useDispatch } from "react-redux";
import Sector from "../../baseComponent/Sector/Sector";

const RootCauseAnalysis = () => {
  const dispatch = useDispatch();

  const updateTheTabState = (updateTheTabState: number) => {
    dispatch(resetMetrics());
    dispatch(resetTableData());
    dispatch(resetTreeChartData());
    dispatch(resetExecutionBackTesting());
    dispatch(resetSummaryBackTestingPagination());
  };

  return (
    <div className="p-5">
      <div className="flex justify-between mb-7">
        <span className="text-3xl font-bold">
          Root Cause Analysis Dashboard
        </span>
        <Sector/>
      </div>
      <BasicTabs
        tabLabels={["RCA Execution", "RCA Summary"]}
        tabStyles={{ color: "white" }}
        activeTab={(activeTab: number) => updateTheTabState(activeTab)}
      >
        <RcaExecution />
        <RcaSummary />
      </BasicTabs>
    </div>
  );
};

export default RootCauseAnalysis;
