import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  setForecastFilter,
  setRcaFilter,
  setResultVisualizationFilter,
} from "../../../store/filterSlice";
import {
  createTheme,
  ThemeProvider,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

type InputTypeProps = {
  itemList?: string[];
  itemType: string;
  lag: boolean;
};

export default function SnapshotDateDropdown(
  itemsProps: Readonly<InputTypeProps>
) {
  const dispatch = useDispatch();
  const [lagValue, setLagValue] = React.useState("0");
  const filter = useSelector((state: RootState) => state.forecastFilter);

  const theme = createTheme({
    palette: {
      background: {
        paper: "#272727",
      },
      text: {
        primary: "#ffff",
      },
    },
  });

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const valuez = [event.target.value] as string[];
    const itemKey = itemsProps.itemType as keyof typeof filter;
    dispatch(setForecastFilter({ key: itemKey, value: valuez }));
    dispatch(setRcaFilter({ key: itemKey, value: valuez }));
    dispatch(setResultVisualizationFilter({ key: itemKey, value: valuez }));
  };

  const handleLagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    return null;
  };

  React.useEffect(() => {
    const itemKey = itemsProps?.itemType as keyof typeof filter;
    // if (
    //   filter[itemKey]?.length === 0 &&
    //   itemsProps.itemList &&
    //   itemsProps.itemList?.length > 0
    // ) {
    if (itemsProps.itemList) {
      dispatch(
        setForecastFilter({ key: itemKey, value: [itemsProps.itemList[0]] })
      );
      dispatch(setRcaFilter({ key: itemKey, value: [itemsProps.itemList[0]] }));
      dispatch(
        setResultVisualizationFilter({
          key: itemKey,
          value: [itemsProps.itemList[0]],
        })
      );
    }
    // }
    // else if (filter[itemKey]?.length > 0) {
    //   dispatch(
    //     setForecastFilter({
    //       key: itemKey,
    //       value: filter[itemsProps.itemType as keyof typeof filter],
    //     })
    //   );
    //   dispatch(
    //     setRcaFilter({
    //       key: itemKey,
    //       value: filter[itemsProps.itemType as keyof typeof filter],
    //     })
    //   );
    //   dispatch(
    //     setResultVisualizationFilter({
    //       key: itemKey,
    //       value: filter[itemsProps.itemType as keyof typeof filter],
    //     })
    // );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex">
      <ThemeProvider theme={theme}>
        <FormControl sx={{ width: "70%" }}>
          <Select
            value={filter[itemsProps.itemType as keyof typeof filter] || ""}
            onChange={handleChange}
            renderValue={(selected) =>
              selected?.length === 0 ? (
                <span style={{ color: "#909090" }}>Select</span>
              ) : (
                selected
              )
            }
            displayEmpty
            sx={{
              height: "38px",
              "& .MuiInputBase-root": {
                color: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#616161",
              },
              "& .MuiSvgIcon-root": {
                color: "white",
              },
              "&.MuiOutlinedInput-notchedOutline": {
                borderColor: "#616161",
              },
            }}
          >
            {itemsProps?.itemList?.map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox
                  sx={{
                    "& .MuiSvgIcon-root": {
                      color: "white",
                    },
                  }}
                  checked={
                    filter[itemsProps.itemType as keyof typeof filter].indexOf(
                      item
                    ) > -1
                  }
                />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: "30%",
            position: "relative",
            bottom: "28px",
            padding: "4px",
          }}
        >
          {itemsProps?.lag ? (
            <>
              <Typography
                variant="caption"
                sx={{ color: "#909090", fontSize: "13px" }}
              >
                Lag
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                  padding: "8px",
                  border: "1px solid #616161",
                  borderRadius: "4px",
                  color: "white",
                  backgroundColor: "#272727",
                }}
              >
                {filter?.term?.toString() === "ST" ? "4" : "6"}
              </Typography>
            </>
          ) : (
            <></>
          )}
        </FormControl>
      </ThemeProvider>
    </div>
  );
}
