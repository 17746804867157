import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LineChart, {
  ChartAnnotation,
} from "../../components/chart/ScatterLine/lineChart";
import { Box, Modal } from "@mui/material";
import enlargeButton from "../../images/enlargeButton.svg";
import closeButton from "../../images/closeButton.svg";
import AnnotationLegend from "../../components/chart/ScatterLine/annotationLegends";
import resetZoomIcon from "../../images/resetZoom.svg";
import settingsIcon from "../../images/Settings.svg";
import { DataShownDialog } from "../../components/Datashow";
import { RootState } from "../../store/store";
import { Pagination } from "../../components/Pagination";
import ForecastAnalysisTable from "../../components/ForecastAnalysis/ForecastAnalysisTable/ForecastAnalysisTable";
import { useOktaAuth } from "@okta/okta-react";
import {
  calculateTotalPages,
  forecastAnalysisDefaultGroupBy,
  forecastAnalysisTableApi,
  mapperForGroupByLableToKey,
} from "../../api/forecastPageApi";
import loadingSvg from "../../images/bouncing-circles.svg";
import {
  setDynamicTableHeader,
  setPageLoadingState,
  setTableRowData,
  updateForecastAnalysisPageNumber,
  updateForecastAnalysisTotalPages,
} from "../../store/foreCastDataSlice";
import { FIRST_PAGE, PAGE_LIMIT } from "../../utils/constant";

export interface ChartData {
  datasets: ChartSeries[];
}

interface ChartPoint {
  x: string;
  y: number;
}

interface ChartSeries {
  type: "line" | "scatter" | "bar";
  yAxisID: string;
  label: string;
  data: ChartPoint[];
  borderColor: string;
  backgroundColor: string;
  borderWidth: number;
  fill?: boolean;
  pointRadius?: number;
  pointStyle: string;
}

interface DataRow {
  clusterId: string;
  upc: string;
  keyAccount: string;
  salesRegion: string;
  ltp: {
    p5W1: number;
    p5W2: number;
    p5W3: number;
    p5W4: number;
    p6W1: number;
    p6W2: number;
    p6W3: number;
    p6W4: number;
    p7W1: number;
    p7W2: number;
    p7W3: number;
    p7W4: number;
    p8W1: number;
    p8W2: number;
    p8W3: number;
    p8W4: number;
    p9W1: number;
    p9W2: number;
    p9W3: number;
    p9W4: number;
  };
}

interface StatisticalForecast {
  p5W1: number;
  p5W2: number;
  p5W3: number;
  p5W4: number;
  p6W1: number;
  p6W2: number;
  p6W3: number;
  p6W4: number;
  p7W1: number;
  p7W2: number;
  p7W3: number;
  p7W4: number;
  p8W1: number;
  p8W2: number;
  p8W3: number;
  p8W4: number;
  p9W1: number;
  p9W2: number;
  p9W3: number;
  p9W4: number;
}
interface InputData {
  statisticalForecast: number;
  historicalShipment: number;
  promoDiscount: number;
  maxPromotionImpact: number;
  minPromotionImpact: number;
  avgPromotionImpact: number;
  timeFrameDate: string;
  periodWeekNumber: string;
}

interface OutputData {
  promoDiscount: number;
  maxDiscount: number;
  avgDiscount: number;
  minDiscount: number;
  periodWeekNumber: string;
}

const holidaySpecificColors: Record<string, string> = {
  "Super Bowl": "#FF4500",
  Thanksgiving: "#FF6347",
  "New Year's Day": "#1E90FF",
  Easter: "#FF69B4",
  "Quarter 1 Close": "#2E8B57",
  "Winter Holiday (Q4 Close)": "#8A2BE2",
  "Labor Day (Q3 Close)": "#FFD700",
  "Memorial Day": "#DC143C",
  "Quarter 2 Close": "#20B2AA",
  "Independence Day": "#FF4500",
  "Cinco De Mayo": "#32CD32",
};

export interface TableProps {
  statisticalForecast: StatisticalForecast;
  historicalForecast: DataRow[];
}

function ForecastAnalysis() {
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();
  const [open, setOpen] = useState(false);
  const [toolTipData, setToolTipData] = useState<any>([]);
  const [openSettings, setOpenSettings] = useState(false);
  const [labels, setLabels] = useState<string[]>([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const chartRef = useRef<any>(null);
  const [chartDataAsProp, setChartData] = useState<ChartSeries[]>([]);
  const [annotationAsProp, setChartAnnotation] = useState<ChartAnnotation[]>(
    []
  );
  const filter = useSelector((state: RootState) => state.forecastFilter);
  const [selectedDataShownOptionList, setSelectedDataShownOptionList] =
    useState<string[]>(forecastAnalysisDefaultGroupBy);
  const [selectedDataShownCategoryList, setSelectedDataShownCategoryList] =
    useState<string[]>([]);
  const forecastChartData = useSelector(
    (state: RootState) => state.forecastChartData
  );
  const forecastAnalysisPaginationData = useSelector(
    (state: RootState) => state.forecastAnalysisPaginationData
  );
  const isLoading = useSelector(
    (state: RootState) => state.forecastChartData.isForcastTableDataLoading
  );

  const getRandomColor = (): string => {
    const array = new Uint8Array(3);
    crypto.getRandomValues(array);
    const [r, g, b] = array;
    return `rgba(${r}, ${g}, ${b}, 1)`;
  };

  const getHolidayColor = (
    holiday: string,
    colorMap: Record<string, string>
  ): string => {
    if (holidaySpecificColors[holiday]) {
      return holidaySpecificColors[holiday];
    }

    if (!colorMap[holiday]) {
      colorMap[holiday] = getRandomColor();
    }

    return colorMap[holiday];
  };

  function extractToolTipData(data: InputData[]): OutputData[] {
    const seen = new Set();
    return data
      ?.map((item) => ({
        promoDiscount: item.promoDiscount ?? 0,
        maxDiscount: item.maxPromotionImpact ?? 0,
        avgDiscount: item.avgPromotionImpact ?? 0,
        minDiscount: item.minPromotionImpact ?? 0,
        periodWeekNumber: item.periodWeekNumber ?? "N/A",
      }))
      .filter((tooltip) => tooltip.promoDiscount !== 0) // Exclude items with promoDiscount = 0
      .filter((tooltip) => {
        const key = `${tooltip.periodWeekNumber},${tooltip.promoDiscount},${tooltip.maxDiscount},${tooltip.avgDiscount},${tooltip.minDiscount}`;
        if (seen.has(key)) {
          return false;
        }
        seen.add(key);
        return true;
      });
  }

  function getSortedUniquePeriodWeekNumbers(
    table: any[],
    chart: any[],
    holidays: any
  ): string[] {
    const uniquePeriodWeekNumbers = new Set<string>([
      ...(table?.map((item) => item?.periodWeekNumber) ?? []),
      ...(chart?.map((item) => item?.periodWeekNumber) ?? []),
      ...Object.keys(holidays ?? {}),
    ]);

    const periodWeekArray = Array.from(uniquePeriodWeekNumbers);

    return periodWeekArray;
  }

  const mapHolidaysToAnnotations = (
    holidayData: Record<string, string[]>
  ): { annotations: ChartAnnotation[] } => {
    const annotations: ChartAnnotation[] = [];
    const holidayColorMap: Record<string, string> = {};

    Object.keys(holidayData)?.forEach((key) => {
      const match = key.match(/(.+)\s(\d{4})$/);

      let period = key;
      let year = "";

      if (match) {
        period = match[1];
        year = match[2];
      }

      holidayData[key]?.forEach((holiday) => {
        const holidayColor = getHolidayColor(holiday, holidayColorMap);

        annotations.push({
          type: "line",
          borderColor: holidayColor,
          borderWidth: 2,
          value: `${period} ${year}`.trim(),
          scaleID: "x",
          label: {
            backgroundColor: holidayColor,
            display: true,
            color: "#fff",
            content: `${holiday} (${year})`.trim(),
          },
        });
      });
    });

    return { annotations };
  };

  const { annotations } = useMemo(
    () => mapHolidaysToAnnotations(forecastChartData?.holidayIndicators),
    [forecastChartData?.holidayIndicators]
  );

  const handleSettingModal = () => {
    mapperForGroupByLableToKeyHandler(selectedDataShownOptionList);
    setOpenSettings(true);
  };

  const resetZoom = useCallback(() => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    } else {
      console.error("Chart ref is not available");
    }
  }, [chartRef]);

  useEffect(() => {
    if (
      Array.isArray(forecastChartData?.chartData) &&
      forecastChartData.chartData?.length > 0
    ) {
      setChartAnnotation(annotations);
      const labelsTemp = getSortedUniquePeriodWeekNumbers(
        forecastChartData.chartData,
        forecastChartData.tableRowData,
        forecastChartData.holidayIndicators
      );
      setLabels(labelsTemp);
      setToolTipData(extractToolTipData(forecastChartData?.chartData));
      setChartData(extractChartData(forecastChartData?.chartData));
    }
  }, [forecastChartData]);

  const mapperForGroupByLableToKeyHandler = (
    selectedItemList: string[] = []
  ) => {
    const { selectedGroupByOptions, selectedCategoryType } =
      mapperForGroupByLableToKey(selectedItemList);
    setSelectedDataShownOptionList(selectedItemList);
    setSelectedDataShownCategoryList(selectedCategoryType);

    return selectedGroupByOptions;
  };

  const handleForecastAnalysisPageNumberUpdateEvent = (
    currentPageNumber: number
  ) => {
    dispatch(
      updateForecastAnalysisPageNumber({
        pageLimit: forecastAnalysisPaginationData?.pageLimit,
        pageNumber: currentPageNumber,
        totalPages: forecastAnalysisPaginationData?.totalPages,
        isDataShownClicked: false,
        groupBySelectedField: selectedDataShownOptionList,
      })
    );
  };

  const updateForecastAnalysisTableData = (selectedOptionList: string[]) => {
    const payloadFilters = {
      shortTermMidTermCode: filter?.term.toString(),
      snapshotDate:
        filter?.term.toString() === "ST"
          ? filter?.shortSnapshotDate
          : filter?.midSnapshotDate,
      brandName: filter?.brand,
      subBrandName: filter?.subBrand,
      productLineName: filter?.productLine,
      productGroupName: filter?.productGroup,
      materialGlobalTradeItemNumberDescription: filter?.planningItem,
      packageSizeName: filter?.size,
      productCategoryName: filter?.category,
      portfolioGroupName: filter?.mg4PortfolioGroup,
      packageName: filter?.count,
      packTypeName: filter.ppg,
      keyAccount: filter?.keyAccount,
      customerGlobalName: filter?.global,
      customerSectorNumber: filter?.customerSector,
      customerRegionName: filter?.customerRegion,
      customerGroupName: filter?.customerGroup,
      customerBusinessUnitName: filter?.businessUnit,
      customerMarketUnitName: filter?.marketUnit,
      ibpDemandGroup: filter?.ibpDemandGroup,
      customerTradeSegmentDescription: filter?.cg1TrageSegment,
      salesSectorName: filter?.salesSector,
      salesRegionName: filter?.salesRegion,
      salesCountryCode: filter?.country,
      salesOrganizationCode: filter?.businessOrg,
      salesDivisionName: filter?.division,
      forecastEngineClusterId: filter?.clusters,
      forecastModuleCode: filter?.ac1ac2,
      forecastEngineSegmentationId: filter?.segment,
      packageTypeName: filter?.container,
    };

    const forecastAnalysisApiRequestBody = {
      ...payloadFilters,
      pageNumber: 1,
      pageSize: PAGE_LIMIT,
      groupBy: mapperForGroupByLableToKeyHandler(selectedOptionList),
    };
    setOpenSettings(false);
    dispatch(setPageLoadingState(true));
    dispatch(setTableRowData([]));
    dispatch(setDynamicTableHeader([]));
    forecastAnalysisTableApi(forecastAnalysisApiRequestBody, authState)
      .then((response) => {
        const totalBackTestingPages = calculateTotalPages(
          PAGE_LIMIT,
          response?.totalCount
        );

        dispatch(setTableRowData(response?.dfuBasedGroupedData));
        dispatch(setDynamicTableHeader(response?.dynamicTableHeader));
        dispatch(
          updateForecastAnalysisTotalPages({
            totalPages: totalBackTestingPages,
            pageLimit: PAGE_LIMIT,
            pageNumber: FIRST_PAGE,
            isDataShownClicked: true,
          })
        );
        dispatch(setPageLoadingState(false));
      })
      .catch((error) => {
        console.log("forecastAnalysisTableApi error", error);
      });
  };

  const extractChartData = (chartData: any): ChartSeries[] => {
    const getUniquePoints = (data: ChartPoint[]): ChartPoint[] => {
      const seen = new Set();
      return data.filter((point) => {
        const key = `${point.x},${point.y}`; // Unique key based on x and y
        if (seen.has(key)) {
          return false;
        }
        seen.add(key);
        return true;
      });
    };

    const statisticalForecastData: ChartPoint[] = getUniquePoints(
      chartData
        ?.map((item: any) => ({
          x: item.periodWeekNumber,
          y: item.statisticalForecast,
        }))
        .filter((point: any) => point.y !== 0)
    );

    const historicalShipmentsData: ChartPoint[] = getUniquePoints(
      chartData
        ?.map((item: any) => ({
          x: item.periodWeekNumber,
          y: item.historicalShipment,
        }))
        .filter((point: any) => point.y !== 0)
    );

    const promoDiscountData: ChartPoint[] = getUniquePoints(
      chartData
        ?.map((item: any) => ({
          x: item.periodWeekNumber,
          y: item.promoDiscount,
        }))
        .filter((point: any) => point.y !== 0)
    );

    const previousYearData: ChartPoint[] = getUniquePoints(
      chartData
        ?.map((item: any) => ({
          x: item.periodWeekNumber,
          y: item.previousYear,
        }))
        .filter((point: any) => point.y !== 0)
    );

    return [
      {
        type: "line",
        label: "Statistical Forecast",
        data: statisticalForecastData,
        borderColor: "#B992EB",
        backgroundColor: "rgba(255, 165, 0, 0.2)",
        borderWidth: 2,
        pointStyle: "line",
        fill: false,
        yAxisID: "y",
      },
      {
        type: "line",
        label: "Historical Shipments (Adjusted)",
        data: historicalShipmentsData,
        borderColor: "#FFA800",
        backgroundColor: "rgba(185, 146, 235, 0.2)",
        borderWidth: 2,
        fill: false,
        pointStyle: "line",
        yAxisID: "y",
      },
      {
        type: "bar",
        label: "Previous Year",
        data: previousYearData,
        backgroundColor: "#A35079",
        borderColor: "#A35079",
        borderWidth: 2,
        pointRadius: 6,
        yAxisID: "y",
        pointStyle: "rect",
      },
      {
        type: "scatter",
        label: "Promo Discount",
        data: promoDiscountData,
        backgroundColor: "#DAB9C9",
        borderColor: "#DAB9C9",
        borderWidth: 2,
        pointRadius: 5,
        yAxisID: "y1",
        pointStyle: "circle",
      },
    ];
  };

  const onModalClosed = () => {
    setOpenSettings(false);
  };

  return (
    <div className="p-4 bg-secondary-bg mt-4 rounded h-full">
      <div className="flex justify-between">
        <h6 className="text-lg font-bold mb-4">Forecast Analysis</h6>
        <div>
          <button
            onClick={handleSettingModal}
            disabled={forecastChartData?.tableRowData?.length === 0}
          >
            <img
              className="mr-5"
              width={20}
              height={20}
              src={settingsIcon}
              alt="settingsIcon"
            />
          </button>
          <button
            onClick={handleOpen}
            disabled={forecastChartData?.tableRowData?.length === 0}
          >
            <img src={enlargeButton} alt="zoom" />
          </button>
        </div>
      </div>
      <div className="bg-ternary-bg rounded p-2">
        {forecastChartData?.chartData?.length === 0 ? (
          isLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="flex justify-center">
              <p className="p-2 m-1">
                No Data to show or no options selected, please choose from the
                above filters.
              </p>
            </div>
          )
        ) : (
          <>
            <div className="flex">
              <LineChart
                chartRef={chartRef}
                chartData={{ datasets: chartDataAsProp }}
                chartAnnotations={annotationAsProp}
                labels={labels}
                customTooltipData={toolTipData}
              />
              <div className="overscroll-contain h-06">
                <AnnotationLegend
                  legendItems={annotationAsProp}
                  labelOrder={labels}
                />
              </div>
              <div className="pt-2">
                <button onClick={resetZoom}>
                  <img src={resetZoomIcon} alt="reset zoom" />
                </button>
              </div>
            </div>
            {forecastChartData?.isForcastTableDataLoading ? (
              <div className="flex justify-center">
                <img
                  src={loadingSvg}
                  alt="loading"
                  className="mt-10"
                  width={40}
                  height={40}
                />
              </div>
            ) : (
              <ForecastAnalysisTable
                tableHeaders={forecastChartData.chartData}
                dynamicTableHeader={forecastChartData?.dynamicTableHeader}
                forecastAnalysisData={{
                  tableRowData: forecastChartData?.tableRowData,
                }}
              />
            )}
            {forecastChartData?.tableRowData?.length > 0 && (
              <div className="flex justify-center p-4 mt-2">
                <Pagination
                  totalPages={forecastAnalysisPaginationData?.totalPages}
                  activePageNumber={forecastAnalysisPaginationData?.pageNumber}
                  updateBackTestingPageNumber={(data) =>
                    handleForecastAnalysisPageNumberUpdateEvent(data)
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
      <DataShownDialog
        open={openSettings}
        defaultSelectedOptions={selectedDataShownOptionList}
        categoryList={selectedDataShownCategoryList}
        onClose={(itemList) => updateForecastAnalysisTableData(itemList)}
        onModalClosed={onModalClosed}
      />
      {forecastChartData?.tableRowData?.length < 0 ? (
        <></>
      ) : (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute" as const,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "#272727",
              boxShadow: 24,
              borderRadius: 2,
            }}
          >
            <div className="m-4">
              <div className="flex justify-between">
                <h6 className="font-bold text-white">Forecast Analysis</h6>
                <button onClick={handleClose}>
                  <img src={closeButton} alt="close" />
                </button>
              </div>

              <div className="bg-ternary-bg rounded m-4">
                <div className="flex">
                  <LineChart
                    chartRef={chartRef}
                    chartData={{ datasets: chartDataAsProp }}
                    chartAnnotations={annotationAsProp}
                    labels={labels}
                    customTooltipData={toolTipData}
                  />
                  <AnnotationLegend
                    legendItems={annotationAsProp}
                    labelOrder={labels}
                  />
                  <div>
                    <button onClick={resetZoom} className="pt-2">
                      <img src={resetZoomIcon} alt="reset zoom" />
                    </button>
                  </div>
                </div>
                <ForecastAnalysisTable
                  tableHeaders={forecastChartData.chartData}
                  dynamicTableHeader={forecastChartData?.dynamicTableHeader}
                  forecastAnalysisData={{
                    tableRowData: forecastChartData?.tableRowData,
                  }}
                />
                <div className="overflow-hidden"></div>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default ForecastAnalysis;
