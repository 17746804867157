import React, { useState } from 'react';
import sortUp from '../../images/sortUp.svg'
import settingsIcon from '../../images/Settings.svg';
import { Checkbox, FormControl, MenuItem, Select, Typography, createTheme, ThemeProvider } from '@mui/material';
import './RcaTable.css';
import { Pagination } from '../../components/Pagination';
const uniqueKey = require('unique-key');

type DataRow = {
    cluster: string;
    upc: string;
    account: string;
    region: string;
    rca: string;
    pfeAccuracy: number;
    pfeBias: number;
    forecast: number;
    error: number;
    dpAccuracy: number;
};

const data: DataRow[] = [
    {
        cluster: "High Demand #AZ",
        upc: "12 Pack 16.9 Oz",
        account: "Kroger",
        region: "North",
        rca: "1.1.1 Uncaptured Trend",
        pfeAccuracy: 88,
        pfeBias: 3,
        forecast: 1000,
        error: 600,
        dpAccuracy: 88,
    },
    {
        cluster: "High Demand #AZ",
        upc: "12 Pack 16.9 Oz",
        account: "Kroger",
        region: "South",
        rca: "1.1.2 Uncaptured Seasonality",
        pfeAccuracy: 72,
        pfeBias: 3,
        forecast: 1000,
        error: 620,
        dpAccuracy: 72,
    },
];

for (let i = 2; i < 50; i++) {
    data.push({
        cluster: `High Demand #AZ`,
        upc: `12 Pack 16.9 Oz`,
        account: `Kroger`,
        region: `North`,
        rca: `1.${i}.1 Random Issue`,
        pfeAccuracy: Math.floor(Math.random() * 100),
        pfeBias: 3,
        forecast: 1000,
        error: Math.floor(600 + Math.random() * 100),
        dpAccuracy: Math.floor(Math.random() * 100),
    });
}

type SortKey = keyof DataRow;

const sortData = (key: SortKey, ascending: boolean) => {
    return [...data].sort((a, b) => {
        if (ascending) {
            return a[key] > b[key] ? 1 : -1;
        } else {
            return a[key] < b[key] ? 1 : -1;
        }
    });
};

const RCATable = () => {
    const [sortKey, setSortKey] = useState<SortKey | null>(null);
    const [ascending, setAscending] = useState<boolean>(true);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [selectValue, setSelectValue] = useState<string>("Select");

    const theme = createTheme({
        palette: {
            background: {
                paper: '#272727',
            },
            text: {
                primary: '#ffff',
            },
        },
    });

    const handleSelectChange = (event: any) => {
        setSelectValue(event.target.value);
    };



    const handleSort = (key: SortKey) => {
        setAscending(sortKey === key ? !ascending : true);
        setSortKey(key);
    };

    const sortedData = sortKey ? sortData(sortKey, ascending) : data;

    const getColor = (value: number) => {
        if (value >= 85) return "bg-green-600 text-white";
        if (value >= 70) return "bg-yellow-500 text-white";
        return "bg-red-700 text-white";
    };

    const renderSortIcon = (key: SortKey) => {
        if (sortKey === key) {
            return ascending ? (
                <img src={sortUp} alt="sortUp" className="ml-2 w-3 h-3" />
            ) : (
                <img src={sortUp} alt="sortDown" className="ml-2 w-3 h-3" />
            );
        } else {
            return <img src={sortUp} alt="sortDown" className="ml-2 w-3 h-3" />;
        }
    };

    const handleCheckboxChange = (index: number) => {
        if (selectedRows.includes(index)) {
            setSelectedRows(selectedRows.filter((row) => row !== index));
        } else {
            setSelectedRows([...selectedRows, index]);
        }
    };

    return (
        <div className="mt-4">
            <div className="bg-ternary-bg text-white p-6 pb-10 rounded-lg shadow-lg">
                <div className='pb-4 flex justify-between items-center'>
                    <span className="text-lg font-bold">Title</span>

                    <div className="flex space-x-2">
                        <div className='pt-3 mr-3'>
                            <Typography variant='h6'>Add</Typography>
                        </div>
                        <ThemeProvider theme={theme}>
                            <FormControl
                                fullWidth
                                sx={{
                                    minWidth: '195px',
                                    backgroundColor: '#313131',
                                    borderRadius: '100px',
                                }}
                            >
                                <Select
                                    value={selectValue}
                                    onChange={handleSelectChange}
                                    sx={{ color: '#fff', '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                >
                                    <MenuItem value="Select">RCA Stat Override</MenuItem>
                                    <MenuItem value="Option1">Option 1</MenuItem>
                                    <MenuItem value="Option2">Option 2</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl
                                fullWidth
                                sx={{
                                    minWidth: '125px',
                                    backgroundColor: '#313131',
                                    borderRadius: '100px',
                                }}
                            >
                                <Select
                                    value={selectValue}
                                    onChange={handleSelectChange}
                                    sx={{ color: '#fff', '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                >
                                    <MenuItem value="Select">RCA Total</MenuItem>
                                    <MenuItem value="Total1">Total 1</MenuItem>
                                    <MenuItem value="Total2">Total 2</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl
                                fullWidth
                                sx={{
                                    minWidth: '80px',
                                    backgroundColor: '#313131',
                                    borderRadius: '100px',
                                }}
                            >
                                <Select
                                    value={selectValue}
                                    onChange={handleSelectChange}
                                    sx={{ color: '#fff', '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                >
                                    <MenuItem value="Select">Tag</MenuItem>
                                    <MenuItem value="Tag1">Tag 1</MenuItem>
                                    <MenuItem value="Tag2">Tag 2</MenuItem>
                                </Select>
                            </FormControl>
                        </ThemeProvider>
                        <img src={settingsIcon} alt='settings' />
                    </div>
                </div>

                <div className="RCA-table-holder">
                    <table className="text-xs">
                        <thead>
                            <tr className="bg-ternary-bg">
                                <th className="border border-primary-border" style={{ width: '40px' }}>
                                    <Checkbox
                                        sx={{ color: "white", padding: '0px', width: '20px', }}
                                        onChange={() => {
                                            if (selectedRows?.length === sortedData?.length) {
                                                setSelectedRows([]);
                                            } else {
                                                setSelectedRows(sortedData.map((_, i) => i));
                                            }
                                        }}
                                        checked={selectedRows?.length === sortedData?.length}
                                    />
                                </th>
                                <th className="px-4 py-4 border border-primary-border">CLUSTER</th>
                                <th className="px-4 py-4 border border-primary-border">UPC</th>
                                <th className="px-4 py-4 border border-primary-border">KEY ACCOUNT</th>
                                <th className="px-4 py-4 border border-primary-border">SALES REGION</th>
                                <th className="px-4 py-4 border border-primary-border">RCA STAT</th>
                                <th className="px-4 py-4 border border-primary-border">RCA STAT OVERRIDE</th>
                                <th
                                    className="px-4 py-4 border border-primary-border cursor-pointer"
                                    onClick={() => handleSort('pfeAccuracy')}
                                >
                                    <div className="flex items-center justify-between">
                                        (PFE) FORECAST ACCURACY STAT
                                        {renderSortIcon('pfeAccuracy')}
                                    </div>
                                </th>
                                <th
                                    className="px-4 py-4 border border-primary-border cursor-pointer"
                                    onClick={() => handleSort('pfeBias')}
                                >
                                    <div className="flex items-center justify-between">
                                        (PFE) BIAS
                                        {renderSortIcon('pfeBias')}
                                    </div>
                                </th>
                                <th className="px-4 py-4 border border-primary-border">STATISTICAL FORECAST</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData?.map((row, idx) => (
                                <tr key={uniqueKey('rca_key_')} className="border border-gray-200">
                                    <td className="border border-primary-border" style={{ width: '40px' }}>
                                        <Checkbox
                                            sx={{ color: 'white', padding: '0px', width: '20px', height: '20px' }}
                                            checked={selectedRows.includes(idx)}
                                            onChange={() => handleCheckboxChange(idx)}
                                        />
                                    </td>
                                    <td className="px-4 py-4 border border-primary-border">{row.cluster}</td>
                                    <td className="px-4 py-4 border border-primary-border">{row.upc}</td>
                                    <td className="px-4 py-4 border border-primary-border">{row.account}</td>
                                    <td className="px-4 py-4 border border-primary-border">{row.region}</td>
                                    <td className="px-4 py-4 border border-primary-border">{row.rca}</td>
                                    <td className="border border-primary-border">
                                        <ThemeProvider theme={theme}>
                                            <FormControl
                                                fullWidth
                                                sx={{
                                                    minWidth: '80px',
                                                    backgroundColor: '#313131',
                                                    borderRadius: '0',
                                                }}
                                            >
                                                <Select
                                                    value={selectValue || ''}
                                                    onChange={handleSelectChange}
                                                    sx={{
                                                        height: '38px',
                                                        '& .MuiInputBase-root': {
                                                            color: 'white',
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#616161',
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'white',
                                                        },
                                                        "&.MuiOutlinedInput-notchedOutline": {
                                                            borderColor: '#616161'
                                                        }
                                                    }}
                                                >
                                                    <MenuItem value="Override1">Override 1</MenuItem>
                                                    <MenuItem value="Override2">Override 2</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </ThemeProvider>
                                    </td>
                                    <td className={`px-4 py-4 border border-primary-border ${getColor(row.pfeAccuracy)}`}>
                                        {row.pfeAccuracy}%
                                    </td>
                                    <td className={`px-4 py-4 border border-primary-border`}>
                                        {row.pfeBias}%
                                    </td>
                                    <td className="px-4 py-4 border border-primary-border">{row.forecast}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='mt-8 flex justify-center'>
                    <Pagination />
                </div>
            </div>
        </div>
    );
};

export default RCATable;
