// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.RCA-table-holder::-webkit-scrollbar {
    height: 10px; /* Height of horizontal scrollbar */
    background-color: grey; /* Background color of the scrollbar track */
    width: 10px;
    border-radius: 10px;
  }
  
  .RCA-table-holder::-webkit-scrollbar-corner {background-color: #1D1D1D;}
  
  .RCA-table-holder::-webkit-scrollbar-thumb {
    background-color: rgb(55, 55, 55); /* Color of the scrollbar thumb */
    border-radius: 10px; 
    border: 2px solid grey;
  }

  
  .RCA-table-holder {
    overflow: auto;
    height: 60.5vh;
    width: 100%;
  }
  
  table {
    width: 100%;
    table-layout: fixed;
  }
  
  th,
  td {
    text-align: left;
    width: 195px;
    font-size: 14px;
  }
  

  .dropDownButton{
    color: #3387DF;
    font-size: 16px;
    font-weight: 400;
    font-weight: bold;
    border:1px solid #3387DF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/RootCauseAnalysis/RcaTable.css"],"names":[],"mappings":";AACA;IACI,YAAY,EAAE,mCAAmC;IACjD,sBAAsB,EAAE,4CAA4C;IACpE,WAAW;IACX,mBAAmB;EACrB;;EAEA,6CAA6C,yBAAyB,CAAC;;EAEvE;IACE,iCAAiC,EAAE,iCAAiC;IACpE,mBAAmB;IACnB,sBAAsB;EACxB;;;EAGA;IACE,cAAc;IACd,cAAc;IACd,WAAW;EACb;;EAEA;IACE,WAAW;IACX,mBAAmB;EACrB;;EAEA;;IAEE,gBAAgB;IAChB,YAAY;IACZ,eAAe;EACjB;;;EAGA;IACE,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,wBAAwB;IACxB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":["\n.RCA-table-holder::-webkit-scrollbar {\n    height: 10px; /* Height of horizontal scrollbar */\n    background-color: grey; /* Background color of the scrollbar track */\n    width: 10px;\n    border-radius: 10px;\n  }\n  \n  .RCA-table-holder::-webkit-scrollbar-corner {background-color: #1D1D1D;}\n  \n  .RCA-table-holder::-webkit-scrollbar-thumb {\n    background-color: rgb(55, 55, 55); /* Color of the scrollbar thumb */\n    border-radius: 10px; \n    border: 2px solid grey;\n  }\n\n  \n  .RCA-table-holder {\n    overflow: auto;\n    height: 60.5vh;\n    width: 100%;\n  }\n  \n  table {\n    width: 100%;\n    table-layout: fixed;\n  }\n  \n  th,\n  td {\n    text-align: left;\n    width: 195px;\n    font-size: 14px;\n  }\n  \n\n  .dropDownButton{\n    color: #3387DF;\n    font-size: 16px;\n    font-weight: 400;\n    font-weight: bold;\n    border:1px solid #3387DF;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding-left: 30px;\n    padding-right: 30px;\n    border-radius: 50px;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
