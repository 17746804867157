import BasicTabs from "../../components/Tabs/customTabPanel";
import Experimentation from "./Experimentation";
import PredefinedRuns from "./PredefinedRuns";

const ResultVisualization = () => {
  return (
    <div className="p-5">
      <div className="flex justify-between mb-7">
        <span className="text-3xl font-bold">Result Visualization</span>
        <span>Sector: PBNA | Market: US</span>
      </div>
      <div className="mb-5">
        <span className="text-lg font-bold">Select Criteria</span>
      </div>
      <BasicTabs
        tabLabels={["Predefined Runs"]}
        tabStyles={{ color: "white" }}
      >
        <PredefinedRuns />
        <Experimentation />
      </BasicTabs>
    </div>
  );
};
export default ResultVisualization;
