import { useOktaAuth } from "@okta/okta-react";
import pepsiLogo from "../../images/PepsiLogo.svg";
import keyLogo from "../../images/key.svg";
import "./Login.css";
import { useEffect, useState } from "react";
import { Loading } from "../../baseComponent";

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  const loginClickHandler = async () => {
    setIsLoading(true);
    await oktaAuth.signInWithRedirect();
  };

  return (
    <div className="flex w-full h-full justify-center items-center loginDivHolder">
      <div className="loginDiv flex flex-col justify-center items-center">
        {isLoading ? <Loading /> : null}
        <img src={pepsiLogo} alt="pepsilogo" width={271.08} height={66} />
        <h6 className="mt-10" style={{ fontSize: "32px" }}>
          Integrated Business
        </h6>
        <h6 className="mt-8" style={{ fontSize: "32px" }}>
          Planning Monitoring
        </h6>
        <p className="mt-8 text-base" style={{ color: "#D0D0D0" }}>
          Welcome back! login with credentials
        </p>
        <button className="flex loginButton mt-14" onClick={loginClickHandler}>
          <img className="pl-3" src={keyLogo} alt="key" />
          <div>
            <div className="pl-32 font-bold">
              Login with Okta
            </div>
          </div>
        </button>
        {/* <div className="loginHelpButton mt-5">
          <div className="">
            <div className="font-bold text-center" style={{ color: "#D0D0D0" }}>
              Login Help
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
