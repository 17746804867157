import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { Series } from "../../../pages/RootCauseAnalysis/RcaSummaryCards/RcaSummaryCards";

interface ChartOptions {
  legend: {
    show: boolean;
  };
  chart: {
    height: number;
    width:string;
    type: string;
    background: string;
    toolbar: {
      show: boolean
    }
  };
  colors: string[];
  stroke: {
    show: boolean;
    width: number;
    colors: string[];
  };
  plotOptions: {
    treemap: {
      distributed: boolean;
      enableShades: boolean;
    };
  };
  theme:{
    mode:string
  }
}

interface TreemapProps {
  colors: string[]
  series: Series[]
}

const Treemap: React.FC<TreemapProps> = ({colors,series}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<ApexCharts | null>(null);

  useEffect(() => {
    if (chartRef.current) {

      const options: ChartOptions = {
        legend: {
          show: true
        },
        chart: {
          height: 600,
          width:"100%",
          type: "treemap",
          background: "#1D1D1D",
          toolbar: {
            show: false
          }
        },
        colors: colors,
        stroke: {
          show: true,
          width: 3,
          colors: ["#1D1D1D"]
        },
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false
          }
        },
        theme: {
          mode: "dark",
        },
      };

      chartInstance.current = new ApexCharts(chartRef.current, {
        series,
        chart: options.chart,
        plotOptions: options.plotOptions,
        colors: options.colors,
        legend: options.legend,
        stroke: options.stroke,
        theme: options.theme
      });
      chartInstance.current.render();
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [colors,series]);

  return (
    <div className="pr-2">
      <div id="chart" ref={chartRef} ></div> 
      <div id="html-dist"></div>
    </div>
  );
};

export default Treemap;
