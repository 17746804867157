import React from "react";
import { OverallPercentType } from "../../../store/foreCastBackTestingDataSlice";

interface BackTestingAnalyticsProps {
  overallAverage: OverallPercentType;
  overallPercent: { [key: string]: number }[];
}

const BackTestingAnalytics: React.FC<BackTestingAnalyticsProps> = ({
  overallAverage,
  overallPercent,
}) => {
  const renderProductAnalysisData = (): React.ReactNode => {
    return overallPercent?.map((data, index) => {
      const displayRow = Object.keys(data)
        .map((key, i) => `${Object.values(data)[i].toFixed(1)}% ${key}`)
        .join(", ");

      return (
        <p
          className="text-base mt-2 text-secondary-text"
          key={`percent_${index}`}
        >
          {displayRow}
        </p>
      );
    });
  };

  const getAccuracyColor = (accuracy: number): string => {
    if (accuracy > 70 && accuracy < 78) return "bg-yellow-bg";
    if (accuracy >= 78) return "bg-green-bg";
    return "bg-red-700";
  };

  const getBiasColor = (bias: number): string => {
    if (bias <= -4 || bias >= 4) return "bg-red-700";
    if ((bias >= -3 && bias <= -1) || (bias >= 1 && bias <= 3))
      return "bg-yellow-bg";
    return "bg-green-bg";
  };

  const getTextColor = (bgClass: string): string => {
    return bgClass === "bg-yellow-bg" ? "text-black" : "text-white";
  };

  const hasOverallAverageData = Object.values(overallAverage).some(
    (val) => val > 0
  );

  return (
    <>
      <div className="mt-8">
        <p className="text-lg font-bold text-secondary-text">Production</p>
        {renderProductAnalysisData()}
      </div>
      {hasOverallAverageData && (
        <div className="w-full grid grid-cols-4 gap-0 mt-5 p-4">
          <div
            className={`p-3 rounded-l-lg ${getAccuracyColor(
              overallAverage.accuracy
            )}`}
          >
            <p
              className={`text-2xl font-bold ${getTextColor(
                getAccuracyColor(overallAverage.accuracy)
              )}`}
            >
              {`${overallAverage.accuracy.toFixed(0)}%`}
            </p>
            <p
              className={`text-sm font-normal ${getTextColor(
                getAccuracyColor(overallAverage.accuracy)
              )}`}
            >
              Forecast Accuracy
            </p>
          </div>
          <div
            className={`p-3 border-l border-black ${getBiasColor(
              overallAverage.bias
            )}`}
          >
            <p
              className={`text-2xl font-bold ${getTextColor(
                getBiasColor(overallAverage.bias)
              )}`}
            >
              {`${overallAverage.bias.toFixed(0)}%`}
            </p>
            <p
              className={`text-sm font-normal ${getTextColor(
                getBiasColor(overallAverage.bias)
              )}`}
            >
              Bias
            </p>
          </div>
          <div className="bg-secondary-bg p-3 border-l border-x-gray-700">
            <p className="text-2xl font-bold text-white">
              {`${overallAverage.statisticalForecast.toLocaleString()}`}
            </p>
            <p className="text-sm font-normal text-white">Forecast</p>
          </div>
          <div className="bg-secondary-bg p-3 rounded-r-lg border-l border-x-gray-700">
            <p className="text-2xl font-bold text-white">
              {`${overallAverage.historicalShipments.toLocaleString()}`}
            </p>
            <p className="text-sm font-normal text-white">
              Historical Shipments
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default BackTestingAnalytics;
