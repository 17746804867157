import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setResultVisualizationFilter } from "../../store/filterSlice";
import { ListItemText, ThemeProvider } from "@mui/material";
import { filterOptions, theme } from "../../components/Filters/FiltersCommon";

type InputTypeProps = {
  itemList?: string[];
  itemType: string;
};

export default function MultipleSelectVisualization(
  itemsProps: Readonly<InputTypeProps>
) {
  const dispatch = useDispatch();
  const filter = useSelector(
    (state: RootState) => state.resultVisualizationFilter
  );

  const handleChange = (event: React.SyntheticEvent, value: string[]) => {
    let itemKey = itemsProps.itemType as keyof typeof filter;
    dispatch(setResultVisualizationFilter({ key: itemKey, value: value }));
  };

  const selectedCount =
    filter[itemsProps.itemType as keyof typeof filter]?.length || 0;

  React.useEffect(() => {
    let itemKey = itemsProps?.itemType as keyof typeof filter;
    if (filter[itemKey]?.length > 0) {
      dispatch(
        setResultVisualizationFilter({
          key: itemKey,
          value: filter[itemsProps.itemType as keyof typeof filter],
        })
      );
    }
  }, [filter, itemsProps?.itemType, dispatch]);

  return (
    <div className="flex">
      <ThemeProvider theme={theme}>
        <Autocomplete
          role="select-autocomplete"
          multiple
          limitTags={1}
          filterOptions={filterOptions}
          options={itemsProps?.itemList ?? []}
          value={filter[itemsProps.itemType as keyof typeof filter] ?? []}
          onChange={handleChange}
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option}>
              <Checkbox checked={selected} />
              <ListItemText primary={option} />
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {selectedCount > 0 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#94D6F0",
                          borderRadius: "4px",
                          padding: "0 8px",
                          height: "30px",
                          color: "black",
                          fontSize: "12px",
                          marginLeft: "8px",
                        }}
                      >
                        {selectedCount}
                      </div>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              sx={{
                "& .MuiInputBase-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#616161",
                },
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "none",
                  },
              }}
            />
          )}
          sx={{
            width: "90%",
            "& .MuiAutocomplete-tag": {
              color: "white",
            },
          }}
        />
      </ThemeProvider>
    </div>
  );
}
