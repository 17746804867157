import React from "react";
import { Routes, Route } from "react-router-dom";
import SecureRoute from "../components/SecuireRoute/SecureRoute";
import { StatisticalForecastAnalysis } from "../pages/StatisticalForecastAnalysis";
import Login from "../pages/Login/Login";
import ResultVisualization from "../pages/ResultVisualization/ResultVisualization";
import { Layout } from "../Layout";
import { LoginCallback } from "@okta/okta-react";
import { Loading } from "../baseComponent";
import RootCauseAnalysis from "../pages/RootCauseAnalysis/RootCauseAnalysis";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="login/callback"
        element={<LoginCallback loadingElement={<Loading />} />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<SecureRoute />}>
        <Route
          path=""
          element={
            <Layout>
              <StatisticalForecastAnalysis />
            </Layout>
          }
        />
        <Route
          path="/statisticalforecastanalysis"
          element={
            <Layout>
              <StatisticalForecastAnalysis />
            </Layout>
          }
        />
      </Route>
      <Route path="/rootcauseanalysis" element={<SecureRoute />}>
        <Route
          path=""
          element={
            <Layout>
              <RootCauseAnalysis />
            </Layout>
          }
        />
      </Route>
      <Route path="/resultvisualization" element={<SecureRoute />}>
        <Route
          path=""
          element={
            <Layout>
              <ResultVisualization />
            </Layout>
          }
        />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
