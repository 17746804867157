import React from "react";
import './anotattionsCss.css';
import { ChartAnnotation } from "./lineChart";
const uniqueKey = require("unique-key");

interface AnnotationLegendProps {
  legendItems: ChartAnnotation[];
}

const AnnotationLegend: React.FC<AnnotationLegendProps> = ({ legendItems }) => {
  const sortedLegendItems = [...legendItems].sort((a, b) => {
    const yearA = parseInt(a.label.content.match(/\((\d{4})\)$/)?.[1] || "0", 10);
    const yearB = parseInt(b.label.content.match(/\((\d{4})\)$/)?.[1] || "0", 10);
    return yearA - yearB;
  });

  return (
    <div className="anot-hold flex flex-col h-64 overflow-y-scroll space-y-1 ml-5 mt-20">
      {sortedLegendItems?.map((holidayName, index) => (
        <div key={uniqueKey('annotaion_legends')} className="flex items-center space-x-2">
          <span
            className="w-2.5 h-1"
            style={{ backgroundColor: holidayName.label.backgroundColor }}
          ></span>
          <span className="text-white text-sm">{holidayName.label.content}</span>
        </div>
      ))}
    </div>
  );
};

export default AnnotationLegend;
