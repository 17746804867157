import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  TextField,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { RootState } from "../../../store/store";
import {
  setResultVisualizationExpFilter,
  setResultVisualizationFilter,
} from "../../../store/filterSlice";

const LagDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const [length, setLength] = useState<boolean>(true);
  const expData = useSelector(
    (state: RootState) => state.resultVisualizationExperimentationData
  );
  const filters = useSelector((state: RootState) => state.rcaFilter);
  const [selectedLag, setSelectedLag] = useState<string | null>(null);

  useEffect(() => {
    const defaultLag = filters?.term?.toString() === "ST" ? "4" : "6";
    setSelectedLag(defaultLag);

    dispatch(
      setResultVisualizationExpFilter({
        key: "lagNumber",
        value: [defaultLag],
      })
    );
  }, [filters?.term, dispatch]);

  useEffect(() => {
    setLength(expData?.forecastLagNumbers?.length > 0 ? false : true);
  }, [expData]);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        paper: "#272727",
      },
      text: {
        primary: "#ffffff",
      },
    },
  });

  const handleLagChange = (
    event: React.SyntheticEvent,
    value: string | null
  ) => {
    setSelectedLag(value);

    dispatch(
      setResultVisualizationFilter({
        key: "lagNumber",
        value: value !== null ? [value] : [],
      })
    );
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="lag-dropdown-container">
        <Autocomplete
          className="p-2"
          disabled={length}
          options={
            expData?.forecastLagNumbers
              ?.slice()
              .map((num) => num.toString())
              .sort((a, b) => parseInt(a, 10) - parseInt(b, 10)) || []
          }
          getOptionLabel={(option) => option}
          value={selectedLag}
          onChange={handleLagChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Lag"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  borderColor: "#616161",
                },
                "& .MuiInputLabel-root": {
                  color: "#909090",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#616161",
                },
              }}
            />
          )}
          sx={{
            "& .MuiAutocomplete-popupIndicator": {
              color: "white",
            },
            "& .MuiAutocomplete-clearIndicator": {
              color: "white",
            },
          }}
        />
      </div>
    </ThemeProvider>
  );
};

export default LagDropdown;
