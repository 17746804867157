// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificationBar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ff891c;
  text-align: left;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  height: 40px;
  max-height: 56px;
  width: 100%;
  border-radius: 4px;
}

.notificationBar-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ff891c;
  width: 100%;
  font-weight: 400;
}

.notificationBar-black-bg {
  color: #000000;
}

.notificationBar-close-btn {
  cursor: pointer;
  float: right;
}
`, "",{"version":3,"sources":["webpack://./src/baseComponent/NotificationBar/NotificationBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,0BAA0B;EAC1B,QAAQ;EACR,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,YAAY;AACd","sourcesContent":[".notificationBar-container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  background: #ff891c;\n  text-align: left;\n  padding: 8px 16px 8px 16px;\n  gap: 8px;\n  height: 40px;\n  max-height: 56px;\n  width: 100%;\n  border-radius: 4px;\n}\n\n.notificationBar-text {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  background: #ff891c;\n  width: 100%;\n  font-weight: 400;\n}\n\n.notificationBar-black-bg {\n  color: #000000;\n}\n\n.notificationBar-close-btn {\n  cursor: pointer;\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
