import React, { ReactElement } from "react";
import { OverallPercentType } from "../../../store/foreCastBackTestingDataSlice";
const uniqueKey = require("unique-key");

interface BackTestingAnalyticstType {
  overallAverage: OverallPercentType;
  overallPercent: any[];
}

const BackTestingAnalytics: React.FC<BackTestingAnalyticstType> = ({
  overallAverage,
  overallPercent,
}) => {
  const renderProductAnalysisData = (): ReactElement[] => {
    return overallPercent?.map((data) => {
      let displayRow: string = "";
      const productKey = Object.keys(data);
      const productValue: number[] = Object.values(data);

      productKey?.forEach((data, index) => {
        const percentageValue = productValue[index];
        if (index === productKey?.length - 1) {
          displayRow += `${percentageValue.toFixed(0)}% ${data}`;
        } else {
          displayRow += `${percentageValue.toFixed(0)}% ${data}, `;
        }
      });

      return (
        <p
          className="text-base mt-2 text-secondary-text"
          key={uniqueKey("key_")}
        >
          {displayRow}
        </p>
      );
    });
  };

  return (
    <>
      <div className="mt-8">
        <p className="text-lg font-bold text-secondary-text">Production</p>
        {renderProductAnalysisData()}
      </div>
      {overallAverage?.accuracy > 0 ||
        overallAverage?.bias > 0 ||
        overallAverage?.statisticalForecast > 0 ||
        overallAverage?.historicalShipments > 0 ? (
          <div className=" w-full grid grid-cols-4 gap-0 mt-5">
            <div className="bg-yellow-bg p-3 rounded-l-lg">
              <p className="text-2xl font-bold text-black">
                {`${overallAverage?.accuracy}%`}
              </p>
              <p className="text-sm text-black font-normal">
                Forecast Accuracy
              </p>
            </div>
            <div className="bg-green-bg p-3 border-l border-black">
              <p className="text-2xl font-bold">
                {`${overallAverage?.bias}%`}
              </p>
              <p className="text-sm  font-normal">Bias</p>
            </div>
            <div className="bg-ternary-bg p-3 border-l border-x-gray-700">
              <p className="text-2xl font-bold">{`${overallAverage?.statisticalForecast}`}</p>
              <p className="text-sm  font-normal">Forecast</p>
            </div>
            <div className="bg-ternary-bg p-3 rounded-r-lg border-l border-x-gray-700">
              <p className="text-2xl font-bold">{`${overallAverage?.historicalShipments}`}</p>
              <p className="text-sm  font-normal">Historical Shipments</p>
            </div>
          </div>
         ) : null }
    </>
  );
};

export default BackTestingAnalytics;
