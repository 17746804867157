import * as React from "react";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker, CustomProvider } from "rsuite";

function DateRangeComp() {
  const [value, setValue] = React.useState<[Date, Date] | null>(null);
  
  return (
    <div>
      <CustomProvider theme="dark">
        <DateRangePicker
          format="MM/dd/yyyy"
          value={value}
          onChange={(newValue) => setValue(newValue)}
          style={{ width: 300 }}
          placeholder="Select Date Range"
        />
      </CustomProvider>
    </div>
  );
}

export default DateRangeComp;
