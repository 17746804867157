import { mapperForGroupByLableToKey } from "../api/forecastPageApi";
import { SIDEBAR_ROUTE_VALUES } from "./constant";

/**
 * Return the array of table data
 * @param rowColumnList
 * @param row
 * @returns string[]
 */
export const renderTableDynamicRow = (
  rowColumnList: string[],
  row: any
): string[] => {
  let rowData: string[] = [];
  let getRowKeys = mapperForGroupByLableToKey(rowColumnList);
  getRowKeys?.selectedGroupByOptions?.forEach((item: string) => {
    let itemFormat = item.replace(item[0], item[0].toLowerCase());
    rowData.push(row[itemFormat]);
  });
  return rowData;
};

export const getResponseDate = (dates: any, selectedDateStr: any) => {
  if (!dates || dates.length === 0) {
    return []; // or handle the empty array case as needed
  }

  const selectedDate = new Date(selectedDateStr);
  const parsedDates = dates.map((dateStr: any) => new Date(dateStr));

  if (selectedDate <= parsedDates[0]) {
    return [dates[0]];
  }

  for (let i = 1; i < parsedDates.length; i++) {
    if (selectedDate === parsedDates[i]) {
      return dates[i];
    }
    if (selectedDate < parsedDates[i]) {
      return [dates[i - 1]];
    }
  }

  return [dates[parsedDates.length - 1]];
};

export const findAndSortCurrentPathSnapshotDates = (
  data: {
    module: string;
    snapshotDates: string[];
  }[],
  currentPath: string
) => {
  type SidebarRouteKeys = keyof typeof SIDEBAR_ROUTE_VALUES;
  let currentPathData = data?.find(
    (i) => i.module === SIDEBAR_ROUTE_VALUES?.[currentPath as SidebarRouteKeys]
  );

  const sortedDates = currentPathData?.snapshotDates.sort(
    (a: string, b: string) => {
      return new Date(b).getTime() - new Date(a).getTime();
    }
  );

  return sortedDates;
};
/**
 * Calculate the padding value based on offsetWidth of the row
 *
 * @param backTestingTableRowRef
 * @param tableHeader
 * @param isPaddingNeedToAdjustMore
 * @returns
 */
export const calculateDynamicCellPadding = (
  backTestingTableRowRef: any,
  tableHeader: string[],
  isExperimentPage?: boolean,
  isPaddingNeedToAdjustMore?: boolean,
  isPredefinePage?: boolean
) => {
  const { totalColumnCount, calculateTotalWidth } = calculateColumnMetrics(
    backTestingTableRowRef,
    tableHeader
  );

  const calculatedRightPadding = calculateRightPadding(
    backTestingTableRowRef,
    totalColumnCount,
    calculateTotalWidth,
    isExperimentPage,
    isPaddingNeedToAdjustMore,
    isPredefinePage
  );

  const calculateWeekColumnWidth = calculateWeekColumnWidthValue(
    backTestingTableRowRef,
    totalColumnCount,
    isExperimentPage,
    isPredefinePage
  );

  return { calculatedRightPadding, calculateWeekColumnWidth, totalColumnCount };
};

const calculateColumnMetrics = (
  backTestingTableRowRef: any,
  tableHeader: string[]
) => {
  let totalColumnCount = 0;
  let calculateTotalWidth = 0;

  let isDataTypeFound = false;
  tableHeader?.forEach((item) => {
    if (item !== "DATA TYPE" && !isDataTypeFound) {
      totalColumnCount++;
    } else if (item === "DATA TYPE") {
      isDataTypeFound = true;
    }
  });

  if (backTestingTableRowRef?.current?.children) {
    for (let i = 0; i < totalColumnCount; i++) {
      calculateTotalWidth +=
        backTestingTableRowRef?.current?.children[i]?.offsetWidth;
    }
  }

  return { totalColumnCount, calculateTotalWidth };
};

const calculateRightPadding = (
  backTestingTableRowRef: any,
  totalColumnCount: number,
  calculateTotalWidth: number,
  isExperimentPage?: boolean,
  isPaddingNeedToAdjustMore?: boolean,
  isPredefinePage?: boolean
) => {
  let rightPadding = Math.round(calculateTotalWidth + totalColumnCount + 12);

  if (isExperimentPage) {
    rightPadding -= 175;
  } else if (isPaddingNeedToAdjustMore) {
    rightPadding -= 12;
  } else if (isPredefinePage) {
    rightPadding -= 160;
  }

  return rightPadding;
};

const calculateWeekColumnWidthValue = (
  backTestingTableRowRef: any,
  totalColumnCount: number,
  isExperimentPage?: boolean,
  isPredefinePage?: boolean
) => {
  let weekColumnWidth =
    backTestingTableRowRef?.current?.children?.[totalColumnCount]?.offsetWidth;

  if (isExperimentPage) {
    return weekColumnWidth / 7.3;
  } else if (isPredefinePage) {
    return weekColumnWidth / 2.2;
  } else {
    return weekColumnWidth / 4.9;
  }
};

export const getAccuracyColor = (value: number) => {
  if (value > 78) return { bg: "bg-green-700", text: "text-white" };
  if (value < 70) return { bg: "bg-red-600", text: "text-white" };
  return { bg: "bg-yellow-500", text: "text-black" };
};

export const getBiasColor = (bias: number) => {
  if (bias <= -5 || bias >= 5) return { bg: "bg-red-600", text: "text-white" };
  if ((bias >= -4.9 && bias <= -2.1) || (bias >= 2.1 && bias <= 4.9))
    return { bg: "bg-yellow-500", text: "text-black" };
  return { bg: "bg-green-700", text: "text-white" };
};
