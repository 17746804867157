import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setForecastFilter } from "../../../store/filterSlice";
import {
  createTheme,
  ThemeProvider,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

type InputTypeProps = {
  itemList?: string[];
  itemType: string;
  lag: boolean;
};

export default function SnapshotDateDropdown(
  itemsProps: Readonly<InputTypeProps>
) {
  const dispatch = useDispatch();
  const filter = useSelector((state: RootState) => state.forecastFilter);

  const theme = createTheme({
    palette: {
      background: {
        paper: "#272727",
      },
      text: {
        primary: "#ffff",
      },
    },
  });

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const valuez = [event.target.value] as string[];
    const itemKey = itemsProps.itemType as keyof typeof filter;
    dispatch(setForecastFilter({ key: itemKey, value: valuez }));
  };

  const handleLagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    return null;
  };

  React.useEffect(() => {
    const itemKey = itemsProps?.itemType as keyof typeof filter;
    if (
      filter[itemKey]?.length === 0 &&
      itemsProps.itemList &&
      itemsProps.itemList?.length > 0
    ) {
      dispatch(
        setForecastFilter({ key: itemKey, value: [itemsProps.itemList[0]] })
      );
    } else if (filter[itemKey]?.length > 0) {
      dispatch(
        setForecastFilter({
          key: itemKey,
          value: filter[itemsProps.itemType as keyof typeof filter],
        })
      );
    }
  }, [filter, itemsProps?.itemType, itemsProps.itemList, dispatch]);

  return (
    <div className="flex">
      <ThemeProvider theme={theme}>
        <FormControl sx={{ width: "70%" }}>
          <Select
            value={filter[itemsProps.itemType as keyof typeof filter] || ""}
            onChange={handleChange}
            renderValue={(selected) =>
              selected?.length === 0 ? (
                <span style={{ color: "#909090" }}>Select</span>
              ) : (
                selected
              )
            }
            displayEmpty
            sx={{
              height: "38px",
              "& .MuiInputBase-root": {
                color: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#616161",
              },
              "& .MuiSvgIcon-root": {
                color: "white",
              },
              "&.MuiOutlinedInput-notchedOutline": {
                borderColor: "#616161",
              },
            }}
          >
            {itemsProps?.itemList?.map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox
                  checked={
                    filter[itemsProps.itemType as keyof typeof filter].indexOf(
                      item
                    ) > -1
                  }
                />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: "30%",
            position: "relative",
            bottom: "28px",
            padding: "4px",
          }}
        >
          {itemsProps?.lag ? (
            <>
              <Typography
                variant="caption"
                sx={{ color: "#909090", fontSize: "13px" }}
              >
                Lag
              </Typography>
              <TextField
                sx={{ width: "100%", marginBottom: "10px" }}
                defaultValue={"4"}
                variant="outlined"
                type="string"
                InputProps={{
                  style: {
                    color: "white",
                    borderColor: "#616161",
                    padding: "2px",
                  },
                }}
                onChange={handleLagChange}
              />{" "}
            </>
          ) : (
            <></>
          )}
        </FormControl>
      </ThemeProvider>
    </div>
  );
}
