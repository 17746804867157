import React from 'react';

const Metrics = () => {
  return (
    <div className="mt-4">
      <div className="bg-ternary-bg text-white p-6 pb-10 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Metrics</h2>
        </div>

        <div className="flex justify-around">
          <div className="text-center pb-2">
            <p className="text-4xl font-bold">68%</p>
            <p className="uppercase text-sm tracking-wider">PFE Forecast Accuracy</p>
          </div>

          <div className="border-l border-gray-700"></div>

          <div className="text-center pb-2">
            <p className="text-4xl font-bold">3%</p>
            <p className="uppercase text-sm tracking-wider">PFE Forecast Bias</p>
          </div>

          <div className="border-l border-gray-700"></div>

          <div className="text-center pb-2">
            <p className="text-4xl font-bold">70%</p>
            <p className="uppercase text-sm tracking-wider">DP Forecast Accuracy</p>
          </div>

          <div className="border-l border-gray-700"></div>

          <div className="text-center pb-2">
            <p className="text-4xl font-bold">4%</p>
            <p className="uppercase text-sm tracking-wider">DP Forecast Bias</p>
          </div>

          <div className="border-l border-gray-700"></div>

          <div className="text-center pb-2">
            <p className="text-4xl font-bold">+10</p>
            <p className="uppercase text-sm tracking-wider">DP Forecast Value Add</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metrics;
