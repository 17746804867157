const ComparisonResultExperimentation = () => {
  return (
    <div className='mt-5'>
    <p className='text-2xl font-medium'>Comparison Result</p>
    <div className='mt-8'>
        <p className='text-lg font-bold text-secondary-text'>Production Model</p>
        <p className='text-base mt-2 text-secondary-text'>75% Orbit, 25% Arima</p>
        <p className='text-base text-secondary-text'>50% Global, 25% Local</p>
    </div>
    <div className=" w-full grid grid-cols-4 gap-0 mt-5">
        <div className='bg-yellow-bg p-3 rounded-l-lg'>
            <p className='text-2xl font-bold text-black'>69.84%</p>
            <p className='text-sm text-black font-normal'>Forecast Accuracy</p>
        </div>
        <div className='bg-green-bg p-3 border-l border-black'>
            <p className='text-2xl font-bold'>3.21%</p>
            <p className='text-sm  font-normal'>Bias</p>
        </div>
        <div className='bg-ternary-bg p-3 border-l border-x-gray-700'>
            <p className='text-2xl font-bold'>2,413.253</p>
            <p className='text-sm  font-normal'>Forecast</p>
        </div>
        <div className='bg-ternary-bg p-3 rounded-r-lg border-l border-x-gray-700'>
            <p className='text-2xl font-bold'>96.65%</p>
            <p className='text-sm  font-normal'>Absolute Error</p>
        </div>
    </div>
    <div className='mt-8'>
        <p className='text-lg font-bold text-secondary-text'>Experiment ID: 13843</p>
        <p className='text-base mt-2 text-secondary-text'>75% Orbit, 25% Arima</p>
        <p className='text-base text-secondary-text'>50% Global, 50% Local</p>
    </div>
    <div className=" w-full grid grid-cols-4 gap-0 mt-5">
        <div className='bg-yellow-bg p-3 rounded-l-lg'>
            <p className='text-2xl font-bold text-black'>60.94%</p>
            <p className='text-sm text-black font-normal'>Forecast Accuracy</p>
        </div>
        <div className='bg-green-bg p-3 border-l border-black'>
            <p className='text-2xl font-bold'>2.37%</p>
            <p className='text-sm  font-normal'>Bias</p>
        </div>
        <div className='bg-ternary-bg p-3 border-l border-x-gray-700'>
            <p className='text-2xl font-bold'>2413.253</p>
            <p className='text-sm  font-normal'>Forecast</p>
        </div>
        <div className='bg-ternary-bg p-3 rounded-r-lg border-l border-x-gray-700'>
            <p className='text-2xl font-bold'>96.65%</p>
            <p className='text-sm  font-normal'>Absolute Error</p>
        </div>
    </div>

    </div>
  )
}

export default ComparisonResultExperimentation