import "./App.css";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useNavigate } from "react-router-dom";
import { OKTA_CONFIG } from "./config";
import AppRoutes from "./routes/AppRoutes";
import { Provider } from "react-redux";
import { store } from "./store/store";


const oktaAuth = new OktaAuth(OKTA_CONFIG);

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };


  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>

      <div className="App">
        <Provider store={store}>
          <AppRoutes />
        </Provider>
      </div>
    </Security>
  );
}

export default App;
