import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ForeCastBackTestingTableDataType,
  initialLoadingState,
  initialTableState,
  PaginationType,
} from "./foreCastBackTestingDataSlice";
import { PAGE_LIMIT } from "../utils/constant";

export interface ChartDataType {
  accuracy: number;
  bias: number;
  statisticalForecast: number;
  historicalShipment: number;
  timeFrameDate: string;
  periodWeekNumber: string;
}

export interface ChartRankType {
  rank: number;
  data: ChartDataType[];
}

export interface RankBasedPercentType {
  rank: number;
  data: { local: number; global: number }[];
}

export interface OverallAverageType {
  rank: number;
  data: {
    accuracy: number;
    bias: number;
    historicalShipments: number;
    statisticalForecast: number;
  };
}

export interface HierarchyComparisonResultType {
  H1: number;
  H2: number;
  H3: number;
}

export interface AverageDataType {
  rankBasedPercent: RankBasedPercentType[];
  overallAverage: OverallAverageType[];
  hierarchyComparisonResult: HierarchyComparisonResultType;
}

export interface ForeCastApiChartResponseType {
  chartData: ChartRankType[];
  average: AverageDataType;
  loader: boolean;
}

export const initialState: ForeCastApiChartResponseType = {
  chartData: [],
  average: {
    rankBasedPercent: [
      {
        rank: 1,
        data: [{ local: 0, global: 0 }],
      },
      {
        rank: 2,
        data: [{ local: 0, global: 0 }],
      },
      {
        rank: 3,
        data: [{ local: 0, global: 0 }],
      },
    ],
    overallAverage: [
      {
        rank: 1,
        data: {
          accuracy: 0,
          bias: 0,
          historicalShipments: 0,
          statisticalForecast: 0,
        },
      },
      {
        rank: 2,
        data: {
          accuracy: 0,
          bias: 0,
          historicalShipments: 0,
          statisticalForecast: 0,
        },
      },
      {
        rank: 3,
        data: {
          accuracy: 0,
          bias: 0,
          historicalShipments: 0,
          statisticalForecast: 0,
        },
      },
    ],
    hierarchyComparisonResult: {
      H1: 0,
      H2: 0,
      H3: 0,
    },
  },
  loader: false,
};

const initialPaginationState: PaginationType = {
  pageNumber: 1,
  pageLimit: PAGE_LIMIT,
  totalPages: 0,
  isDataShownClicked: false,
  groupBySelectedField: [],
};

export const ResultVisualizationSlice = createSlice({
  name: "ResultVisualizationData",
  initialState,
  reducers: {
    setChartData: (state, action: PayloadAction<ChartRankType[]>) => {
      state.chartData = action?.payload;
    },
    updateChartData: (
      state,
      action: PayloadAction<{ rank: number; data: ChartDataType[] }>
    ) => {
      const { rank, data } = action?.payload;
      const existingRank = state.chartData.find((item) => item.rank === rank);
      if (existingRank) {
        existingRank.data = data;
      }
    },
    setAverageData: (state, action: PayloadAction<AverageDataType>) => {
      state.average = action?.payload;
    },
    resetResultVisulizationChartData: (state) => {
      state.chartData = [];
      state.average = initialState.average;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action?.payload;
    },
  },
});

export const resultVisulizationBackTestingTableDataSlice = createSlice({
  name: "resultVisulizationBackTestingTableDataSlice",
  initialState: initialTableState,
  reducers: {
    resultVisulizationBackTestingTableDataSetter: (
      state,
      action: PayloadAction<ForeCastBackTestingTableDataType>
    ) => {
      state.backTestingTableData = action?.payload.backTestingTableData;
      state.tableHeader = action?.payload.tableHeader;
      state.isTableDataLoading = action?.payload.isTableDataLoading;
    },
    resetresultVisulizationBackTestingTableState: (state) => {
      state.backTestingTableData = [];
      state.tableHeader = [];
      state.isTableDataLoading = false;
    },
  },
});

export const resultVisulizationTestingDataLoadingSlice = createSlice({
  name: "resultVisulizationTestingDataLoadingSlice",
  initialState: initialLoadingState,
  reducers: {
    resultVisulizationBackTestingApiDataLoading: (
      state,
      action: PayloadAction<ForeCastBackTestingTableDataType>
    ) => {
      state.isDataLoading = action?.payload.isTableDataLoading;
    },
    resetresultVisulizationBackTestingApiDataLoading: (state) => {
      state.isDataLoading = false;
    },
  },
});

export const resultVisulizationBackTestingPagination = createSlice({
  name: "resultVisulizationTestingPagination",
  initialState: initialPaginationState,
  reducers: {
    updateBackTestingPageNumber: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    updateBackTestingPageLimit: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    updateBackTestingTotalPages: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    resetPredefinePaginationState: (state) => {
      state.pageNumber = 1;
      state.pageLimit = PAGE_LIMIT;
      state.totalPages = 0;
      state.isDataShownClicked = false;
      state.groupBySelectedField = [];
    },
  },
});

export const {
  setChartData,
  updateChartData,
  setAverageData,
  resetResultVisulizationChartData,
  setLoader,
} = ResultVisualizationSlice.actions;

export const {
  updateBackTestingPageLimit,
  updateBackTestingPageNumber,
  updateBackTestingTotalPages,
  resetPredefinePaginationState,
} = resultVisulizationBackTestingPagination.actions;

export const {
  resultVisulizationBackTestingTableDataSetter,
  resetresultVisulizationBackTestingTableState,
} = resultVisulizationBackTestingTableDataSlice.actions;

export const {
  resultVisulizationBackTestingApiDataLoading,
  resetresultVisulizationBackTestingApiDataLoading,
} = resultVisulizationTestingDataLoadingSlice.actions;

export default ResultVisualizationSlice.reducer;
