import { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material";
const uniqueKey = require("unique-key");

type OptionType = {
  key: string;
  label: string;
  value: string;
};

type InputTypeProps = {
  selectedValue: string;
  itemList?: Array<OptionType>;
  className?: string;
  onChange?: (Event: SelectChangeEvent) => void;
};

const theme = createTheme({
  palette: {
    background: {
      paper: '#272727',
    },
    text: {
      primary: 'white',
    },
  },
});

const InputSelectType = (props: InputTypeProps) => {
  const [selectedOptionValue, setSelectedOptionValue] = useState(
    props?.selectedValue
  );

  useEffect(() => {
    setSelectedOptionValue(props?.selectedValue);
  }, [props?.selectedValue]);

  /**
   * Render optionField form the itemList
   * @returns React.ReactNode
   */
  const renderOptions = (): React.ReactNode => {
    return props?.itemList?.map((item) => (
      <MenuItem key={`${uniqueKey('key_')}-menu-item`} value={item?.value}> {item?.label}</MenuItem>
    ));
  };



  return (
    <ThemeProvider theme={theme}>
    <Select
      role="input-select-type"
      className={props?.className}
      value={selectedOptionValue}
      onChange={props?.onChange}
      displayEmpty
      sx={{
        borderRadius: '4px',
        border: '1px solid #616161',
        color: 'white',
        width: '90%',
        height: '32px',
        '& .MuiSelect-icon': {
          color: 'white', // Optional: Set the color of the dropdown arrow
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: 0
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none"
        }
      }}
    > 
      {renderOptions()}
    </Select>
    </ThemeProvider>
  );
};

export default InputSelectType;
