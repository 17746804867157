import axios from "axios";

interface Params {
  baseUrl: string;
  headers: Record<string, string>;
}

const getConfig: Params = {
  baseUrl: process.env.REACT_APP_API_KEY as string,
  headers: {},
};

const postConfig: Params = {
  baseUrl: process.env.REACT_APP_API_KEY as string,
  headers: {},
};

export const postAPI = async (
  url: string,
  data: any,
  authToken: string
): Promise<any> => {
  try {
    const response = await axios.post(`${postConfig.baseUrl}${url}`, data, {
      headers: {
        Authorization: "Bearer " + authToken,
      },
    });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error: any) {
    console.error(error);
    return {
      status: error.response?.status || 500,
      data: error.response?.data || "Something went wrong",
    };
  }
};

export const getAPI = async (
  url: string,
  data: any,
  authToken: string
): Promise<any> => {
  try {
    const response = await axios.get(`${getConfig.baseUrl}${url}`, {
      headers: {
        Authorization: "Bearer " + authToken,
      },
    });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error: any) {
    console.error(error);
    return {
      status: error.response?.status || 500,
      data: error.response?.data || "Something went wrong",
    };
  }
};
