import { createFilterOptions, createTheme } from "@mui/material";

export const filterOptions = createFilterOptions({
    matchFrom: 'start',
    limit: 200,
    stringify: (option: string) => option,
});

export const theme = createTheme({
    palette: {
        background: {
        paper: '#272727',
        },
        text: {
        primary: '#ffff',
        },
    },
});

