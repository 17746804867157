import { mapperForGroupByLableToKey } from "../api/forecastPageApi";

  /**
   * Return the array of table data
   * @param rowColumnList
   * @param row
   * @returns string[]
   */
 export const renderTableDynamicRow = (
    rowColumnList: string[],
    row: any
  ): string[] => {
    let rowData: string[] = [];
    let getRowKeys = mapperForGroupByLableToKey(rowColumnList);
    getRowKeys?.selectedGroupByOptions.pop();
    getRowKeys?.selectedGroupByOptions?.forEach((item: string) => {
      let itemFormat = item.replace(item[0], item[0].toLowerCase());
      rowData.push(row[itemFormat]);
    });
    return rowData;
  };
