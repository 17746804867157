import React, { ReactElement } from "react";
import "./NotificationBar.css";
import AlertImg from "../../images/alert.png";
import closeIconBlack from "../../images/closeIconBlack.png";

interface NotificationBarType {
  text: string;
  shouldNotificationVisible: boolean;
  onCloseNotification: (notificationStatus: boolean) => void;
}

const NotificationBar: React.FC<NotificationBarType> = ({
  text,
  shouldNotificationVisible,
  onCloseNotification,
}): ReactElement => {
  const renderNotificationHtml = () => {
    if (shouldNotificationVisible) {
      return (
        <div className="notificationBar-container">
          <div className="notificationBar-text">
            <img
              src={AlertImg}
              alt="Alert Icon"
              style={{
                width: "18px",
                height: "18px",
              }}
            />
            <span className="notificationBar-black-bg pl-6">{text}</span>
          </div>
          <img
            src={closeIconBlack}
            alt="close btn"
            className="notificationBar-close-btn"
            onClick={() => onCloseNotification(false)}
          />
        </div>
      );
    }
  };

  return <div className="pb-8">{renderNotificationHtml()}</div>;
};

export default NotificationBar;
