import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Interfaces
export interface FilterSection {
  labels: string[];
  mappings: string[];
  position: number;
}

export interface SectorFilters {
  [category: string]: FilterSection;
}

export interface FilterData {
  [sector: string]: SectorFilters;
}

export interface UserSectorData {
  defaultSector: string;
  defaultMarket: string;
  sectors: string[];
  markets: string[];
}

export interface SectionConfig {
  enabled: boolean;
}

export interface Sections {
  forecastAnalysis: SectionConfig;
  predefinedRuns: SectionConfig;
  predefinedRunsExperimentation: SectionConfig;
  rcaSummary: SectionConfig;
  rcaExecution: SectionConfig;
}


export interface currentSector {
  sector: string;
  market: string;
}

export interface UserState {
  filterData: FilterData | null;
  userSectorData: UserSectorData | null;
  sections: Sections | null;
  currentSector?: currentSector | null;
}

// Initial state
const initialState: UserState = {
  filterData: null,
  userSectorData: null,
  sections: null,
};

// Slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserState>) => {
      state.filterData = action.payload.filterData;
      state.userSectorData = action.payload.userSectorData;
      state.sections = action.payload.sections;
    },
    setCurrentSectorFilter: (state, action: PayloadAction<currentSector>) => {
      state.currentSector = { sector: action.payload.sector, market: action.payload.market };
    },
    clearUserData: (state) => {
      state.filterData = null;
      state.userSectorData = null;
      state.sections = null;
    },
  },
});

export const { setUserData, setCurrentSectorFilter, clearUserData } = userSlice.actions;
export default userSlice.reducer;

