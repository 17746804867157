import { useEffect, useState } from "react";
import loadingSvg from "../../images/bouncing-circles.svg";
import ForecastDecomposeTable from "../../components/ForecastDecomposeTable/ForecastDecomposeTable";
import BarStackedChart from "../../components/chart/BarStacked/BarStackedChart";
import { useOktaAuth } from "@okta/okta-react";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { DataType } from "../../store/foreCastDecomSlice";
import { Pagination } from "../../components/Pagination";
import { updateForecastAnalysisPageNumber } from "../../store/foreCastDataSlice";

interface SeriesData {
  name: string;
  data: number[];
  color: string;
  type: string;
}

interface ForecastDataRow {
  weekPeriodNumber: string;
  totalSeasonalQuantity: number;
  totalTradePromotionsQuantity: number;
  totalCustomerPromotionsQuantity: number;
  totalHolidaysEventsQuantity: number;
  totalFixedElementsQuantity: number;
  totalTradePartnersQuantity: number;
  totalBaselineQuantity: number;
  totalMacroEconomicsQuantity: number;
  totalMerchandisingQuantity: number;
  totalSalesIncentivesQuantity: number;
  totalAdvertisingQuantity: number;
  totalWeatherQuantity: number;
  totalPerfectStoreQuantity: number;
  totalDirectPointOfSalesQuantity: number;
  totalInnovationNPDQuantity: number;
  totalListPriceQuantity: number;
  totalDARateQuantity: number;
  totalMarketShareQuantity: number;
  totalInternalInventoryAvailabilityQuantity: number;
  totalExternalInventoryAvailabilityQuantity: number;
  totalRawMaterialInventoryQuantity: number;
  totalPurchaseOrdersQuantity: number;
  totalMobilityQuantity: number;
  totalShipQuantity: number;
}

interface ForecastDecomposeTableProps {
  forecastDecompositionData: ForecastDataRow[];
}

function ForecastDecomposition() {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<ForecastDecomposeTableProps>({
    forecastDecompositionData: [],
  });
  const [chartData, setChartData] = useState<SeriesData[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const { authState } = useOktaAuth();
  const forecastData = useSelector(
    (state: RootState) => state.foreCastDecomp.data
  );

  const foreCastDecompositionPaginationData = useSelector(
    (state: RootState) => state.foreCastDecompositionPaginationData
  );
  const foreCastDecomp = useSelector(
    (state: RootState) => state.foreCastDecomp
  );
  const loadingDecomposition = useSelector(
    (state: RootState) => state.foreCastDecomp.loadingDecomposition
  );


  const colors = [
    "#33ABDA",
    "#0078A7",
    "#A952AA",
    "#59346A",
    "#B993EB",
    "#6E3CB2",
    "#FF5733",
    "#4CAF50",
    "#FFC300",
    "#8E44AD",
    "#2E86C1",
    "#16A085",
    "#C0392B",
    "#27AE60",
    "#2980B9",
    "#D35400",
    "#34495E",
    "#F39C12",
    "#8E44AD",
    "#2C3E50",
    "#BDC3C7",
    "#9B59B6",
    "#E74C3C",
    "#FFA800",
  ];

  const seriesNames = [
    "Seasonal",
    "Trade Promotions",
    "Customer Promotions",
    "Holiday Events",
    "Fixed Elements",
    "Trade Partners",
    "Baseline",
    "Macro Economics",
    "Merchandising",
    "Sales Incentives",
    "Advertising",
    "Weather",
    "Perfect Store",
    "Direct Point of Sales",
    "Innovation NPD",
    "List Price",
    "DA Rate",
    "Market Rate",
    "Internal Inventory Availability",
    "External Inventory Availability",
    "Raw Material Inventory",
    "Purchase Orders",
    "Mobility",
    "Total Ship Quantity",
  ];

  const seriesKeys: (keyof DataType)[] = [
    "totalSeasonalQuantity",
    "totalTradePromotionsQuantity",
    "totalCustomerPromotionsQuantity",
    "totalHolidaysEventsQuantity",
    "totalFixedElementsQuantity",
    "totalTradePartnersQuantity",
    "totalBaselineQuantity",
    "totalMacroEconomicsQuantity",
    "totalMerchandisingQuantity",
    "totalSalesIncentivesQuantity",
    "totalAdvertisingQuantity",
    "totalWeatherQuantity",
    "totalPerfectStoreQuantity",
    "totalDirectPointOfSalesQuantity",
    "totalInnovationNPDQuantity",
    "totalListPriceQuantity",
    "totalDARateQuantity",
    "totalMarketShareQuantity",
    "totalInternalInventoryAvailabilityQuantity",
    "totalExternalInventoryAvailabilityQuantity",
    "totalRawMaterialInventoryQuantity",
    "totalPurchaseOrdersQuantity",
    "totalMobilityQuantity",
    "totalShipQuantity",
  ];

  useEffect(() => {
    const fetchForecastDecomposition = async () => {
      const data: DataType[] = forecastData;

      const dataForTable: ForecastDataRow[] = data?.map((item) => ({
        weekPeriodNumber: item.weekPeriodNumber,
        totalSeasonalQuantity: item.totalSeasonalQuantity,
        totalTradePromotionsQuantity: item.totalTradePromotionsQuantity,
        totalCustomerPromotionsQuantity: item.totalCustomerPromotionsQuantity,
        totalHolidaysEventsQuantity: item.totalHolidaysEventsQuantity,
        totalFixedElementsQuantity: item.totalFixedElementsQuantity,
        totalTradePartnersQuantity: item.totalTradePartnersQuantity,
        totalBaselineQuantity: item.totalBaselineQuantity,
        totalMacroEconomicsQuantity: item.totalMacroEconomicsQuantity,
        totalMerchandisingQuantity: item.totalMerchandisingQuantity,
        totalSalesIncentivesQuantity: item.totalSalesIncentivesQuantity,
        totalAdvertisingQuantity: item.totalAdvertisingQuantity,
        totalWeatherQuantity: item.totalWeatherQuantity,
        totalPerfectStoreQuantity: item.totalPerfectStoreQuantity,
        totalDirectPointOfSalesQuantity: item.totalDirectPointOfSalesQuantity,
        totalInnovationNPDQuantity: item.totalInnovationNPDQuantity,
        totalListPriceQuantity: item.totalListPriceQuantity,
        totalDARateQuantity: item.totalDARateQuantity,
        totalMarketShareQuantity: item.totalMarketShareQuantity,
        totalInternalInventoryAvailabilityQuantity:
          item.totalInternalInventoryAvailabilityQuantity,
        totalExternalInventoryAvailabilityQuantity:
          item.totalExternalInventoryAvailabilityQuantity,
        totalRawMaterialInventoryQuantity:
          item.totalRawMaterialInventoryQuantity,
        totalPurchaseOrdersQuantity: item.totalPurchaseOrdersQuantity,
        totalMobilityQuantity: item.totalMobilityQuantity,
        totalShipQuantity: item.totalShipQuantity,
      }));

      setTableData({ forecastDecompositionData: dataForTable });

      const extractedCategories = data?.map((item) =>
        item.weekPeriodNumber ? item.weekPeriodNumber.toUpperCase() : ""
      );

      setCategories(extractedCategories);

      const seriesData = seriesNames?.map((name, index) => ({
        name,
        data: data?.map((item) =>
          Math.trunc(Number(item[seriesKeys[index]] || 0))
        ),
        color: colors[index],
        type: name === "Total Ship Quantity" ? "line" : "bar",
      }));

      setChartData(seriesData);
    };
    fetchForecastDecomposition();
  }, [authState?.accessToken, forecastData]);

  const handlePageNumberUpdateEvent = (currentPageNumber: number) => {
    dispatch(
      updateForecastAnalysisPageNumber({
        pageLimit: foreCastDecompositionPaginationData?.pageLimit,
        pageNumber: currentPageNumber,
        totalPages: foreCastDecompositionPaginationData?.totalPages,
        isDataShownClicked: false
      })
    );
  };

  const renderForecastDecomposeTable = () => {
    let childElement = null;
    if (forecastData?.length > 0) {
      childElement = (
        <ForecastDecomposeTable
          forecastDecompositionData={tableData.forecastDecompositionData}
        />
      );
    }

    return foreCastDecomp?.loadingDecomposition ? (
      <div className="flex justify-center">
        <img
          src={loadingSvg}
          alt="loading"
          className="mt-10"
          width={40}
          height={40}
        />
      </div>
    ) : (
      childElement
    );
  };

  return (
    <div className="p-4 bg-secondary-bg mt-4 rounded">
      <h6 className="text-lg font-bold mb-4">Forecast Decomposition</h6>

      <div className="bg-ternary-bg rounded p-2">
        {(() => {
          let content;
          if (!loadingDecomposition && forecastData?.length === 0) {
            content = (
              <div className="flex justify-center">
                <p className="p-2 m-1">
                  No Data to show or no options selected, please choose from the above filters.
                </p>
              </div>
            );
          } else if (chartData?.length > 0 && categories?.length > 0) {
            content = (
              <BarStackedChart series={chartData} categories={categories} />
            );
          } else {
            content = (
              <div className="flex justify-center">
                <img
                  src={loadingSvg}
                  alt="loading"
                  className="mt-10"
                  width={40}
                  height={40}
                />
              </div>
            );
          }
          return content;
        })()}
        {renderForecastDecomposeTable()}
      </div>

      {forecastData?.length !== 0 && (
        <div className="mt-5">
          {tableData?.forecastDecompositionData?.length > 0 &&
            foreCastDecompositionPaginationData?.totalPages > 1 && (
              <div className="flex justify-center">
                <Pagination
                  totalPages={foreCastDecompositionPaginationData?.totalPages}
                  activePageNumber={
                    foreCastDecompositionPaginationData?.pageNumber
                  }
                  updateBackTestingPageNumber={(data) =>
                    handlePageNumberUpdateEvent(data)
                  }
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default ForecastDecomposition;
