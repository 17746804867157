import { createFilterOptions, createTheme } from "@mui/material";

export const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 200,
  stringify: (option: string) => option.toLowerCase(),
});

export const theme = createTheme({
  palette: {
    background: {
      paper: "#272727",
    },
    text: {
      primary: "#ffff",
    },
  },
});
