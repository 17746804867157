import React, { useEffect, useState } from "react";
import "./pagination.css";
import { PAGE_LIMIT } from "../../utils/constant";

interface PaginationPageProps {
  totalPages?: number;
  activePageNumber?: number;
  updateBackTestingPageNumber?: (pageNumber: number) => void;
}

interface PageRangeType {
  id: number;
  label: string;
}

const Pagination: React.FC<PaginationPageProps> = ({
  totalPages = 0,
  activePageNumber = 1,
  updateBackTestingPageNumber = () => {},
}) => {
  const [activePage, setActivePage] = useState(activePageNumber);
  const [pageRange, setPageRange] = useState<PageRangeType[]>([]);
  const [visibleRange, setVisibleRange] = useState<PageRangeType[]>([]);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    let rangeLimit = PAGE_LIMIT;
    let currentOffSet = 1;
    const totalRangeNum = Math.ceil(totalPages / rangeLimit);
    let pageRangeArray: PageRangeType[] = [];

    for (let i = 1; i <= totalPages; i++) {
      const pageRangeLabel = `${currentOffSet} - ${Math.min(
        currentOffSet + rangeLimit - 1,
      )}`;
      pageRangeArray.push({
        id: i,
        label: pageRangeLabel,
      });
      currentOffSet += rangeLimit;
    }

    if (totalPages > 20) {
      const lastValue = Math.ceil(totalPages / 10) * 10;
      pageRangeArray.push({
        id: totalRangeNum + 1,
        label: `${lastValue}`,
      });
    }

    setPageRange(pageRangeArray);
    setVisibleRange(pageRangeArray.slice(0, 3));
  }, [totalPages]);

  const handlePageClick = (id: number) => {
    updateBackTestingPageNumber(id);
    setActivePage(id);
  };

  const handleArrowClick = (direction: string) => {
    const totalRanges = pageRange.length;

    if (direction === "prev" && offset > 0) {
      setOffset(offset - 1);
      setVisibleRange(pageRange.slice(offset - 1, offset + 2));
    } else if (direction === "next" && offset + 3 < totalRanges) {
      setOffset(offset + 1);
      setVisibleRange(pageRange.slice(offset + 1, offset + 4));
    }
  };

  const handleGoToFirst = () => {
    setOffset(0);
    setVisibleRange(pageRange.slice(0, 3));
    handlePageClick(1);
  };

  const handleGoToLast = () => {
    const totalRanges = pageRange.length;
    const lastPageIndex = totalRanges > 3 ? totalRanges - 3 : 0;
    setOffset(lastPageIndex);
    setVisibleRange(pageRange.slice(lastPageIndex, lastPageIndex + 3));
    handlePageClick(totalRanges);
  };

  return (
    <div className="pagination">
      <div className="flex flex-row">
        <button className="arrow-btn" onClick={handleGoToFirst} disabled={offset === 0}>
          {"<<"}
        </button>
        <button className="arrow-btn" onClick={() => handleArrowClick("prev")} disabled={offset === 0}>
          &lt;
        </button>
        <div className="flex flex-row">
          {visibleRange?.map((page) => (
            <div className={`page ${page.id === activePage ? "active" : ""}`} key={page.id}>
              <button onClick={() => handlePageClick(page.id)}>{page.label}</button>
            </div>
          ))}
        </div>
        <button className="arrow-btn" onClick={() => handleArrowClick("next")} disabled={offset + 3 >= pageRange.length}>
          &gt;
        </button>
        <button className="arrow-btn" onClick={handleGoToLast} disabled={offset + 3 >= pageRange.length}>
          {">>"}
        </button>
      </div>
    </div>
  );
};

export default Pagination;
