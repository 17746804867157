import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { useForecastClusterFilter } from "../../../api/filtersApi";
import { useOktaAuth } from "@okta/okta-react";
import MultipleSelectForecast from "../../../baseComponent/inputSelectType/multiInputSelectForecast";
import loadingSvg from "../../../images/bouncing-circles.svg";
import { useDispatch } from "react-redux";
import {
  FilterState,
  setForecastFilter,
  setRcaFilter,
  setResultVisualizationFilter,
} from "../../../store/filterSlice";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9",
    },
    background: {
      default: "#121212",
      paper: "#1e1e1e",
    },
    text: {
      primary: "#ffff",
    },
  },
});

const ClusterCard: React.FC = () => {
  const { authState } = useOktaAuth();
  const {
    data: clusterApiData,
    isLoading: isClusterApiDataLoading,
    isSuccess,
  } = useForecastClusterFilter(authState?.accessToken?.accessToken!, "cluster");
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isSuccess) {
      let itemKey = "clusters" as keyof FilterState;

      dispatch(
        setForecastFilter({
          key: itemKey,
          value: clusterApiData?.clusters,
        })
      );
      dispatch(setRcaFilter({ key: itemKey, value: clusterApiData?.clusters }));
      dispatch(
        setResultVisualizationFilter({
          key: itemKey,
          value: clusterApiData?.clusters,
        })
      );
    }
  }, [isSuccess]);

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="p-2 bg-secondary-bg rounded h-64">
        <h3 className="m-2 p-1 font-bold">Cluster</h3>
        <div className="p-2">
          {isClusterApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <MultipleSelectForecast
              width={"100%"}
              itemDisplayCount={3}
              itemList={clusterApiData?.clusters}
              itemType="clusters"
            />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ClusterCard;
