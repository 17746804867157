import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";

interface SeriesData {
  name: string;
  type: "column" | "line";
  data: number[];
  color: string;
}

interface LineColumnChartProps {
  seriesData?: SeriesData[];
  columnWidth?: string;
  labels?: string[];
}

const LineColumnChart: React.FC<LineColumnChartProps> = ({
  seriesData = [],
  columnWidth = "50%",
  labels = [],
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (chartRef.current) {
      const options = {
        series: seriesData.length > 0 ? seriesData : [{ name: "Default", data: [], type: "line", color: "#fff" }],
        chart: {
          height: 250,
          type: "line",
          background: "#1D1D1D",
        },
        plotOptions: {
          bar: {
            columnWidth: columnWidth,
            borderRadius: 3,
            borderRadiusApplication: "top",
          },
        },
        stroke: {
          width: [0, 2],
          curve: "straight",
        },
        xaxis: {
          categories: labels.length > 0 ? labels : ["N/A"],
        },
        yaxis: [
          {
            labels: {
              style: {
                colors: "white",
              },
              formatter: (value: number) => `${value}%`,
            },
            title: {
              text: "Statistical Forecast",
            },
            opposite: true,
          },
          {
            title: {
              text: "Forecast Accuracy",
            },
          },
        ],
        theme: {
          mode: "dark",
        },
        grid: {
          borderColor: "#616161",
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          labels: {
            colors: "white",
          },
          markers: {
            shape: "circle",
            strokeWidth: 0,
            offsetX: -2,
          },
        },
      };

      try {
        const chart = new ApexCharts(chartRef.current, options);
        chart.render();

        return () => {
          chart.destroy();
        };
      } catch (error) {
        console.error("Error initializing chart:", error);
      }
    }
  }, [seriesData, columnWidth, labels]);

  return <div className="pt-3" ref={chartRef}></div>;
};

export default LineColumnChart;
