import ResultVisualizationPredefinedFilter from "../../components/ResultVisualizationFilter/ResultVisualizationPredefinedFilter";
import ClusterCard from "../StatisticalForecastAnalysis/Clustercard/clusterCard";
import TimeCardComponent from "../StatisticalForecastAnalysis/Timecard/TimeCardComponent";
import ComparisonResultPredefinedRuns from "./ComparisonResultPredefinedRuns";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useEffect } from "react";
import ResultVisulizationBacktestingResults from "./ResultVisulizationBacktestingResults";

const PredefinedRuns = () => {
  const overallAverage = useSelector(
    (state: RootState) => state.resultVisualizationData.average
  );

  return (
    <>
      <div className="flex space-x-3 mt-5">
        <div style={{ width: '500px' }}>
          <TimeCardComponent lag={false} />
        </div>
        <div className="flex-auto">
          <ClusterCard />
        </div>
      </div>
      <ResultVisualizationPredefinedFilter />
      <ComparisonResultPredefinedRuns data={overallAverage} />
      <ResultVisulizationBacktestingResults widthColumn="12%" isResultVisulizationPage={true} />
    </>
  );
};

export default PredefinedRuns;
