// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginDivHolder{
    height: 100vh;
    width: 100vw;
}

.loginDiv{
    border: 1px solid #272A31;
    border-radius: 16px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 35px;
    padding-right: 35px;
}

.loginButton{
    background-color: #0069D7;
    border-radius: 50px;
    padding: 10px;
    width: 456px;
    height: 40px;
}

.loginButton:hover{
    cursor: pointer;
}


.loginHelpButton{
    border: 1px solid #0069D7;
    border-radius: 50px;
    padding: 10px;
    width: 456px;
    height: 40px;
}

.loginHelpButton:hover{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".loginDivHolder{\n    height: 100vh;\n    width: 100vw;\n}\n\n.loginDiv{\n    border: 1px solid #272A31;\n    border-radius: 16px;\n    padding-top: 30px;\n    padding-bottom: 30px;\n    padding-left: 35px;\n    padding-right: 35px;\n}\n\n.loginButton{\n    background-color: #0069D7;\n    border-radius: 50px;\n    padding: 10px;\n    width: 456px;\n    height: 40px;\n}\n\n.loginButton:hover{\n    cursor: pointer;\n}\n\n\n.loginHelpButton{\n    border: 1px solid #0069D7;\n    border-radius: 50px;\n    padding: 10px;\n    width: 456px;\n    height: 40px;\n}\n\n.loginHelpButton:hover{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
