import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { RootState } from "../../store/store";
import { resetForecastState } from "../../store/filterSlice";
import MultiInputSelect from "../../baseComponent/inputSelectType/multiInputSelectForecast";
import "./ForecastAnalysisFilter.css";
import {
  useItemsApiData,
  useCusomtersApiData,
  useSalesGeoApiData,
  usePfeApiData,
} from "../../api/filtersApi";
import loadingSvg from "../../images/bouncing-circles.svg";
import { Box, Modal, Typography } from "@mui/material";
import {
  ChartData,
  foreCastBackTestingApiDataLoading,
  foreCastBackTestingAverageDataSetter,
  foreCastBackTestingChartDataSetter,
  foreCastBackTestingTableDataSetter,
  resetForecastBackTestingApiDataLoading,
  resetForecastBackTestingAverageState,
  resetForecastBackTestingChartState,
  resetForecastBackTestingTableState,
  updateBackTestingTotalPages
} from "../../store/foreCastBackTestingDataSlice";
import {
  resetForecastDecomState,
  setForecastData,
  updateBackDecompositionTotalPages,
} from "../../store/foreCastDecomSlice";
import {
  resetForecastData,
  setChartData,
  setDynamicTableHeader,
  setHolidayIndicators,
  setPageLoadingState,
  setTableRowData,
  updateForecastAnalysisTotalPages
} from "../../store/foreCastDataSlice";
import NotificationBar from "../../baseComponent/NotificationBar/NotificationBar";
import {
  calculateTotalPages,
  forecastAnalysisChartApi,
  forecastAnalysisDefaultGroupBy,
  forecastAnalysisHolidaysApi,
  forecastAnalysisTableApi,
  forecastBackTestingAverageApi,
  forecastBackTestingChartApi,
  forecastBackTestingDefaultGroupBy,
  forecastBackTestingTableApi,
  forecastDecompositionApi,
  mapperForGroupByLableToKey,
} from "../../api/forecastPageApi";
import { PAGE_LIMIT } from "../../utils/constant";

const style = {
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  borderRadius: "10px",
  bgcolor: "#1D1D1D",
  border: "8px solid #3A3A3A",
  boxShadow: 24,
  p: 4,
};

interface ForecastAnalysisFilterType {
  pageLoadingHandler: (data: boolean) => void;
}

const ForecastAnalysisFilter: React.FC<ForecastAnalysisFilterType> = ({
  pageLoadingHandler,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showNotification, setHideNotification] = useState<boolean>(false);

  const { authState } = useOktaAuth();
  const dispatch = useDispatch();
  const filter = useSelector((state: RootState) => state.forecastFilter);
  const backTestingPagination = useSelector(
    (state: RootState) => state.foreCastBackTestingPagination
  );
  const forecastAnalysisPaginationData = useSelector(
    (state: RootState) => state.forecastAnalysisPaginationData
  );
  const foreCastBackTestingTableData = useSelector(
    (state: RootState) => state.foreCastBackTestingTableDataSlice
  );

  const foreCastDecompositionPagination = useSelector(
    (state: RootState) => state.foreCastDecompositionPaginationData
  );

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const payloadFilters = {
    shortTermMidTermCode: filter?.term.toString(),
    snapshotDate: filter?.midSnapshotDate ? filter?.shortSnapshotDate : "",
    brandName: filter?.brand,
    subBrandName: filter?.subBrand,
    productLineName: filter?.productLine,
    productGroupName: filter?.productGroup,
    packageSizeName: filter?.size,
    productCategoryName: filter?.category,
    portfolioGroupName: filter?.mg4PortfolioGroup,
    packageName: filter?.container,
    caseCountValue: filter?.count,
    customerGlobalName: filter?.global,
    customerSectorNumber: filter?.customerSector,
    customerRegionName: filter?.customerRegion,
    customerGroupName: filter?.customerGroup,
    customerBusinessUnitName: filter?.businessUnit,
    customerMarketUnitName: filter?.marketUnit,
    ibpDemandGroup: filter?.ibpDemandGroup,
    customerTradeSegmentCode: filter?.cg1TrageSegment,
    salesSectorName: filter?.salesSector,
    salesOrganizationCode: filter?.businessOrg,
    salesDivisionName: filter?.division,
    forecastEngineClusterId: filter?.clusters,
    pageNumber: 1,
    pageSize: PAGE_LIMIT,
    // segment
    // Ac1/AC2
    // recipe runs
    // country
    // IBPDemandGroup
    // PPG
    // planningItem
  };

  const backtestingPayloadFilters = {
    shortTermMidTermCode: filter?.term.toString(),
    snapshotDate: [],
    brandName: filter?.brand,
    subBrandName: filter?.subBrand,
    productLineName: filter?.productLine,
    productGroupName: filter?.productGroup,
    packageSizeName: filter?.size,
    productCategoryName: filter?.category,
    portfolioGroupName: filter?.mg4PortfolioGroup,
    packageName: filter?.container,
    caseCountValue: filter?.count,
    customerGlobalName: filter?.global,
    customerSectorNumber: filter?.customerSector,
    customerRegionName: filter?.customerRegion,
    customerGroupName: filter?.customerGroup,
    customerBusinessUnitName: filter?.businessUnit,
    customerMarketUnitName: filter?.marketUnit,
    ibpDemandGroup: filter?.ibpDemandGroup,
    customerTradeSegmentCode: filter?.cg1TrageSegment,
    salesSectorName: filter?.salesSector,
    salesOrganizationCode: filter?.businessOrg,
    salesDivisionName: filter?.division,
    forecastEngineClusterId: filter?.clusters,
    pageNumber: 1,
    pageSize: PAGE_LIMIT,
  };

  const { data: itemsApiData, isLoading: isItemsApiDataLoading } =
    useItemsApiData(authState?.accessToken?.accessToken!, "items");
  const { data: customersApiData, isLoading: isCustomersApiDataLoading } =
    useCusomtersApiData(authState?.accessToken?.accessToken!, "customers");
  const { data: salesGeoApiData, isLoading: isSalesGeoApiDataLoading } =
    useSalesGeoApiData(authState?.accessToken?.accessToken!, "salesgeo");
  const { data: pfeApiData, isLoading: ispfeApiDataLoading } = usePfeApiData(
    authState?.accessToken?.accessToken!,
    "pfe"
  );

  const resetFilter = () => {
    dispatch(resetForecastState());
    dispatch(resetForecastData())
    dispatch(resetForecastDecomState());
    dispatch(resetForecastBackTestingTableState());
    dispatch(resetForecastBackTestingChartState());
    dispatch(resetForecastBackTestingAverageState());
    dispatch(resetForecastBackTestingApiDataLoading());
    handleClose();
  };

  useEffect(() => {
    if (backTestingPagination?.totalPages > 1 && backTestingPagination?.isDataShownClicked === false) {
      const backTestingGroupByOptions = mapperForGroupByLableToKey(
        backTestingPagination.groupBySelectedField
      );
      const forecastBackTestingApiRequestBody = {
        ...backtestingPayloadFilters,
        pageNumber: backTestingPagination?.pageNumber,
        pageSize: backTestingPagination?.pageLimit,
        groupBy: backTestingGroupByOptions?.selectedGroupByOptions,
      };
      dispatch(
        foreCastBackTestingTableDataSetter({
          isTableDataLoading: true,
          backTestingTableData: foreCastBackTestingTableData?.backTestingTableData,
          tableHeader: foreCastBackTestingTableData?.tableHeader,
        })
      );
      forecastBackTestingTableApi(forecastBackTestingApiRequestBody, authState)
        .then((response) => {
          const totalBackTestingPages = calculateTotalPages(
            PAGE_LIMIT,
            response?.totalCount
          );

          dispatch(
            updateBackTestingTotalPages({
              totalPages: totalBackTestingPages,
              pageLimit: backTestingPagination.pageLimit,
              pageNumber: backTestingPagination.pageNumber,
              isDataShownClicked: false
            })
          );
          dispatch(
            foreCastBackTestingTableDataSetter({
              tableHeader: response?.dynamicTableHeader,
              backTestingTableData: response?.dfuBasedGroupedData,
              isTableDataLoading: false,
            })
          );
        })
        .catch((error) => {
          console.log("forecastBackTestingTableApi error", error);
        });
    }
  }, [backTestingPagination?.pageNumber]);

  useEffect(() => {
    if (forecastAnalysisPaginationData?.totalPages > 1 && forecastAnalysisPaginationData?.isDataShownClicked === false) {
      dispatch(setPageLoadingState(true));
      const forecastAnalysisGroupByOptions = mapperForGroupByLableToKey(
        forecastAnalysisPaginationData?.groupBySelectedField
      );

      const forecastAnalysisApiRequestBody = {
        ...payloadFilters,
        pageNumber: forecastAnalysisPaginationData?.pageNumber,
        pageSize: forecastAnalysisPaginationData?.pageLimit,
        groupBy: forecastAnalysisGroupByOptions?.selectedGroupByOptions,
      };

      forecastAnalysisTableApi(forecastAnalysisApiRequestBody, authState)
        .then((response) => {
          const totalBackTestingPages = calculateTotalPages(
            PAGE_LIMIT,
            response?.totalCount
          );
          dispatch(
            updateForecastAnalysisTotalPages({
              totalPages: totalBackTestingPages,
              pageLimit: forecastAnalysisPaginationData.pageLimit,
              pageNumber: forecastAnalysisPaginationData.pageNumber,
              isDataShownClicked: false
            })
          );
          dispatch(setTableRowData(response?.dfuBasedGroupedData));
          dispatch(setDynamicTableHeader(response?.dynamicTableHeader));
          dispatch(setPageLoadingState(false));
        })
        .catch((error) => {
          console.log("forecastBackTestingTableApi error", error);
          dispatch(setPageLoadingState(false));
        });
    }
  }, [forecastAnalysisPaginationData?.pageNumber]);

  useEffect(() => {
    if (foreCastDecompositionPagination?.totalPages > 1) {
      makeForecastAnalsisDecompostionApiCall(payloadFilters, authState);
    }
  }, [foreCastDecompositionPagination?.pageNumber]);

  const applyFilters = async () => {
    pageLoadingHandler(true);
    dispatch(
      foreCastBackTestingApiDataLoading({
        isDataLoading: true,
      })
    );

    makeForecastAnalysisApiCall(payloadFilters, authState);
    makeBackTesingApiCall(backtestingPayloadFilters, authState);
    makeForecastAnalsisDecompostionApiCall(payloadFilters, authState);
  };

  const makeForecastAnalsisDecompostionApiCall = (payloadFilters: any, authState: any) => {
    dispatch(setForecastData({
      loadingDecomposition: true,
      chartData: {
        range: {
          start: 0,
          end: 0,
        },
      },
      displayNames: {},
      data: [],

    }));
    const foreCastDecompositionRequestBody = {
      ...payloadFilters,
      pageNumber: foreCastDecompositionPagination?.pageNumber,
      pageSize: foreCastDecompositionPagination?.pageLimit,
      groupBy: [],
    };
    forecastDecompositionApi(foreCastDecompositionRequestBody, authState).then((response) => {
      const totalBackTestingPages = calculateTotalPages(
        PAGE_LIMIT,
        response?.totalCount
      );
      dispatch(
        updateBackDecompositionTotalPages({
          totalPages: totalBackTestingPages,
          pageLimit: foreCastDecompositionPagination.pageLimit,
          pageNumber: foreCastDecompositionPagination.pageNumber,
          isDataShownClicked: foreCastDecompositionPagination?.isDataShownClicked
        })
      );
      dispatch(setForecastData({
        loadingDecomposition: false,
        chartData: response?.chartData,
        displayNames: response?.displayNames,
        data: response?.data
      }));
      pageLoadingHandler(false);
    })
      .catch((error) => {
        console.log("forecastBackTestingTableApi error", error);
        dispatch(setForecastData({
          loadingDecomposition: true,
          chartData: {
            range: {
              start: 0,
              end: 0,
            },
          },
          displayNames: {},
          data: [],

        }));
        pageLoadingHandler(false);
      });
  }

  const makeForecastAnalysisApiCall = (payloadFilters: any, authState: any) => {
    dispatch(setPageLoadingState(true));
    dispatch(setChartData([]));
    dispatch(setTableRowData([]));
    dispatch(setDynamicTableHeader([]));
    dispatch(setHolidayIndicators({}));

    const forecastAnalysisGroupByOptions = mapperForGroupByLableToKey(
      forecastAnalysisDefaultGroupBy
    );

    const forecastAnalysisApiRequestBody = {
      ...payloadFilters,
      pageNumber: backTestingPagination?.pageNumber,
      pageSize: backTestingPagination?.pageLimit,
      groupBy: forecastAnalysisGroupByOptions?.selectedGroupByOptions,
    };


    forecastAnalysisTableApi(forecastAnalysisApiRequestBody, authState)
      .then((response) => {
        const totalBackTestingPages = calculateTotalPages(
          PAGE_LIMIT,
          response?.totalCount
        );
        dispatch(
          updateForecastAnalysisTotalPages({
            totalPages: totalBackTestingPages,
            pageLimit: forecastAnalysisPaginationData.pageLimit,
            pageNumber: forecastAnalysisPaginationData.pageNumber,
            isDataShownClicked: forecastAnalysisPaginationData?.isDataShownClicked
          })
        );
        dispatch(setTableRowData(response?.dfuBasedGroupedData));
        dispatch(setDynamicTableHeader(response?.dynamicTableHeader));
        dispatch(setPageLoadingState(false));
      })
      .catch((error) => {
        console.log("forecastBackTestingTableApi error", error);
        dispatch(setPageLoadingState(false));
      });
    forecastAnalysisChartApi(forecastAnalysisApiRequestBody, authState)
      .then((response) => {
        dispatch(setChartData(response?.chartData));
      })
      .catch((error) => {
        console.log("forecastBackTestingChartApi error", error);
      });

    forecastAnalysisHolidaysApi(forecastAnalysisApiRequestBody, authState)
      .then((response) => {
        dispatch(setHolidayIndicators(response?.holidayIndicators || {}));
      })
      .catch((error) => {
        console.log("forecastAnalysisHolidaysApi error", error);
      });
  };

  const makeBackTesingApiCall = (payloadFilters: any, authState: any) => {
    dispatch(
      foreCastBackTestingTableDataSetter({
        isTableDataLoading: true,
        backTestingTableData: [],
        tableHeader: [],
      })
    );
    dispatch(
      foreCastBackTestingChartDataSetter({
        backTestingChartLabels: [],
        chartData: [],
        isChartDataLoading: true,
      })
    );
    dispatch(
      foreCastBackTestingAverageDataSetter({
        overallAverage: {
          accuracy: 0,
          bias: 0,
          historicalShipments: 0,
          statisticalForecast: 0,
        },
        overallPercent: [],
        isAverageDataLoading: true,
      })
    );
    const backTestingGroupByOptions = mapperForGroupByLableToKey(
      forecastBackTestingDefaultGroupBy
    );
    const forecastBackTestingApiRequestBody = {
      ...backtestingPayloadFilters,
      pageNumber: backTestingPagination?.pageNumber,
      pageSize: backTestingPagination?.pageLimit,
      groupBy: backTestingGroupByOptions.selectedGroupByOptions,
    };

    forecastBackTestingTableApi(forecastBackTestingApiRequestBody, authState)
      .then((response) => {
        const totalBackTestingPages = calculateTotalPages(
          PAGE_LIMIT,
          response?.totalCount
        );
        dispatch(
          updateBackTestingTotalPages({
            totalPages: totalBackTestingPages,
            pageLimit: backTestingPagination.pageLimit,
            pageNumber: backTestingPagination.pageNumber,
            isDataShownClicked: backTestingPagination.isDataShownClicked
          })
        );
        dispatch(
          foreCastBackTestingTableDataSetter({
            tableHeader: response?.dynamicTableHeader,
            backTestingTableData: response?.dfuBasedGroupedData,
            isTableDataLoading: false,
          })
        );
      })
      .catch((error) => {
        console.log("forecastBackTestingTableApi error", error);
      });
    forecastBackTestingChartApi(forecastBackTestingApiRequestBody, authState)
      .then((response) => {
        const backTestingChartLable = response?.chartData?.map(
          (data: ChartData) => data.periodWeekNumber
        );
        dispatch(
          foreCastBackTestingChartDataSetter({
            chartData: response?.chartData,
            backTestingChartLabels: backTestingChartLable,
            isChartDataLoading: false,
          })
        );
      })
      .catch((error) => {
        console.log("forecastBackTestingChartApi error", error);
      });
    forecastBackTestingAverageApi(forecastBackTestingApiRequestBody, authState)
      .then((response) => {
        dispatch(
          foreCastBackTestingAverageDataSetter({
            overallAverage: response?.overallAverage,
            overallPercent: response?.overallPercent,
            isAverageDataLoading: false,
          })
        );
      })
      .catch((error) => {
        console.log("forecastBackTestingAverageApi error", error);
      });
  };

  const handleNotificationCloseEvent = (hideNotification: boolean) => {
    setHideNotification(hideNotification);
  };

  return (
    <div className="p-5 bg-secondary-bg mt-4 rounded">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            style={{ fontWeight: "bold", fontSize: "28px", color: "white" }}
          >
            Are you sure you want to reset filters?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 1, color: "white" }}
          >
            This cannot be undone and you will loose all you filters selections
          </Typography>
          <div className="flex w-full justify-end mt-6">
            <button
              onClick={handleClose}
              className="cancelFilterResetModalButton"
            >
              Cancel
            </button>
            <button onClick={resetFilter} className="resetFilterModalButton">
              Reset Filters
            </button>
          </div>
        </Box>
      </Modal>
      <div className="flex justify-between mb-4">
        <span className="text-lg font-bold mb-4 ">Filters</span>
        <div className="flex">
          <button onClick={handleOpen} className="resetFilterButton">
            Reset Filters
          </button>
          <button onClick={applyFilters} className="applyFilterButton">
            Apply Filters
          </button>
        </div>
      </div>

      <NotificationBar
        text=" The filters you have applied do not have any data that can be shown,
        please try again with different filters"
        shouldNotificationVisible={showNotification}
        onCloseNotification={handleNotificationCloseEvent}
      />

      <div className="flex flex-row">
        <div className="basis-3/4 ">
          <h6 className="text-lg">Item</h6>
          {isItemsApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="grid grid-cols-3 pt-3">
              <div>
                <div className="pb-2 select-label">Category</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={itemsApiData?.categories}
                  itemType="category"
                />
                <div className="pb-2 mt-4 select-label">Product Line</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={itemsApiData?.productLines}
                  itemType="productLine"
                />
                <div className="pb-2 mt-4 select-label">Product Group</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={itemsApiData?.productGroups}
                  itemType="productGroup"
                />
                <div className="pb-2 mt-4 select-label">
                  MG4 Portfolio Group
                </div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={itemsApiData?.mg4PortfolioGroups}
                  itemType="mg4PortfolioGroup"
                />
              </div>
              <div>
                <div className="pb-2 select-label">Brand</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={itemsApiData?.brands}
                  itemType="brand"
                />
                <div className="pb-2 mt-4 select-label">Sub Brand</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={itemsApiData?.subBrands}
                  itemType="subBrand"
                />
                <div className="pb-2 mt-4 select-label">Container</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={itemsApiData?.containers}
                  itemType="container"
                />
                <div className="pb-2 mt-4 select-label">PPG</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={itemsApiData?.ppg}
                  itemType="ppg"
                />
              </div>
              <div>
                <div className="pb-2 select-label">Size</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={itemsApiData?.size}
                  itemType="size"
                />
                <div className="pb-2 mt-4 select-label">Count</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={itemsApiData?.count}
                  itemType="count"
                />
                <div className="pb-2 mt-4 select-label">Planning Item</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={itemsApiData?.planningItems}
                  itemType="planningItem"
                />
              </div>
            </div>
          )}
        </div>
        <div className="basis-2/4">
          <h6 className="text-lg">Customer</h6>
          {isCustomersApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="grid grid-cols-2 pt-3">
              <div>
                <div className="pb-2 select-label">Key Account</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={customersApiData?.keyAccount}
                  itemType="keyAccount"
                />
                <div className="pb-2 mt-4 select-label">Global</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={customersApiData?.global}
                  itemType="global"
                />
                <div className="pb-2 mt-4 select-label">Sector</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={customersApiData?.sector}
                  itemType="customerSector"
                />
                <div className="pb-2 mt-4 select-label">Region</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={customersApiData?.regions}
                  itemType="customerRegion"
                />
                <div className="pb-2 mt-4 select-label">Customer Group</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={customersApiData?.customerGroups}
                  itemType="customerGroup"
                />
              </div>
              <div>
                <div className="pb-2 select-label">Business Unit</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={customersApiData?.businessUnits}
                  itemType="businessUnit"
                />
                <div className="pb-2 mt-4 select-label">Market Unit</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={customersApiData?.marketUnits}
                  itemType="marketUnit"
                />
                <div className="pb-2 mt-4 select-label">IBP Demand Group</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={customersApiData?.ibpDemandGroup}
                  itemType="ibpDemandGroup"
                />
                <div className="pb-2 mt-4 select-label">CGI Trade Segment</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={customersApiData?.cgiTradeSegment}
                  itemType="cg1TrageSegment"
                />
              </div>
            </div>
          )}
        </div>
        <div className="basis-1/4">
          <h6 className="text-lg">Sales Geo</h6>
          {isSalesGeoApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="grid grid-cols-1 pt-3">
              <div>
                <div className="pb-2 select-label">Sector</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={salesGeoApiData?.sector}
                  itemType="salesSector"
                />
                <div className="pb-2 mt-4 select-label">Country</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={salesGeoApiData?.countries}
                  itemType="country"
                />
                <div className="pb-2 mt-4 select-label">Sales Region</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={salesGeoApiData?.salesRegion}
                  itemType="salesRegion"
                />
                <div className="pb-2 mt-4 select-label">Sales Business Org</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={salesGeoApiData?.salesBusinessOrg}
                  itemType="businessOrg"
                />
                <div className="pb-2 mt-4 select-label">Sales Division</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={salesGeoApiData?.salesDivision}
                  itemType="division"
                />
              </div>
            </div>
          )}
        </div>
        <div className="basis-1/4">
          <h6 className="text-lg">PFE</h6>
          {ispfeApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="grid grid-cols-1 pt-3">
              <div>
                <div className="pb-2 select-label">Segment</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={pfeApiData?.segment}
                  itemType="segment"
                />
                <div className="pb-2 mt-4 select-label">AC1/AC2</div>
                <MultiInputSelect
                  width={"90%"}
                  itemDisplayCount={1}
                  itemList={pfeApiData?.acOneTwo}
                  itemType="ac1ac2"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForecastAnalysisFilter;
