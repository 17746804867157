import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { resetResultVisualizationExpState } from "../../store/filterSlice";
import MultipleSelectVisualizationExp from "../../baseComponent/inputSelectType/multiInputSelectExperimentation";
import {
  useCusomtersApiData,
  useForecastClusterFilter,
  useItemsApiData,
  usePfeApiData,
  useSalesGeoApiData,
} from "../../api/filtersApi";
import loadingSvg from "../../images/bouncing-circles.svg";
import { Box, Modal, Typography } from "@mui/material";
import { RootState } from "../../store/store";
import {
  calculateTotalPages,
  forecastAnalysisHolidaysApi,
  forecastBackTestingDefaultGroupBy,
  forwardChartAPI,
  mapperForGroupByLableToKey,
  resultVisualisationChartExperimentaionApi,
  resultVisualisationChartExperimentaionForwardForecastTableApi,
  resultVisualisationChartExperimentaionTableApi,
  resultVisualisationFilterExperimentaionApi,
  resultVisualizationAverageExperimentaitonApi,
  resultVisulizationExperimentDefaultGroupBy,
} from "../../api/forecastPageApi";
import {
  resetExperId,
  resetFFChartState,
  resetResultVisulizationExperimentBackTestingTableDataSetter,
  resetResultVisulizationExperimentForwardForecastDataSetter,
  resetStateExp,
  resultVisulizationExperimentBackTestingTableDataSetter,
  resultVisulizationExperimentForwardForecastDataSetter,
  setChartData,
  setExperimentationData,
  setExperimentId,
  setHolidaysData,
  setLoader,
  setProductionData,
  setPromotionImpactData,
  updateExperinemtBackTestingTotalPages,
  updateExperinemtForewardForecastTotalPages,
} from "../../store/resultVisualizationExperimentation";
import { PAGE_LIMIT } from "../../utils/constant";

const style = {
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  borderRadius: "10px",
  bgcolor: "#1D1D1D",
  border: "8px solid #3A3A3A",
  boxShadow: 24,
  p: 4,
};

const ResultVisualizationExperimentalFilter: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();

  const filter = useSelector(
    (state: RootState) => state.resultVisualizationExpFilter
  );

  const resultVisulizationExperimentBackTestingPagination = useSelector(
    (state: RootState) =>
      state.resultVisulizationExperimentBackTestingPagination
  );

  const resultVisulizationForewardForecastPagination = useSelector(
    (state: RootState) => state.resultVisulizationForewardForecastPagination
  );

  const { data: clusterApiData, isLoading: isClusterApiDataLoading } =
    useForecastClusterFilter(authState?.accessToken?.accessToken!, "cluster");

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    resetFilter();
  }, []);

  const hoildayPayload = {
    shortTermMidTermCode: filter?.term.toString(),
    isFFHolidays: true,
    experimentId: filter?.experimentId,
  };

  const payloadForLag = {
    shortTermMidTermCode: filter?.term.toString(),
    experimentId: filter?.experimentId,
  };

  const backtestingPayloadFilters = {
    shortTermMidTermCode: filter?.term.toString(),
    // snapshotDate:
    //   filter?.term.toString() === "ST"
    //     ? filter?.shortSnapshotDate
    //     : filter?.midSnapshotDate,
    brandName: filter?.brand,
    subBrandName: filter?.subBrand,
    productLineName: filter?.productLine,
    productGroupName: filter?.productGroup,
    packageSizeName: filter?.size,
    keyAccount: filter?.keyAccount,
    materialGlobalTradeItemNumberDescription: filter?.planningItem,
    productCategoryName: filter?.category,
    portfolioGroupName: filter?.mg4PortfolioGroup,
    packageName: filter?.count,
    packTypeName: filter.ppg,
    experimentId: filter?.experimentId,
    customerGlobalName: filter?.global,
    customerSectorNumber: filter?.customerSector,
    customerRegionName: filter?.customerRegion,
    customerGroupName: filter?.customerGroup,
    customerBusinessUnitName: filter?.businessUnit,
    customerMarketUnitName: filter?.marketUnit,
    ibpDemandGroup: filter?.ibpDemandGroup,
    customerTradeSegmentDescription: filter?.cg1TrageSegment,
    salesSectorName: filter?.salesSector,
    salesOrganizationCode: filter?.businessOrg,
    salesCountryCode: filter?.country,
    salesDivisionName: filter?.division,
    salesRegionName: filter?.salesRegion,
    forecastModuleCode: filter?.ac1ac2,
    packageTypeName: filter?.container,
    forecastEngineSegmentationId: filter?.segment,
    forecastEngineClusterId: filter?.clusters,
    pageNumber: 1,
    pageSize: PAGE_LIMIT,
  };

  const payload = {
    ...backtestingPayloadFilters,

    forecastLagNumber: parseInt(filter?.lagNumber[0] || "0", 10),
  };

  const forwardChartPayload = {
    ...backtestingPayloadFilters,
    rank: filter?.rankForward?.map(Number),
  };

  const backTestingChartPayload = {
    ...payload,
    rank: filter?.rankBackTestingChart.map(Number),
  };

  const { data: itemsApiData, isLoading: isItemsApiDataLoading } =
    useItemsApiData(authState?.accessToken?.accessToken!, "items");
  const { data: customersApiData, isLoading: isCustomersApiDataLoading } =
    useCusomtersApiData(authState?.accessToken?.accessToken!, "customers");
  const { data: salesGeoApiData, isLoading: isSalesGeoApiDataLoading } =
    useSalesGeoApiData(authState?.accessToken?.accessToken!, "salesgeo");
  const { data: pfeApiData, isLoading: ispfeApiDataLoading } = usePfeApiData(
    authState?.accessToken?.accessToken!,
    "pfe"
  );

  useEffect(() => {
    if (
      resultVisulizationExperimentBackTestingPagination?.totalPages > 1 &&
      resultVisulizationExperimentBackTestingPagination?.isDataShownClicked ===
        false
    ) {
      makeExperimentBackTesingApiCall(backtestingPayloadFilters, authState);
    }
  }, [resultVisulizationExperimentBackTestingPagination?.pageNumber]);

  useEffect(() => {
    if (resultVisulizationForewardForecastPagination?.totalPages > 1) {
      makeExperimentForewardForecastApiCall(
        backtestingPayloadFilters,
        authState
      );
    }
  }, [resultVisulizationForewardForecastPagination?.pageNumber]);

  const handleApplyFilters = async () => {
    resultVisualisationFilterExperimentaionApi(payloadForLag, authState).then(
      (data) => dispatch(setExperimentId(data))
    );
    resultVisualizationAverageExperimentaitonApi(
      { ...payload, rank: filter?.rankComparision.map(Number) },
      authState
    ).then((data) => {
      dispatch(setExperimentationData(data?.experimentData));
      dispatch(setProductionData(data?.productionData));
    });
    forwardChartAPI(forwardChartPayload, authState).then((data) =>
      dispatch(setPromotionImpactData(data?.chartData))
    );
    forecastAnalysisHolidaysApi(hoildayPayload, authState).then((data) =>
      dispatch(setHolidaysData(data?.holidayIndicators))
    );
    resultVisualisationChartExperimentaionApi(
      backTestingChartPayload,
      authState
    ).then((data) => dispatch(setChartData(data?.chartData)));
    makeExperimentBackTesingApiCall(backtestingPayloadFilters, authState);
    makeExperimentForewardForecastApiCall(backtestingPayloadFilters, authState);
  };
  const resetFilter = () => {
    dispatch(resetExperId());
    dispatch(resetResultVisualizationExpState({}));
    dispatch(resetStateExp());
    dispatch(setLoader(false));
    dispatch(resetResultVisulizationExperimentBackTestingTableDataSetter());
    dispatch(resetResultVisulizationExperimentForwardForecastDataSetter());
    dispatch(resetFFChartState());
    handleClose();
  };

  const makeExperimentBackTesingApiCall = (
    resultVisulizationExperiementApiRequestBodyTable: any,
    authState: any
  ) => {
    dispatch(
      resultVisulizationExperimentBackTestingTableDataSetter({
        isTableDataLoading: true,
        backTestingTableData: [],
        tableHeader: [],
      })
    );

    const backTestingGroupByOptions = mapperForGroupByLableToKey(
      resultVisulizationExperimentDefaultGroupBy
    );
    const resultVisulizationTestingApiRequestBody = {
      ...resultVisulizationExperiementApiRequestBodyTable,
      pageNumber: resultVisulizationExperimentBackTestingPagination?.pageNumber,
      pageSize: resultVisulizationExperimentBackTestingPagination?.pageLimit,
      groupBy: backTestingGroupByOptions.selectedGroupByOptions,
      rank: filter?.rankBackTestingChart.map(Number),
    };
    resultVisualisationChartExperimentaionTableApi(
      resultVisulizationTestingApiRequestBody,
      authState
    )
      .then((response) => {
        dispatch(setLoader(true));
        const totalBackTestingPages = calculateTotalPages(
          PAGE_LIMIT,
          response?.totalCount
        );
        dispatch(
          updateExperinemtBackTestingTotalPages({
            totalPages: totalBackTestingPages,
            pageLimit:
              resultVisulizationExperimentBackTestingPagination.pageLimit,
            pageNumber:
              resultVisulizationExperimentBackTestingPagination.pageNumber,
            isDataShownClicked:
              resultVisulizationExperimentBackTestingPagination.isDataShownClicked,
          })
        );
        dispatch(
          resultVisulizationExperimentBackTestingTableDataSetter({
            tableHeader: response?.dynamicTableHeader,
            backTestingTableData: response?.dfuBasedGroupedData,
            isTableDataLoading: false,
          })
        );
        dispatch(setLoader(false));
      })
      .catch((error) => {
        console.log("resultVisulizationTestingTableApi error", error);
      });
  };

  const makeExperimentForewardForecastApiCall = (
    resultVisulizationExperiementApiRequestBodyTable: any,
    authState: any
  ) => {
    dispatch(
      resultVisulizationExperimentForwardForecastDataSetter({
        isTableDataLoading: true,
        backTestingTableData: [],
        tableHeader: [],
      })
    );

    const backTestingGroupByOptions = mapperForGroupByLableToKey(
      forecastBackTestingDefaultGroupBy
    );
    const resultVisulizationTestingApiRequestBody = {
      ...resultVisulizationExperiementApiRequestBodyTable,
      pageNumber: resultVisulizationExperimentBackTestingPagination?.pageNumber,
      pageSize: resultVisulizationExperimentBackTestingPagination?.pageLimit,
      groupBy: backTestingGroupByOptions.selectedGroupByOptions,
    };

    resultVisualisationChartExperimentaionForwardForecastTableApi(
      {
        ...resultVisulizationTestingApiRequestBody,
        rank: filter?.rankForward.map(Number),
      },
      authState
    )
      .then((response) => {
        dispatch(setLoader(true));
        const totalBackTestingPages = calculateTotalPages(
          PAGE_LIMIT,
          response?.totalCount
        );
        dispatch(
          updateExperinemtForewardForecastTotalPages({
            totalPages: totalBackTestingPages,
            pageLimit:
              resultVisulizationExperimentBackTestingPagination.pageLimit,
            pageNumber:
              resultVisulizationExperimentBackTestingPagination.pageNumber,
            isDataShownClicked:
              resultVisulizationExperimentBackTestingPagination.isDataShownClicked,
          })
        );
        dispatch(
          resultVisulizationExperimentForwardForecastDataSetter({
            tableHeader: response?.dynamicTableHeader,
            backTestingTableData: response?.dfuBasedGroupedData,
            isTableDataLoading: false,
          })
        );
        dispatch(setLoader(false));
      })
      .catch((error) => {
        console.log("resultVisulizationTestingTableApi error", error);
      });
  };

  return (
    <div className="p-5 bg-secondary-bg mt-4 rounded">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            style={{ fontWeight: "bold", fontSize: "28px", color: "white" }}
          >
            Are you sure you want to reset filters?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 1, color: "white" }}
          >
            This cannot be undone and you will loose all you filters selections
          </Typography>
          <div className="flex w-full justify-end mt-6">
            <button
              onClick={handleClose}
              className="cancelFilterResetModalButton"
            >
              Cancel
            </button>
            <button onClick={resetFilter} className="resetFilterModalButton">
              Reset Filters
            </button>
          </div>
        </Box>
      </Modal>
      <div className="flex justify-between mb-4">
        <span className="text-lg font-bold mb-4 ">Filters</span>
        <div className="flex">
          <button onClick={handleOpen} className="resetFilterButton">
            Reset Filters
          </button>
          <button onClick={handleApplyFilters} className="applyFilterButton">
            Apply Filters
          </button>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="basis-3/4 ">
          <h6 className="text-lg">Item</h6>
          {isItemsApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="grid grid-cols-3 pt-3">
              <div>
                <div className="pb-2 select-label">Category</div>
                <MultipleSelectVisualizationExp
                  itemList={itemsApiData?.categories}
                  itemType="category"
                />
                <div className="pb-2 mt-4 select-label">Product Line</div>
                <MultipleSelectVisualizationExp
                  itemList={itemsApiData?.productLines}
                  itemType="productLine"
                />
                <div className="pb-2 mt-4 select-label">Product Group</div>
                <MultipleSelectVisualizationExp
                  itemList={itemsApiData?.productGroups}
                  itemType="productGroup"
                />
                <div className="pb-2 mt-4 select-label">
                  MG4 Portfolio Group
                </div>
                <MultipleSelectVisualizationExp
                  itemList={itemsApiData?.mg4PortfolioGroups}
                  itemType="mg4PortfolioGroup"
                />
              </div>
              <div>
                <div className="pb-2 select-label">Brand</div>
                <MultipleSelectVisualizationExp
                  itemList={itemsApiData?.brands}
                  itemType="brand"
                />
                <div className="pb-2 mt-4 select-label">Sub Brand</div>
                <MultipleSelectVisualizationExp
                  itemList={itemsApiData?.subBrands}
                  itemType="subBrand"
                />
                <div className="pb-2 mt-4 select-label">Container</div>
                <MultipleSelectVisualizationExp
                  itemList={itemsApiData?.containers}
                  itemType="container"
                />
                <div className="pb-2 mt-4 select-label">PPG</div>
                <MultipleSelectVisualizationExp
                  itemList={itemsApiData?.ppg}
                  itemType="ppg"
                />
              </div>
              <div>
                <div className="pb-2 select-label">Size</div>
                <MultipleSelectVisualizationExp
                  itemList={itemsApiData?.size}
                  itemType="size"
                />
                <div className="pb-2 mt-4 select-label">Count</div>
                <MultipleSelectVisualizationExp
                  itemList={itemsApiData?.count}
                  itemType="count"
                />
                <div className="pb-2 mt-4 select-label">Planning Item</div>
                <MultipleSelectVisualizationExp
                  itemList={itemsApiData?.planningItems}
                  itemType="planningItem"
                />
              </div>
            </div>
          )}
        </div>
        <div className="basis-2/4">
          <h6 className="text-lg">Customer</h6>
          {isCustomersApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="grid grid-cols-2 pt-3">
              <div>
                <div className="pb-2 select-label">Key Account</div>
                <MultipleSelectVisualizationExp
                  itemList={customersApiData?.keyAccount}
                  itemType="keyAccount"
                />
                <div className="pb-2 mt-4 select-label">Global</div>
                <MultipleSelectVisualizationExp
                  itemList={customersApiData?.global}
                  itemType="global"
                />
                <div className="pb-2 mt-4 select-label">Sector</div>
                <MultipleSelectVisualizationExp
                  itemList={customersApiData?.sector}
                  itemType="customerSector"
                />
                <div className="pb-2 mt-4 select-label">Region</div>
                <MultipleSelectVisualizationExp
                  itemList={customersApiData?.regions}
                  itemType="customerRegion"
                />
                <div className="pb-2 mt-4 select-label">Customer Group</div>
                <MultipleSelectVisualizationExp
                  itemList={customersApiData?.customerGroups}
                  itemType="customerGroup"
                />
              </div>
              <div>
                <div className="pb-2 select-label">Business Unit</div>
                <MultipleSelectVisualizationExp
                  itemList={customersApiData?.businessUnits}
                  itemType="businessUnit"
                />
                <div className="pb-2 mt-4 select-label">Market Unit</div>
                <MultipleSelectVisualizationExp
                  itemList={customersApiData?.marketUnits}
                  itemType="marketUnit"
                />
                <div className="pb-2 mt-4 select-label">IBP Demand Group</div>
                <MultipleSelectVisualizationExp
                  itemList={customersApiData?.ibpDemandGroup}
                  itemType="ibpDemandGroup"
                />
                <div className="pb-2 mt-4 select-label">CGI Trade Segment</div>
                <MultipleSelectVisualizationExp
                  itemList={customersApiData?.cgiTradeSegment}
                  itemType="cg1TrageSegment"
                />
              </div>
            </div>
          )}
        </div>
        <div className="basis-1/4">
          <h6 className="text-lg">Sales Geo</h6>
          {isSalesGeoApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="grid grid-cols-1 pt-3">
              <div>
                <div className="pb-2 select-label">Sector</div>
                <MultipleSelectVisualizationExp
                  itemList={salesGeoApiData?.sector}
                  itemType="salesSector"
                />
                <div className="pb-2 mt-4 select-label">Country</div>
                <MultipleSelectVisualizationExp
                  itemList={salesGeoApiData?.countries}
                  itemType="country"
                />
                <div className="pb-2 mt-4 select-label">Sales Region</div>
                <MultipleSelectVisualizationExp
                  itemList={salesGeoApiData?.salesRegion}
                  itemType="salesRegion"
                />
                <div className="pb-2 mt-4 select-label">Sales Business Org</div>
                <MultipleSelectVisualizationExp
                  itemList={salesGeoApiData?.salesBusinessOrg}
                  itemType="businessOrg"
                />
                <div className="pb-2 mt-4 select-label">Sales Division</div>
                <MultipleSelectVisualizationExp
                  itemList={salesGeoApiData?.salesDivision}
                  itemType="division"
                />
              </div>
            </div>
          )}
        </div>
        <div className="basis-1/4">
          <h6 className="text-lg">PFE</h6>
          {ispfeApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="grid grid-cols-1 pt-3">
              <div>
                <div className="pb-2 select-label">Segment</div>
                <MultipleSelectVisualizationExp
                  itemList={pfeApiData?.segment}
                  itemType="segment"
                />
                <div className="pb-2 mt-4 select-label">AC1/AC2</div>
                <MultipleSelectVisualizationExp
                  itemList={pfeApiData?.acOneTwo}
                  itemType="ac1ac2"
                />
                <div className="pb-2 mt-4 select-label">Cluster</div>
                <MultipleSelectVisualizationExp
                  itemList={clusterApiData?.clusters}
                  itemType="clusters"
                />
                {/* <div className="pb-2 mt-4 select-label">Recipe Runs</div>
                <MultipleSelectVisualizationExp
                  itemList={pfeApiData?.recipeRuns}
                  itemType="recipeRuns"
                /> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultVisualizationExperimentalFilter;
