import { Box, Modal } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BacktestingResultTable from "../../components/BackTesting/BacktestingResultTable/BacktestingResultTable";
import LineColumnChart from "../../components/chart/LineColumn/LineColumnChart";
import { DataShownDialog } from "../../components/Datashow";
import arrowLogo from "../../images/arrow.svg";
import closeButton from "../../images/closeButton.svg";
import enlargeButton from "../../images/enlargeButton.svg";
import { RootState } from "../../store/store";
import loadingSvg from "../../images/bouncing-circles.svg";
import settingsIcon from "../../images/Settings.svg";
import {
  updateBackTestingTotalPages,
  updateBackTestingPageNumber,
  resultVisulizationBackTestingTableDataSetter,
} from "../../store/resultVisualizationBackTesting";
import { Pagination } from "../../components/Pagination";
import {
  calculateTotalPages,
  forecastBackTestingDefaultGroupBy,
  mapperForGroupByLableToKey,
  resultVisualisationTableAPI,
} from "../../api/forecastPageApi";
import { useOktaAuth } from "@okta/okta-react";
import { FIRST_PAGE, PAGE_LIMIT } from "../../utils/constant";
import { BackTestingChartData, BacktestingResultsProps } from "../../store/foreCastBackTestingDataSlice";

interface ChartDataset {
  name: string;
  data: number[];
  color: string;
  type?: string;
}

function ResultVisulizationBacktestingResults({
  widthColumn,
  isResultVisulizationPage,
}: BacktestingResultsProps) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [chartDataPredefined, setChartDataPreDefined] = useState<any>([]);
  const [chartDataPredefinedLabels, setChartDataPreDefinedLabels] =
    useState<any>([]);
  const { authState } = useOktaAuth();
  const [selectedDataShownOptionList, setSelectedDataShownOptionList] =
    useState<string[]>(forecastBackTestingDefaultGroupBy);
  const [selectedDataShownCategoryList, setSelectedDataShownCategoryList] =
    useState<string[]>([]);

  const resultVisulizationBackTestingTableData = useSelector(
    (state: RootState) => state.resultVisulizationBackTestingTableDataSlice
  );

  const foreCastBackTestingDataLoading = useSelector(
    (state: RootState) => state.foreCastBackTestingDataLoading
  );

  const resultVisualizationData = useSelector(
    (state: RootState) => state.resultVisualizationData
  );

  const loader = useSelector(
    (state: RootState) => state.resultVisualizationData
  );


  const [openSettings, setOpenSettings] = useState(false);
  const [backTesingChartData, setBackTestingChartData] =
    useState<BackTestingChartData>({
      backTestingSeriesData: [],
      backTestingChartLabels: [],
    });

  const filter = useSelector((state: RootState) => state.forecastFilter);

  const resultVisulizationBackTestingPaginationDate = useSelector(
    (state: RootState) => state.resultVisulizationBackTestingPagination
  );

  const extractData = (response: any, index: number, key: string) => {
    return response?.[index]?.data?.map((item: any) => item?.[key] ?? null) ?? [];
  };
  const buildLabels = (response: any) => {
    const labels = Array.from(
      new Set(
        response.flatMap((rank: any) =>
          rank?.data?.map((item: any) => item?.periodWeekNumber)
        )
      )
    );

    return labels.sort((a: any, b: any) => {
      const yearA = parseInt(a.match(/\d{4}$/)?.[0] ?? "0", 10);
      const yearB = parseInt(b.match(/\d{4}$/)?.[0] ?? "0", 10);

      const periodA = parseInt(a.match(/P(\d+)/)?.[1] ?? "0", 10);
      const periodB = parseInt(b.match(/P(\d+)/)?.[1] ?? "0", 10);

      const weekA = parseInt(a.match(/W(\d+)/)?.[1] ?? "0", 10);
      const weekB = parseInt(b.match(/W(\d+)/)?.[1] ?? "0", 10);

      if (yearA !== yearB) return yearA - yearB;
      if (periodA !== periodB) return periodA - periodB;
      return weekA - weekB;
    });
  };



  useEffect(() => {
    const createChartData = (
      response: any
    ): { datasets: ChartDataset[]; labels: string[] } => {
      const datasets: ChartDataset[] = [
        {
          name: "Top Recipe",
          data: extractData(response, 0, "accuracy"),
          color: "#B992EB",
          type: "line",
        },
        {
          name: "Rank 2",
          data: extractData(response, 1, "accuracy"),
          color: "#33B1E8",
          type: "line",
        },
        {
          name: "Rank 3",
          data: extractData(response, 2, "accuracy"),
          color: "#994900",
          type: "line",
        },
        {
          name: "Top Recipe Bias",
          data: extractData(response, 0, "bias"),
          color: "#B992EB",
          type: "bar",
        },
        {
          name: "Rank 2 Bias",
          data: extractData(response, 1, "bias"),
          color: "#33B1E8",
          type: "bar",
        },
        {
          name: "Rank 3 Bias",
          data: extractData(response, 2, "bias"),
          color: "#994900",
          type: "bar",
        },
      ];

      const labels: any = buildLabels(response);

      return { datasets, labels };
    }

    if (resultVisualizationData?.chartData?.length > 0) {
      const { datasets, labels } = createChartData(
        resultVisualizationData?.chartData
      );
      setChartDataPreDefined(datasets);
      setChartDataPreDefinedLabels(labels);
    }
  }, [resultVisualizationData?.chartData]);

  const renderResultVisualizationChartHtml = (): ReactElement => {
    return chartDataPredefinedLabels?.length < 0 ? (
      <div className="flex justify-center">
        <img
          src={loadingSvg}
          alt="loading"
          className="mt-10"
          width={40}
          height={40}
        />
      </div>
    ) : (
      chartDataPredefined[0]?.data?.length > 0 ?
        <LineColumnChart
          seriesData={chartDataPredefined}
          columnWidth={"4"}
          labels={chartDataPredefinedLabels}
        /> : <></>
    );
  };

  const handleSettingModal = () => {
    mapperForGroupByLableToKeyHandler(selectedDataShownOptionList);
    setOpenSettings(true);
  };

  // const applyFilterAndRedirect = () => {
  //   dispatch(borrowFiltersFromForecast(filter));
  //   return navigate("/resultvisualization", { replace: true });
  // };

  const renderBackTestingAnalyticsHtml = () => {
    return (
      <>
        {/* {resultVisualizationData?.isAverageDataLoading ? (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        ) : ( */}
        {/* <div className="mb-7">
            <BackTestingAnalytics
              overallAverage={resultVisualizationData?.overallAverage}
              overallPercent={resultVisualizationData?.overallPercent}
            />
          </div> */}
        {/* )} */}
      </>
    );
  };

  // const renderBackTestingChartHtml = (): ReactElement => {
  //   let chartComponent = null;

  //   if (
  //     backTesingChartData?.backTestingSeriesData?.length > 0 &&
  //     backTesingChartData?.backTestingChartLabels?.length > 0
  //   ) {
  //     chartComponent = (
  //       <LineColumnChart
  //         seriesData={backTesingChartData?.backTestingSeriesData}
  //         columnWidth={widthColumn}
  //         labels={backTesingChartData?.backTestingChartLabels}
  //       />
  //     );
  //   }

  //   return (
  //     <>
  //       {foreCastBackTestingChartData?.isChartDataLoading ? (
  //         <div className="flex justify-center">
  //           <img
  //             src={loadingSvg}
  //             alt="loading"
  //             className="mt-10"
  //             width={40}
  //             height={40}
  //           />
  //         </div>
  //       ) : (
  //         chartComponent
  //       )}
  //     </>
  //   );
  // };

  const renderBackTestingTableHtml = (): ReactElement => {
    return (
      <>
        {resultVisulizationBackTestingTableData?.isTableDataLoading ? (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        ) : (
          <BacktestingResultTable
            tableHeader={resultVisulizationBackTestingTableData?.tableHeader}
            chartData={[]}
            backTestingTableData={
              resultVisulizationBackTestingTableData?.backTestingTableData
            }
            isResultVisulizationPage={isResultVisulizationPage}
            resultVisulizationTableData={resultVisualizationData.chartData}
          />
        )}
      </>
    );
  };

  const mapperForGroupByLableToKeyHandler = (
    selectedItemList: string[] = []
  ) => {
    const { selectedGroupByOptions, selectedCategoryType } =
      mapperForGroupByLableToKey(selectedItemList);
    setSelectedDataShownOptionList(selectedItemList);
    setSelectedDataShownCategoryList(selectedCategoryType);

    return selectedGroupByOptions;
  };

  const updateBackTestingTableData = (selectedOptionList: string[]) => {
    const payloadFilters = {
      shortTermMidTermCode: filter?.term.toString(),
      snapshotDate: [],
      brandName: filter?.brand,
      subBrandName: filter?.subBrand,
      productLineName: filter?.productLine,
      productGroupName: filter?.productGroup,
      packageSizeName: filter?.size,
      productCategoryName: filter?.category,
      portfolioGroupName: filter?.mg4PortfolioGroup,
      packageName: filter?.container,
      caseCountValue: filter?.count,
      customerGlobalName: filter?.global,
      customerSectorNumber: filter?.customerSector,
      customerRegionName: filter?.customerRegion,
      customerGroupName: filter?.customerGroup,
      customerBusinessUnitName: filter?.businessUnit,
      customerMarketUnitName: filter?.marketUnit,
      ibpDemandGroup: filter?.ibpDemandGroup,
      customerTradeSegmentCode: filter?.cg1TrageSegment,
      salesSectorName: filter?.salesSector,
      salesOrganizationCode: filter?.businessOrg,
      salesDivisionName: filter?.division,
      forecastEngineClusterId: filter?.clusters,
    };

    const forecastBackTestingApiRequestBody = {
      ...payloadFilters,
      pageNumber: 1,
      pageSize: PAGE_LIMIT,
      groupBy: mapperForGroupByLableToKeyHandler(selectedOptionList),
    };

    setOpenSettings(false);
    dispatch(
      resultVisulizationBackTestingTableDataSetter({
        tableHeader: [],
        backTestingTableData: [],
        isTableDataLoading: true,
      })
    );

    resultVisualisationTableAPI(forecastBackTestingApiRequestBody, authState)
      .then((response) => {
        const totalBackTestingPages = calculateTotalPages(
          PAGE_LIMIT,
          response?.totalCount
        );
        dispatch(
          updateBackTestingTotalPages({
            totalPages: totalBackTestingPages,
            pageLimit: resultVisulizationBackTestingPaginationDate.pageLimit,
            pageNumber: resultVisulizationBackTestingPaginationDate.pageNumber,
            isDataShownClicked: resultVisulizationBackTestingPaginationDate.isDataShownClicked,
          })
        );
        dispatch(
          resultVisulizationBackTestingTableDataSetter({
            tableHeader: response?.dynamicTableHeader,
            backTestingTableData: response?.dfuBasedGroupedData,
            isTableDataLoading: false,
          })
        );
      })
      .catch((error) => {
        console.log("forecastBackTestingTableApi error", error);
      });

  };

  const onModalClosed = () => {
    setOpenSettings(false);
  }

  const renderBackTestingDataShownModalHtml = (): ReactElement => {
    return (
      <>
        <DataShownDialog
          open={openSettings}
          defaultSelectedOptions={selectedDataShownOptionList}
          categoryList={selectedDataShownCategoryList}
          onClose={(itemList) => updateBackTestingTableData(itemList)}
          onModalClosed={onModalClosed}
        />
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute" as const,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "#272727",
              boxShadow: 24,
              borderRadius: 2,
            }}
          >
            <div className="m-4">
              <div className="flex justify-between p-4">
                <h6 className="font-bold text-white">Backtesting Results</h6>
                <button onClick={handleClose}>
                  <img src={closeButton} alt="close" />
                </button>
              </div>
              <div className="bg-ternary-bg rounded m-4">
                {chartDataPredefined?.length > 0 &&
                  <>

                    < LineColumnChart
                      seriesData={chartDataPredefined}
                      columnWidth={"4"}
                      labels={chartDataPredefinedLabels}
                    />
                    <BacktestingResultTable
                      tableHeader={resultVisulizationBackTestingTableData?.tableHeader}
                      chartData={[]}
                      backTestingTableData={
                        resultVisulizationBackTestingTableData?.backTestingTableData
                      }
                      isResultVisulizationPage={isResultVisulizationPage}
                      resultVisulizationTableData={resultVisualizationData.chartData}
                    />
                  </>
                }

              </div>
            </div>
          </Box>
        </Modal>
      </>
    );
  };

  const renderBackTestingHtml = (): ReactElement => {
    const isDataEmpty =
      !foreCastBackTestingDataLoading?.isDataLoading &&
      resultVisualizationData?.average &&
      resultVisulizationBackTestingTableData?.backTestingTableData?.length === 0 &&
      resultVisualizationData?.chartData?.length === 0;

    return isDataEmpty ? (
      loader ? <div className="bg-ternary-bg rounded p-2">
        <div className="flex justify-center">
          <p className="p-2 m-1">
            No Data to show or no options selected, please choose from the above filters.
          </p>
        </div>
      </div> :
        <div className="flex justify-center">
          <img
            src={loadingSvg}
            alt="loading"
            className="mt-10"
            width={40}
            height={40}
          />
        </div>
    ) : (
      <>
        {renderBackTestingAnalyticsHtml()}
        <div className="bg-ternary-bg rounded">
          {renderResultVisualizationChartHtml()}
          {renderBackTestingTableHtml()}
        </div>
        {renderBackTestingDataShownModalHtml()}
        <div className="flex justify-end">
          {/* <div
            // onClick={() => applyFilterAndRedirect()}
            style={{ backgroundColor: "#0069D7", borderRadius: "50px" }}
            className="font-bold text-base px-4 py-2 mt-4 cursor-pointer flex"
          >
            <span>View Alternative Recipes</span>
            <img src={arrowLogo} className="pl-2" alt="arrow" />
          </div> */}
        </div>
      </>
    );
  };

  const handlePageNumberUpdateEvent = (currentPageNumber: number) => {
    dispatch(
      updateBackTestingPageNumber({
        pageLimit: resultVisulizationBackTestingPaginationDate?.pageLimit,
        pageNumber: currentPageNumber,
        totalPages: resultVisulizationBackTestingPaginationDate?.totalPages,
        isDataShownClicked: false,
        groupBySelectedField: selectedDataShownOptionList,
      })
    );
  };

  return (
    <div className="p-5 bg-secondary-bg mt-4 rounded">
      <div className="flex justify-between">
        <div className="flex">
          <h6 className="text-lg font-bold mb-4 mt-2">Backtesting Results</h6>
          <div className="m-2 rounded bg-zinc-600 pt-2">
            {filter?.term.includes('ST') ? <span className="p-1">Lag 4</span> : <span className="p-1">Lag 6</span>}
          </div>
        </div>
        {!foreCastBackTestingDataLoading?.isDataLoading ||
          resultVisulizationBackTestingTableData?.backTestingTableData?.length > 0 ? (
          <div>
            <button onClick={handleSettingModal}>
              <img
                className="mr-5"
                width={20}
                height={20}
                src={settingsIcon}
                alt="settingsIcon"
              />
            </button>
            <button onClick={handleOpen}>
              <img src={enlargeButton} alt="zoom" />
            </button>
          </div>
        ) : null}
      </div>
      <>
        {renderBackTestingHtml()}
        <div className="flex justify-center">
          {resultVisulizationBackTestingTableData?.backTestingTableData?.length > 0 && (
            <div className="pt-2">
              <Pagination
                totalPages={resultVisulizationBackTestingPaginationDate?.totalPages}
                activePageNumber={resultVisulizationBackTestingPaginationDate?.pageNumber}
                updateBackTestingPageNumber={(data) =>
                  handlePageNumberUpdateEvent(data)
                }
              />
            </div>
          )}
        </div>
      </>
    </div>
  );
}

export default ResultVisulizationBacktestingResults;
