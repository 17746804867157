import React, { useEffect, useState } from 'react';
import sortUp from '../../images/sortUp.svg'
import settingsIcon from '../../images/Settings.svg';
import { Checkbox, FormControl, MenuItem, Select, Typography, createTheme, ThemeProvider, Popover, Tooltip, Button } from '@mui/material';
import './RcaTable.css';
import { Pagination } from '../../components/Pagination';
import SelectableList from './PopOverComp';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { table } from 'console';
import { rcaWriteBackApi } from '../../api/forecastPageApi';
import { useOktaAuth } from '@okta/okta-react';
import { updateExecutionBackTestingPageNumber } from '../../store/rcaSlice';
import loadingSvg from "../../images/bouncing-circles.svg";
const uniqueKey = require('unique-key');

type DataRow = {
    upc: string;
    account: string;
    region: string;
    rca: string;
    pfeAccuracy: number;
    pfeBias: number;
    forecast: number;
    error: number;
    dpAccuracy: number;
    id: string;
};

const RCATable = () => {
    const [data, setTableData] = useState<any>([])
    const [listOverride, setListItems] = useState<any>([])
    const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
    const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
    const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null);
    const [selectValue1, setSelectValue1] = useState<string>('RCA Stat Override');
    const [selectValue2, setSelectValue2] = useState<string>('RCA Total');
    const [selectValue3, setSelectValue3] = useState<string>('Tag');
    const [sortKey, setSortKey] = useState<SortKey | null>(null);
    const [ascending, setAscending] = useState<boolean>(true);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [selectValue, setSelectValue] = useState<{ [key: string]: string }>({});
    const tableData = useSelector((state: RootState) => state.rcaData.tableData)
    const isTableLoading = useSelector((state: RootState) => state.rcaData.isTableDataLoading);
    const filter = useSelector((state: RootState) => state.rcaFilter);
    const { authState } = useOktaAuth();
    const rcaExecutionPaginationSlice = useSelector((state: RootState) => state.rcaExecutionPaginationSlice);
    const dispatch = useDispatch();


    // const handleMenuItemClick = (value: string, setValue: React.Dispatch<React.SetStateAction<string>>, setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => {
    //     setValue(value);
    //     setAnchorEl(null);
    // };

    const extractTableData = () => {

        const extractedData = sortedData
            .map((row: any) => ({
                materialGlobalTradeItemNumber: row.upc,
                demandPlanningKeyAccountId: row.account,
                salesRegionCode: row.region,
                snapshotDate: [row.snapshotDate].toString() || null,
                shortTermMidTermCode: filter?.term.toString(),
                rcaStatOverride: selectValue[row.id] || null,
            }))
            .filter((row: any) => row.rcaStatOverride !== null);
        rcaWriteBackApi(extractedData[0], authState)
    };

    const extractRcaNames = (data: any): any => {
        // Create a Set to hold unique finalRcaNames to avoid duplicates
        const uniqueRcaNames = new Set<string>();

        // Iterate through the data and add finalRcaName to the Set
        data?.forEach((item: any) => {
            if (item.finalRcaName && !uniqueRcaNames.has(item.finalRcaName)) {
                uniqueRcaNames.add(item.finalRcaName);
            }
        });

        // Map the Set to an array of objects with id and label
        return Array.from(uniqueRcaNames).map((name, index) => ({
            id: (index + 1).toString(),
            label: name,
        }));
    };

    const transformResponseData = (responseData: any) => {
        return responseData?.map((item: any) => ({
            upc: item.materialGlobalTradeItemNumber,
            account: item.demandPlanningKeyAccountId,
            region: item.salesRegionCode,
            rca: item.rcaStat,
            pfeAccuracy: item.pfeAccuracy,
            pfeBias: item.pfeBias,
            forecast: Math.trunc(item.statisticalForecast),
            snapshotDate: item?.snapshotDate,
            id: uniqueKey()
        }));
    }


    type SortKey = keyof DataRow;

    const sortData = (key: SortKey, ascending: boolean) => {
        return [...data].sort((a, b) => {
            if (ascending) {
                return a[key] > b[key] ? 1 : -1;
            } else {
                return a[key] < b[key] ? 1 : -1;
            }
        });
    };

    const sortedData = sortKey ? sortData(sortKey, ascending) : data;

    useEffect(() => {
        setTableData(transformResponseData(tableData));
        setListItems(extractRcaNames(tableData))
    }, [tableData])

    const theme = createTheme({
        palette: {
            background: {
                paper: '#272727',
            },
            text: {
                primary: '#ffff',
            },
        },
    });

    const handleSelectChange = (id: string, event: any) => {
        setSelectValue((prev) => ({
            ...prev,
            [id]: event.target.value, // Update the value for the specific row
        }));
    };

    const handleUpdate = (selectedValue: string | null) => {
        if (!selectedValue) return; // If the value is null, do nothing

        const updatedValues = { ...selectValue };

        // Update the dropdown value for selected rows
        selectedRows.forEach((index) => {
            const rowId = data[index]?.id;
            if (rowId) {
                updatedValues[rowId] = selectedValue;
            }
        });

        setSelectValue(updatedValues);
        setAnchorEl1(null); // Close the popover
    };


    const handleSort = (key: SortKey) => {
        setAscending(sortKey === key ? !ascending : true);
        setSortKey(key);
    };


    const getColor = (value: number) => {
        if (value >= 85) return "bg-green-bg text-white";
        if (value >= 70) return "bg-yellow-bg text-white";
        return "bg-red-700 text-white";
    };

    const renderSortIcon = (key: SortKey) => {
        if (sortKey === key) {
            return ascending ? (
                <img src={sortUp} alt="sortUp" className="ml-2 w-3 h-3" />
            ) : (
                <img src={sortUp} alt="sortDown" className="ml-2 w-3 h-3" />
            );
        } else {
            return <img src={sortUp} alt="sortDown" className="ml-2 w-3 h-3" />;
        }
    };

    const handleCheckboxChange = (index: number) => {
        if (selectedRows.includes(index)) {
            setSelectedRows(selectedRows.filter((row) => row !== index));
        } else {
            setSelectedRows([...selectedRows, index]);
        }
    };

    const handlePageNumberUpdateEvent = (currentPageNumber: number) => {
        dispatch(
            updateExecutionBackTestingPageNumber({
                pageLimit: rcaExecutionPaginationSlice?.pageLimit,
                pageNumber: currentPageNumber,
                totalPages: rcaExecutionPaginationSlice?.totalPages,
                isDataShownClicked: false,
                groupBySelectedField: [],
            })
        );
    };

    return (
        <div className="mt-4">
            {isTableLoading ? (
                <div className="flex justify-center">
                    <img
                        src={loadingSvg}
                        alt="loading"
                        className="mt-10"
                        width={40}
                        height={40}
                    />
                </div>
            ) : (
                <div className="bg-ternary-bg text-white p-6 pb-10 rounded-lg shadow-lg">
                    <div className='pb-4 flex justify-end items-center'>
                        {/* <span className="text-lg font-bold">RCA </span> */}

                        <div className="flex space-x-2">
                            <div className='pt-3 mr-3'>
                                <Typography variant='h6'>Add</Typography>
                            </div>
                            <ThemeProvider theme={theme}>
                                <div
                                    style={{
                                        minWidth: '195px',
                                        backgroundColor: '#313131',
                                        borderRadius: '100px',
                                        position: 'relative',
                                        display: 'inline-block',
                                    }}
                                >
                                    <Tooltip title={selectedRows.length > 0 ? '' : 'Select rows to enable'}>
                                        <Typography
                                            onClick={(event) => {
                                                if (selectedRows.length > 0) {
                                                    setAnchorEl1(event.currentTarget);
                                                }
                                            }}
                                            className='p-4'
                                            sx={{
                                                color: selectedRows.length > 0 ? '#fff' : '#616161',
                                                cursor: selectedRows.length > 0 ? 'pointer' : 'not-allowed',
                                                display: 'block',
                                                border: 'none',
                                            }}
                                        >
                                            {selectValue1 || 'RCA Stat Override'}
                                        </Typography>
                                    </Tooltip>

                                    <Popover
                                        open={Boolean(anchorEl1)}
                                        anchorEl={anchorEl1}
                                        onClose={() => setAnchorEl1(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <SelectableList
                                            items={listOverride}
                                            onCancel={() => setAnchorEl1(null)}
                                            onUpdate={handleUpdate}
                                        />
                                    </Popover>
                                </div>

                                {/* <div
                                style={{
                                    minWidth: '125px',
                                    backgroundColor: '#313131',
                                    borderRadius: '100px',
                                    position: 'relative',
                                    display: 'inline-block',
                                }}
                            >
                                <Typography
                                    onClick={(event) => setAnchorEl2(event.currentTarget)}
                                    sx={{
                                        color: '#fff',
                                        padding: '8px 16px',
                                        cursor: 'pointer',
                                        display: 'block',
                                        border: 'none',
                                    }}
                                >
                                    {selectValue2 || 'RCA Total'}
                                </Typography>
                                <Popover
                                    open={Boolean(anchorEl2)}
                                    anchorEl={anchorEl2}
                                    onClose={() => setAnchorEl2(null)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={() => handleMenuItemClick('Total 1', setSelectValue2, setAnchorEl2)}>
                                        Total 1
                                    </MenuItem>
                                    <MenuItem onClick={() => handleMenuItemClick('Total 2', setSelectValue2, setAnchorEl2)}>
                                        Total 2
                                    </MenuItem>
                                </Popover>
                            </div> */}

                                {/* <div
                                style={{
                                    minWidth: '80px',
                                    backgroundColor: '#313131',
                                    borderRadius: '100px',
                                    position: 'relative',
                                    display: 'inline-block',
                                }}
                            >
                                <Typography
                                    onClick={(event) => setAnchorEl3(event.currentTarget)}
                                    className='p-4'
                                    sx={{
                                        color: '#fff',
                                        cursor: 'pointer',
                                        display: 'block',
                                        border: 'none',
                                    }}
                                >
                                    {selectValue3 || 'Tag'}
                                </Typography>
                                <Popover
                                    open={Boolean(anchorEl3)}
                                    anchorEl={anchorEl3}
                                    onClose={() => setAnchorEl3(null)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={() => handleMenuItemClick('Tag 1', setSelectValue3, setAnchorEl3)}>
                                        Tag 1
                                    </MenuItem>
                                    <MenuItem onClick={() => handleMenuItemClick('Tag 2', setSelectValue3, setAnchorEl3)}>
                                        Tag 2
                                    </MenuItem>
                                </Popover>
                            </div> */}
                                <div className="flex justify-end">
                                    <button
                                        className='write-back-button'
                                        onClick={extractTableData}
                                    >
                                        Save
                                    </button>
                                </div>

                            </ThemeProvider>
                        </div>
                    </div>

                    {tableData?.length > 0 ? <div className="RCA-table-holder">
                        <table className="text-xs">
                            <thead>
                                <tr className="bg-ternary-bg">
                                    <th className="border border-primary-border" style={{ width: '40px' }}>
                                        <Checkbox
                                            sx={{ color: "white", padding: '0px', width: '20px', }}
                                            onChange={() => {
                                                if (selectedRows?.length === sortedData?.length) {
                                                    setSelectedRows([]);
                                                } else {
                                                    setSelectedRows(sortedData?.map((_: DataRow, i: number) => i));
                                                }
                                            }}
                                            checked={selectedRows?.length === sortedData?.length}
                                        />
                                    </th>
                                    <th className="px-4 py-4 border border-primary-border">UPC</th>
                                    <th className="px-4 py-4 border border-primary-border">KEY ACCOUNT</th>
                                    <th className="px-4 py-4 border border-primary-border">SALES REGION</th>
                                    <th className="px-4 py-4 border border-primary-border">RCA STAT</th>
                                    <th className="px-4 py-4 border border-primary-border">RCA STAT OVERRIDE</th>
                                    <th
                                        className="px-4 py-4 border border-primary-border cursor-pointer"
                                        onClick={() => handleSort('pfeAccuracy')}
                                    >
                                        <div className="flex items-center justify-between">
                                            (PFE) FORECAST ACCURACY STAT
                                            {renderSortIcon('pfeAccuracy')}
                                        </div>
                                    </th>
                                    <th
                                        className="px-4 py-4 border border-primary-border cursor-pointer"
                                        onClick={() => handleSort('pfeBias')}
                                    >
                                        <div className="flex items-center justify-between">
                                            (PFE) BIAS
                                            {renderSortIcon('pfeBias')}
                                        </div>
                                    </th>
                                    <th className="px-4 py-4 border border-primary-border">STATISTICAL FORECAST</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData?.map((row: DataRow, idx: number) => {
                                    const rowId = row?.id;
                                    return (<tr key={rowId} className="border border-gray-200">
                                        <td className="border border-primary-border" style={{ width: '40px' }}>
                                            <Checkbox
                                                sx={{ color: 'white', padding: '0px', width: '20px', height: '20px' }}
                                                checked={selectedRows.includes(idx)}
                                                onChange={() => handleCheckboxChange(idx)}
                                            />
                                        </td>
                                        <td className="px-4 py-4 border border-primary-border">{row.upc}</td>
                                        <td className="px-4 py-4 border border-primary-border">{row.account}</td>
                                        <td className="px-4 py-4 border border-primary-border">{row.region}</td>
                                        <td className="px-4 py-4 border border-primary-border">{row.rca}</td>
                                        <td className="border border-primary-border">
                                            <ThemeProvider theme={theme}>
                                                <FormControl
                                                    fullWidth
                                                    sx={{
                                                        minWidth: '80px',
                                                        backgroundColor: '#313131',
                                                        borderRadius: '0',
                                                    }}
                                                >
                                                    <Select
                                                        value={selectValue[rowId] || ''}
                                                        onChange={(event) => handleSelectChange(rowId, event)}
                                                        sx={{
                                                            height: '38px',
                                                            '& .MuiInputBase-root': {
                                                                color: 'white',
                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#616161',
                                                            },
                                                            '& .MuiSvgIcon-root': {
                                                                color: 'white',
                                                            },
                                                            "&.MuiOutlinedInput-notchedOutline": {
                                                                borderColor: '#616161'
                                                            }
                                                        }}
                                                    >
                                                        {listOverride?.map((item: any) => (
                                                            <MenuItem value={item?.label.toString()} id={item?.id}>{item?.label.toString()}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </ThemeProvider>
                                        </td>
                                        <td className={`px-4 py-4 border border-primary-border ${getColor(row.pfeAccuracy)}`}>
                                            {row.pfeAccuracy}%
                                        </td>
                                        <td className={`px-4 py-4 border border-primary-border`}>
                                            {row.pfeBias}%
                                        </td>
                                        <td className="px-4 py-4 border border-primary-border">{row.forecast}</td>
                                    </tr>)
                                })}
                            </tbody>

                        </table>
                    </div> : <div className="bg-ternary-bg rounded p-2">
                        <div className="flex justify-center">
                            <p className="p-2 m-1">
                                No Data to show or no options selected, please choose from the above filters.
                            </p>
                        </div>
                    </div>}
                    {tableData?.length > 0 ? <div className='mt-8 flex justify-center'>
                        <div className="pt-2">
                            <Pagination
                                totalPages={rcaExecutionPaginationSlice?.totalPages}
                                activePageNumber={rcaExecutionPaginationSlice?.pageNumber}
                                updateBackTestingPageNumber={(data) =>
                                    handlePageNumberUpdateEvent(data)
                                }
                            />
                        </div>
                    </div> : <></>}
                </div>
            )}
        </div>
    );
};

export default RCATable;
