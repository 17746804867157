import React, { useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { RootState } from "../../store/store";
import { resetResultVisualizationState } from "../../store/filterSlice";
import MultiInputSelect from "../../baseComponent/inputSelectType/multiInputSelectVisualization";

import {
  useCusomtersApiData,
  useForecastClusterFilter,
  useItemsApiData,
  usePfeApiData,
  useSalesGeoApiData,
} from "../../api/filtersApi";
import loadingSvg from "../../images/bouncing-circles.svg";
import { Box, Modal, Typography } from "@mui/material";
import { postAPI } from "../../API";
import {
  setChartData,
  setAverageData,
  resetResultVisulizationChartData,
  resultVisulizationBackTestingTableDataSetter,
  updateBackTestingTotalPages,
  setLoader,
  resetresultVisulizationBackTestingTableState,
  resetPredefinePaginationState,
} from "../../store/resultVisualizationPredefined";
import {
  calculateTotalPages,
  resultVisulizationPredefineDefaultGroupBy,
  mapperForGroupByLableToKey,
  resultVisualisationAverageAPI,
  resultVisualisationTableAPI,
} from "../../api/forecastPageApi";
import { PAGE_LIMIT } from "../../utils/constant";
import { useNavigate, useSearchParams } from "react-router-dom";

const style = {
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  borderRadius: "10px",
  bgcolor: "#1D1D1D",
  border: "8px solid #3A3A3A",
  boxShadow: 24,
  p: 4,
};

const ResultVisualizationPredefinedFilter: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();
  const filter = useSelector(
    (state: RootState) => state.resultVisualizationFilter
  );
  const resultVisulizationBackTestingPagination = useSelector(
    (state: RootState) => state.resultVisulizationBackTestingPagination
  );

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const payloadForChart = {
    brandName: filter?.brand,
    subBrandName: filter?.subBrand,
    rank: filter?.rankPredefined?.map((item) => +item),
    shortTermMidTermCode: filter?.term.toString(),
    productLineName: filter?.productLine,
    productGroupName: filter?.productGroup,
    materialGlobalTradeItemNumberDescription: filter?.planningItem,
    keyAccount: filter?.keyAccount,
    packageSizeName: filter?.size,
    productCategoryName: filter?.category,
    portfolioGroupName: filter?.mg4PortfolioGroup,
    packageName: filter?.count,
    packageTypeName: filter?.container,
    packTypeName: filter.ppg,
    salesRegionName: filter?.salesRegion,
    salesCountryCode: filter?.country,
    forecastModuleCode: filter?.ac1ac2,
    forecastEngineSegmentationId: filter?.segment,
    customerGlobalName: filter?.global,
    customerSectorNumber: filter?.customerSector,
    customerRegionName: filter?.customerRegion,
    customerGroupName: filter?.customerGroup,
    customerBusinessUnitName: filter?.businessUnit,
    customerMarketUnitName: filter?.marketUnit,
    ibpDemandGroup: filter?.ibpDemandGroup,
    customerTradeSegmentDescription: filter?.cg1TrageSegment,
    salesSectorName: filter?.salesSector,
    salesOrganizationCode: filter?.businessOrg,
    salesDivisionName: filter?.division,
    forecastEngineClusterId: filter?.clusters,
  };

  const backtestingPayloadFilters = {
    shortTermMidTermCode: filter?.term.toString(),
    // snapshotDate:
    //   filter?.term.toString() === "ST"
    //     ? filter?.shortSnapshotDate
    //     : filter?.midSnapshotDate,
    brandName: filter?.brand,
    subBrandName: filter?.subBrand,
    rank: filter?.rankPredefined?.map((item) => +item),
    productLineName: filter?.productLine,
    productGroupName: filter?.productGroup,
    materialGlobalTradeItemNumberDescription: filter?.planningItem,
    keyAccount: filter?.keyAccount,
    packageSizeName: filter?.size,
    productCategoryName: filter?.category,
    portfolioGroupName: filter?.mg4PortfolioGroup,
    packageName: filter?.count,
    packageTypeName: filter?.container,
    packTypeName: filter.ppg,
    salesRegionName: filter?.salesRegion,
    salesCountryCode: filter?.country,
    forecastModuleCode: filter?.ac1ac2,
    forecastEngineSegmentationId: filter?.segment,
    customerGlobalName: filter?.global,
    customerSectorNumber: filter?.customerSector,
    customerRegionName: filter?.customerRegion,
    customerGroupName: filter?.customerGroup,
    customerBusinessUnitName: filter?.businessUnit,
    customerMarketUnitName: filter?.marketUnit,
    ibpDemandGroup: filter?.ibpDemandGroup,
    customerTradeSegmentDescription: filter?.cg1TrageSegment,
    salesSectorName: filter?.salesSector,
    salesOrganizationCode: filter?.businessOrg,
    salesDivisionName: filter?.division,
    forecastEngineClusterId: filter?.clusters,
    pageNumber: 1,
    pageSize: PAGE_LIMIT,
  };

  const { data: itemsApiData, isLoading: isItemsApiDataLoading } =
    useItemsApiData(authState?.accessToken?.accessToken!, "items");
  const { data: customersApiData, isLoading: isCustomersApiDataLoading } =
    useCusomtersApiData(authState?.accessToken?.accessToken!, "customers");
  const { data: salesGeoApiData, isLoading: isSalesGeoApiDataLoading } =
    useSalesGeoApiData(authState?.accessToken?.accessToken!, "salesgeo");
  const { data: pfeApiData, isLoading: ispfeApiDataLoading } = usePfeApiData(
    authState?.accessToken?.accessToken!,
    "pfe"
  );
  const { data: clusterApiData } = useForecastClusterFilter(
    authState?.accessToken?.accessToken!,
    "cluster"
  );

  const resetFilter = () => {
    dispatch(
      resetResultVisualizationState({
        key: "clusters",
        value: clusterApiData?.clusters,
      })
    );
    dispatch(setLoader(false));
    dispatch(resetResultVisulizationChartData());
    dispatch(resetresultVisulizationBackTestingTableState());
    dispatch(resetPredefinePaginationState());
    handleClose();
  };

  useEffect(() => {
    if (
      resultVisulizationBackTestingPagination?.totalPages > 1 &&
      resultVisulizationBackTestingPagination?.isDataShownClicked === false
    ) {
      makeBackTesingApiCall(backtestingPayloadFilters, authState);
    }
  }, [resultVisulizationBackTestingPagination?.pageNumber]);

  const applyFilters = async () => {
    dispatch(setLoader(true));
    const chartDataResponse = await postAPI(
      "predefinedbacktesting/chart",
      payloadForChart,
      authState?.accessToken?.accessToken!
    );

    const forecastAnalysisApiRequestBody = {
      shortTermMidTermCode: filter?.term.toString(),
      rank: filter?.rankPredefined?.map((item) => +item),
      brandName: filter?.brand,
      subBrandName: filter?.subBrand,
      productLineName: filter?.productLine,
      productGroupName: filter?.productGroup,
      materialGlobalTradeItemNumberDescription: filter?.planningItem,
      keyAccount: filter?.keyAccount,
      packageSizeName: filter?.size,
      productCategoryName: filter?.category,
      portfolioGroupName: filter?.mg4PortfolioGroup,
      packageName: filter?.count,
      packageTypeName: filter?.container,
      packTypeName: filter.ppg,
      customerGlobalName: filter?.global,
      customerSectorNumber: filter?.customerSector,
      customerRegionName: filter?.customerRegion,
      customerGroupName: filter?.customerGroup,
      customerBusinessUnitName: filter?.businessUnit,
      customerMarketUnitName: filter?.marketUnit,
      ibpDemandGroup: filter?.ibpDemandGroup,
      customerTradeSegmentDescription: filter?.cg1TrageSegment,
      salesSectorName: filter?.salesSector,
      salesRegionName: filter?.salesRegion,
      salesCountryCode: filter?.country,
      salesOrganizationCode: filter?.businessOrg,
      salesDivisionName: filter?.division,
      forecastEngineClusterId: filter?.clusters,
      pageNumber: 1,
      forecastModuleCode: filter?.ac1ac2,
      forecastEngineSegmentationId: filter?.segment,
      pageSize: PAGE_LIMIT,
    };

    const forecastAnalysisApiRequestBodyTable = {
      shortTermMidTermCode: filter?.term.toString(),
      brandName: filter?.brand,
      subBrandName: filter?.subBrand,
      rank: filter?.rankPredefined?.map((item) => +item),
      // rank: [1, 2],
      productLineName: filter?.productLine,
      productGroupName: filter?.productGroup,
      packageSizeName: filter?.size,
      packageTypeName: filter?.container,
      packTypeName: filter.ppg,
      keyAccount: filter?.keyAccount,
      materialGlobalTradeItemNumberDescription: filter?.planningItem,
      productCategoryName: filter?.category,
      portfolioGroupName: filter?.mg4PortfolioGroup,
      packageName: filter?.count,
      salesRegionName: filter?.salesRegion,
      salesCountryCode: filter?.country,
      forecastModuleCode: filter?.ac1ac2,
      forecastEngineSegmentationId: filter?.segment,
      customerGlobalName: filter?.global,
      customerSectorNumber: filter?.customerSector,
      customerRegionName: filter?.customerRegion,
      customerGroupName: filter?.customerGroup,
      customerBusinessUnitName: filter?.businessUnit,
      customerMarketUnitName: filter?.marketUnit,
      ibpDemandGroup: filter?.ibpDemandGroup,
      customerTradeSegmentDescription: filter?.cg1TrageSegment,
      salesSectorName: filter?.salesSector,
      salesOrganizationCode: filter?.businessOrg,
      salesDivisionName: filter?.division,
      forecastEngineClusterId: filter?.clusters,
      pageNumber: 1,
      pageSize: PAGE_LIMIT,
    };

    resultVisualisationAverageAPI(forecastAnalysisApiRequestBody, authState)
      .then((averageData) => {
        dispatch(setLoader(true));
        dispatch(setAverageData(averageData));
        dispatch(setLoader(false));
      })
      .catch((err) => console.log("Error", err));
    const chartData = chartDataResponse?.data?.response?.chartData;
    dispatch(setChartData(chartData));
    makeBackTesingApiCall(forecastAnalysisApiRequestBodyTable, authState);
  };
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("invoke") === "true") {
      applyFilters();

      setTimeout(() => {
        navigate("/resultvisualization", { replace: true });
      }, 1000);
    }
  }, [searchParams, navigate]);

  const makeBackTesingApiCall = (
    forecastAnalysisApiRequestBodyTable: any,
    authState: any
  ) => {
    dispatch(
      resultVisulizationBackTestingTableDataSetter({
        isTableDataLoading: true,
        backTestingTableData: [],
        tableHeader: [],
      })
    );

    const backTestingGroupByOptions = mapperForGroupByLableToKey(
      resultVisulizationPredefineDefaultGroupBy
    );
    const resultVisulizationTestingApiRequestBody = {
      ...forecastAnalysisApiRequestBodyTable,
      pageNumber: resultVisulizationBackTestingPagination?.pageNumber,
      pageSize: resultVisulizationBackTestingPagination?.pageLimit,
      groupBy: backTestingGroupByOptions.selectedGroupByOptions,
    };

    resultVisualisationTableAPI(
      resultVisulizationTestingApiRequestBody,
      authState
    )
      .then((response) => {
        dispatch(setLoader(true));
        const totalBackTestingPages = calculateTotalPages(
          PAGE_LIMIT,
          response?.totalCount
        );
        dispatch(
          updateBackTestingTotalPages({
            totalPages: totalBackTestingPages,
            pageLimit: resultVisulizationBackTestingPagination.pageLimit,
            pageNumber: resultVisulizationBackTestingPagination.pageNumber,
            isDataShownClicked:
              resultVisulizationBackTestingPagination.isDataShownClicked,
          })
        );
        dispatch(
          resultVisulizationBackTestingTableDataSetter({
            tableHeader: response?.dynamicTableHeader,
            backTestingTableData: response?.dfuBasedGroupedData,
            isTableDataLoading: false,
          })
        );
        dispatch(setLoader(false));
      })
      .catch((error) => {
        console.log("resultVisulizationTestingTableApi error", error);
      });
  };

  return (
    <div className="p-5 bg-secondary-bg mt-4 rounded">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            style={{ fontWeight: "bold", fontSize: "28px", color: "white" }}
          >
            Are you sure you want to reset filters?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 1, color: "white" }}
          >
            This cannot be undone and you will loose all you filters selections
          </Typography>
          <div className="flex w-full justify-end mt-6">
            <button
              onClick={handleClose}
              className="cancelFilterResetModalButton"
            >
              Cancel
            </button>
            <button onClick={resetFilter} className="resetFilterModalButton">
              Reset Filters
            </button>
          </div>
        </Box>
      </Modal>
      <div className="flex justify-between mb-4">
        <span className="text-lg font-bold mb-4 ">Filters</span>
        <div className="flex">
          <button onClick={handleOpen} className="resetFilterButton">
            Reset Filters
          </button>
          <button onClick={applyFilters} className="applyFilterButton">
            Apply Filters
          </button>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="basis-3/4 ">
          <h6 className="text-lg">Item</h6>
          {isItemsApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="grid grid-cols-3 pt-3">
              <div>
                <div className="pb-2 select-label">Category</div>
                <MultiInputSelect
                  itemList={itemsApiData?.categories}
                  itemType="category"
                />
                <div className="pb-2 mt-4 select-label">Product Line</div>
                <MultiInputSelect
                  itemList={itemsApiData?.productLines}
                  itemType="productLine"
                />
                <div className="pb-2 mt-4 select-label">Product Group</div>
                <MultiInputSelect
                  itemList={itemsApiData?.productGroups}
                  itemType="productGroup"
                />
                <div className="pb-2 mt-4 select-label">
                  MG4 Portfolio Group
                </div>
                <MultiInputSelect
                  itemList={itemsApiData?.mg4PortfolioGroups}
                  itemType="mg4PortfolioGroup"
                />
              </div>
              <div>
                <div className="pb-2 select-label">Brand</div>
                <MultiInputSelect
                  itemList={itemsApiData?.brands}
                  itemType="brand"
                />
                <div className="pb-2 mt-4 select-label">Sub Brand</div>
                <MultiInputSelect
                  itemList={itemsApiData?.subBrands}
                  itemType="subBrand"
                />
                <div className="pb-2 mt-4 select-label">Container</div>
                <MultiInputSelect
                  itemList={itemsApiData?.containers}
                  itemType="container"
                />
                <div className="pb-2 mt-4 select-label">PPG</div>
                <MultiInputSelect itemList={itemsApiData?.ppg} itemType="ppg" />
              </div>
              <div>
                <div className="pb-2 select-label">Size</div>
                <MultiInputSelect
                  itemList={itemsApiData?.size}
                  itemType="size"
                />
                <div className="pb-2 mt-4 select-label">Count</div>
                <MultiInputSelect
                  itemList={itemsApiData?.count}
                  itemType="count"
                />
                <div className="pb-2 mt-4 select-label">Planning Item</div>
                <MultiInputSelect
                  itemList={itemsApiData?.planningItems}
                  itemType="planningItem"
                />
              </div>
            </div>
          )}
        </div>
        <div className="basis-2/4">
          <h6 className="text-lg">Customer</h6>
          {isCustomersApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="grid grid-cols-2 pt-3">
              <div>
                <div className="pb-2 select-label">Key Account</div>
                <MultiInputSelect
                  itemList={customersApiData?.keyAccount}
                  itemType="keyAccount"
                />
                <div className="pb-2 mt-4 select-label">Global</div>
                <MultiInputSelect
                  itemList={customersApiData?.global}
                  itemType="global"
                />
                <div className="pb-2 mt-4 select-label">Sector</div>
                <MultiInputSelect
                  itemList={customersApiData?.sector}
                  itemType="customerSector"
                />
                <div className="pb-2 mt-4 select-label">Region</div>
                <MultiInputSelect
                  itemList={customersApiData?.regions}
                  itemType="customerRegion"
                />
                <div className="pb-2 mt-4 select-label">Customer Group</div>
                <MultiInputSelect
                  itemList={customersApiData?.customerGroups}
                  itemType="customerGroup"
                />
              </div>
              <div>
                <div className="pb-2 select-label">Business Unit</div>
                <MultiInputSelect
                  itemList={customersApiData?.businessUnits}
                  itemType="businessUnit"
                />
                <div className="pb-2 mt-4 select-label">Market Unit</div>
                <MultiInputSelect
                  itemList={customersApiData?.marketUnits}
                  itemType="marketUnit"
                />
                <div className="pb-2 mt-4 select-label">IBP Demand Group</div>
                <MultiInputSelect
                  itemList={customersApiData?.ibpDemandGroup}
                  itemType="ibpDemandGroup"
                />
                <div className="pb-2 mt-4 select-label">CGI Trade Segment</div>
                <MultiInputSelect
                  itemList={customersApiData?.cgiTradeSegment}
                  itemType="cg1TrageSegment"
                />
              </div>
            </div>
          )}
        </div>
        <div className="basis-2/4">
          <h6 className="text-lg">Sales Geo</h6>
          {isSalesGeoApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="grid grid-cols-2 pt-3">
              <div>
                <div className="pb-2 select-label">Sector</div>
                <MultiInputSelect
                  itemList={salesGeoApiData?.sector}
                  itemType="salesSector"
                />
                <div className="pb-2 mt-4 select-label">Country</div>
                <MultiInputSelect
                  itemList={salesGeoApiData?.countries}
                  itemType="country"
                />
                <div className="pb-2 mt-4 select-label">Sales Region</div>
                <MultiInputSelect
                  itemList={salesGeoApiData?.salesRegion}
                  itemType="salesRegion"
                />
              </div>
              <div>
                <div className="pb-2 select-label">Sales Business Org</div>
                <MultiInputSelect
                  itemList={salesGeoApiData?.salesBusinessOrg}
                  itemType="businessOrg"
                />
                <div className="pb-2 mt-4 select-label">Sales Division</div>
                <MultiInputSelect
                  itemList={salesGeoApiData?.salesDivision}
                  itemType="division"
                />
              </div>
            </div>
          )}
        </div>
        <div className="basis-1/4">
          <h6 className="text-lg">PFE</h6>
          {ispfeApiDataLoading ? (
            <div className="flex justify-center">
              <img
                src={loadingSvg}
                alt="loading"
                className="mt-10"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <div className="grid grid-cols-1 pt-3">
              <div>
                <div className="pb-2 select-label">Segment</div>
                <MultiInputSelect
                  itemList={pfeApiData?.segment}
                  itemType="segment"
                />
                <div className="pb-2 mt-4 select-label">AC1/AC2</div>
                <MultiInputSelect
                  itemList={pfeApiData?.acOneTwo}
                  itemType="ac1ac2"
                />
                {/* <div className="pb-2 mt-4 select-label">Recipe Runs</div>
                <MultiInputSelect
                  itemList={pfeApiData?.recipeRuns}
                  itemType="recipeRuns"
                /> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultVisualizationPredefinedFilter;
