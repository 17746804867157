// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-button {
  display: flex;
  border: 1px solid #4b4b4b;
  border-radius: 5px;
  overflow: hidden;
  align-self: flex-start;
}

.toggle-button button {
  background-color: transparent;
  border: none;
  padding: 8px 14px;
  min-width: 30%;
  font-size: 14px;
  color: #3387df;
  cursor: pointer;
}

.toggle-button button.active {
  background-color: #0069d7;
  color: white;
}

.date-input {
  margin: 11px;
  width: 50%;
}

.date-input input {
  width: 100%;
  padding: 8px;
  background-color: #333333;
  border: none;
  color: #ffffff;
  border-radius: 5px;
}

.lag-input {
  width: 20%;
  margin: 11px;
}

.lag-input input {
  width: 100%;
  padding: 8px;
  background-color: #333333;
  border: none;
  color: #ffffff;
  border-radius: 5px;
}

.view-forecast-button {
  background-color: transparent;
  border: none;
  color: #3387df;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-container {
  margin: 11px;
}

.toggle-label {
  margin-right: 10px;
  color: #8f8f8f;
  font-size: 14px;
  align-self: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/pages/StatisticalForecastAnalysis/Timecard/cardTime.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".toggle-button {\n  display: flex;\n  border: 1px solid #4b4b4b;\n  border-radius: 5px;\n  overflow: hidden;\n  align-self: flex-start;\n}\n\n.toggle-button button {\n  background-color: transparent;\n  border: none;\n  padding: 8px 14px;\n  min-width: 30%;\n  font-size: 14px;\n  color: #3387df;\n  cursor: pointer;\n}\n\n.toggle-button button.active {\n  background-color: #0069d7;\n  color: white;\n}\n\n.date-input {\n  margin: 11px;\n  width: 50%;\n}\n\n.date-input input {\n  width: 100%;\n  padding: 8px;\n  background-color: #333333;\n  border: none;\n  color: #ffffff;\n  border-radius: 5px;\n}\n\n.lag-input {\n  width: 20%;\n  margin: 11px;\n}\n\n.lag-input input {\n  width: 100%;\n  padding: 8px;\n  background-color: #333333;\n  border: none;\n  color: #ffffff;\n  border-radius: 5px;\n}\n\n.view-forecast-button {\n  background-color: transparent;\n  border: none;\n  color: #3387df;\n  padding: 10px;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.toggle-container {\n  margin: 11px;\n}\n\n.toggle-label {\n  margin-right: 10px;\n  color: #8f8f8f;\n  font-size: 14px;\n  align-self: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
