import { useState } from "react";
import sortUp from "../../images/sortUp.svg";
import {
  FormControl,
  MenuItem,
  Select,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import "./RcaTable.css";
import loadingSvg from "../../images/bouncing-circles.svg";
import { Pagination } from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { updateSummaryBackTestingPageNumber } from "../../store/rcaSlice";
const uniqueKey = require("unique-key");

type DataRow = {
  upc: string;
  account: string;
  region: string;
  rca: string;
  pfeAccuracy: number;
  pfeBias: number;
  forecast: number;
  error: number;
  dpAccuracy: number;
};

const RcaSummaryTable = () => {
  type SortKey = keyof DataRow;
  const [sortKey, setSortKey] = useState<SortKey | null>(null);
  const [ascending, setAscending] = useState<boolean>(true);
  const [selectValue] = useState<string>("Select");
  const navigate = useNavigate();
  const rcaSummaryPaginationSlice = useSelector(
    (state: RootState) => state.rcaSummaryPaginationSlice
  );
  const rcaData = useSelector((state: RootState) => state.rcaData);
  const dispatch = useDispatch();
  const data = useSelector(
    (state: RootState) => state.rcaData.secondTable?.data
  );

  const sortData = (key: SortKey, ascending: boolean) => {
    return [...data].sort((a: any, b: any) => {
      const aValue = a[key];
      const bValue = b[key];
      const ascReturn = calCulateAsceding(ascending, aValue, bValue);

      if (typeof aValue === "number" && typeof bValue === "number") {
        return ascending ? aValue - bValue : bValue - aValue;
      }
      return ascReturn;
    });
  };

  const handleClick = () => {
    navigate("/statisticalforecastanalysis?invoke=true");
  };

  const calCulateAsceding = (
    ascending: boolean,
    aValue: string | number,
    bValue: string | number
  ) => {
    let result;

    if (ascending) {
      result = aValue > bValue ? 1 : -1;
    } else {
      result = aValue < bValue ? 1 : -1;
    }

    return result;
  };

  const theme = createTheme({
    palette: {
      background: {
        paper: "#272727",
      },
      text: {
        primary: "#ffff",
      },
    },
  });

  const handleSort = (key: SortKey) => {
    setAscending(sortKey === key ? !ascending : true);
    setSortKey(key);
  };

  const sortedData = sortKey ? sortData(sortKey, ascending) : data;

  const getColor = (value: number) => {
    if (value <= 70) return "bg-red-700 text-white";
    if (value >= 78) return "bg-green-bg text-white";
    return "bg-yellow-bg text-white";
  };

  const renderSortIcon = (key: SortKey) => {
    if (sortKey === key) {
      return ascending ? (
        <img src={sortUp} alt="sortUp" className="ml-2 w-3 h-3" />
      ) : (
        <img src={sortUp} alt="sortDown" className="ml-2 w-3 h-3" />
      );
    } else {
      return <img src={sortUp} alt="sortDown" className="ml-2 w-3 h-3" />;
    }
  };

  const handlePageNumberUpdateEvent = (currentPageNumber: number) => {
    dispatch(
      updateSummaryBackTestingPageNumber({
        pageLimit: rcaSummaryPaginationSlice?.pageLimit,
        pageNumber: currentPageNumber,
        totalPages: rcaSummaryPaginationSlice?.totalPages,
        isDataShownClicked: false,
        groupBySelectedField: [],
      })
    );
  };

  let tableContent;

  if (rcaData?.isTableDataLoading) {
    tableContent = (
      <div className="flex justify-center">
        <img
          src={loadingSvg}
          alt="loading"
          className="mt-10"
          width={40}
          height={40}
        />
      </div>
    );
  } else if (data?.length > 0) {
    tableContent = (
      <div className="RCA-table-holder">
        <table className="text-xs">
          <thead>
            <tr className="bg-ternary-bg">
              <th className="px-4 py-4 border border-primary-border">UPC</th>
              <th className="px-4 py-4 border border-primary-border">
                KEY ACCOUNT
              </th>
              <th className="px-4 py-4 border border-primary-border">
                SALES REGION
              </th>
              <th className="px-4 py-4 border border-primary-border">
                RCA STAT FINAL
              </th>
              <th
                className="px-4 py-4 border border-primary-border cursor-pointer"
                onClick={() => handleSort("pfeAccuracy")}
              >
                <div className="flex items-center justify-between">
                  (PFE) FORECAST ACCURACY STAT
                  {renderSortIcon("pfeAccuracy")}
                </div>
              </th>
              <th
                className="px-4 py-4 border border-primary-border cursor-pointer"
                onClick={() => handleSort("pfeBias")}
              >
                <div className="flex items-center justify-between">
                  (PFE) BIAS
                  {renderSortIcon("pfeBias")}
                </div>
              </th>
              <th
                className="px-4 py-4 border border-primary-border cursor-pointer"
                onClick={() => handleSort("error")}
              >
                <div className="flex items-center justify-between">
                  ABSOLUTE ERROR
                  {renderSortIcon("error")}
                </div>
              </th>
              <th
                className="px-4 py-4 border border-primary-border cursor-pointer"
                onClick={() => handleSort("forecast")}
              >
                <div className="flex items-center justify-between">
                  STATISTICAL FORECAST
                  {renderSortIcon("forecast")}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData?.map((row: any, idx: any) => (
              <tr
                key={uniqueKey("rca_summary_key")}
                className="border border-gray-200"
              >
                <td className="px-4 py-4 border border-primary-border">
                  {row.materialGlobalTradeItemNumber}
                </td>
                <td className="px-4 py-4 border border-primary-border">
                  {row.demandPlanningKeyAccountId}
                </td>
                <td className="px-4 py-4 border border-primary-border">
                  {row.salesRegionCode}
                </td>
                <td className="px-4 py-4 border border-primary-border">
                  {row.finalRcaName}
                </td>
                <td
                  className={`px-4 py-4 border border-primary-border ${getColor(
                    Math.trunc(row.pfeAccuracy)
                  )}`}
                >
                  {`${Math.trunc(row.pfeAccuracy)}%`}
                </td>
                <td className="px-4 py-4 border border-primary-border">
                  {`${Math.trunc(row.pfeBias)}%`}
                </td>
                <td className="px-4 py-4 border border-primary-border">
                  {Math.trunc(row.absoluteErrorQuantity)}
                </td>
                <td className="px-4 py-4 border border-primary-border">
                  {Math.trunc(row.statisticalForecast)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    tableContent = (
      <div className="bg-ternary-bg rounded p-2">
        <div className="flex justify-center">
          <p className="p-2 m-1">
            No Data to show or no options selected, please choose from the above
            filters.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-4">
      <div className="bg-ternary-bg text-white p-6 pb-10 rounded-lg shadow-lg">
        <div className="pb-4 flex justify-between items-center">
          <span className="text-lg font-bold">RCA Summary</span>

          <div className="flex space-x-2">
            <ThemeProvider theme={theme}>
              <FormControl
                fullWidth
                sx={{
                  minWidth: "195px",
                  backgroundColor: "#313131",
                  borderRadius: "100px",
                }}
              >
                <Select
                  value={selectValue}
                  sx={{
                    color: "#fff",
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  }}
                >
                  <MenuItem value="Select">Filter</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </div>
        </div>

        {tableContent}

        {data?.length > 0 ? (
          <div className="mt-8 flex justify-between">
            <div className="flex-grow flex justify-between ">
              <div></div>
              <Pagination
                totalPages={rcaSummaryPaginationSlice?.totalPages}
                activePageNumber={rcaSummaryPaginationSlice?.pageNumber}
                updateBackTestingPageNumber={(data) =>
                  handlePageNumberUpdateEvent(data)
                }
              />
              <button
                className="write-back-button"
                onClick={handleClick}
                // disabled={!(extractTableData?.length > 0)}
              >
                View Forecast Analysis
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default RcaSummaryTable;
