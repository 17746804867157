import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
const uniqueKey = require("unique-key");

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface BasicTabsProps {
  tabLabels: string[];
  children: React.ReactNode[];
  tabStyles?: React.CSSProperties;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs({ tabLabels, children, tabStyles }: BasicTabsProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider"}}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          aria-label="basic tabs example"
        >
          {tabLabels?.map((label, index) => (
            <Tab
              key={uniqueKey('customeTabPanel_tab_label')}
              label={label}
              style={{ textTransform: "none", ...tabStyles }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {children?.map((child, index) => (
        <CustomTabPanel key={uniqueKey('customeTabPanel_children')} value={value} index={index}>
          {child}
        </CustomTabPanel>
      ))}
    </>
  );
}

export default BasicTabs;
