import pepsiLogo from "../../images/PepsiLogo.svg";
import notificationIcon from "../../images/Notification.svg";
import settingsIcon from "../../images/Settings.svg";
import infoIcon from "../../images/info.svg";
import logoutIcon from "../../images/logout.svg";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { Loading } from "../../baseComponent";

function HorizontalNavbar() {
  const { oktaAuth } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  const logout = () => {
    setIsLoading(true);
    oktaAuth.signOut();
  };

  return (
    <div className="flex justify-between bg-secondary-bg p-4 ">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <img src={pepsiLogo} alt="PepsiLogo" />
          <span className="text-base font-bold">Forecast Analyzer</span>
          <div className="flex">
            <img
              className="mr-7"
              width={18}
              height={18}
              src={notificationIcon}
              alt="notificationIcon"
            />
            <img
              className="mr-7"
              width={20}
              height={20}
              src={settingsIcon}
              alt="settingsIcon"
            />
            <img
              className="mr-7"
              width={20}
              height={20}
              src={infoIcon}
              alt="infoIcon"
            />
            <img
              className="cursor-pointer"
              onClick={() => logout()}
              width={20}
              height={20}
              src={logoutIcon}
              alt="logoutIcon"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default HorizontalNavbar;
