import BasicTabs from "../../components/Tabs/customTabPanel";
import RcaExecution from "./RcaExecution";
import RcaSummary from "./RcaSummary";

const RootCauseAnalysis = () => {
  return (
    <div className="p-5">
      <div className="flex justify-between mb-7">
        <span className="text-3xl font-bold">Root Cause Analysis Dashboard</span>
      </div>
      <BasicTabs
        tabLabels={["RCA Execution", "RCA Summary"]}
        tabStyles={{ color: "white" }}
      >
        <RcaExecution/>
        <RcaSummary/>
      </BasicTabs>
    </div>
  );
}

export default RootCauseAnalysis