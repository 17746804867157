import React, { useState } from "react";
import "./ForecastDecomposeTable.css";
const uniqueKey = require("unique-key");

export interface ForecastDecomposeTableProps {
  forecastDecompositionData: {
    weekPeriodNumber: string;
    totalSeasonalQuantity: number;
    totalTradePromotionsQuantity: number;
    totalCustomerPromotionsQuantity: number;
    totalHolidaysEventsQuantity: number;
    totalFixedElementsQuantity: number;
    totalTradePartnersQuantity: number;
    totalBaselineQuantity: number;
    totalMacroEconomicsQuantity: number;
    totalMerchandisingQuantity: number;
    totalSalesIncentivesQuantity: number;
    totalAdvertisingQuantity: number;
    totalWeatherQuantity: number;
    totalPerfectStoreQuantity: number;
    totalDirectPointOfSalesQuantity: number;
    totalInnovationNPDQuantity: number;
    totalListPriceQuantity: number;
    totalDARateQuantity: number;
    totalMarketShareQuantity: number;
    totalInternalInventoryAvailabilityQuantity: number;
    totalExternalInventoryAvailabilityQuantity: number;
    totalRawMaterialInventoryQuantity: number;
    totalPurchaseOrdersQuantity: number;
    totalMobilityQuantity: number;
  }[];
}

const ForecastDecomposeTable: React.FC<ForecastDecomposeTableProps> = ({
  forecastDecompositionData,
}) => {
  const [currentPage] = useState(1);
  const rowsPerPage = 25;

  const weekLabels = forecastDecompositionData?.map((item) => {
    return item.weekPeriodNumber;
  });

  const tableData = [
    {
      name: "Baseline",
      values: forecastDecompositionData?.map(
        (item) => item.totalBaselineQuantity
      ),
    },
    {
      name: "Seasonal",
      values: forecastDecompositionData?.map(
        (item) => item.totalSeasonalQuantity
      ),
    },
    {
      name: "Trade Promotions",
      values: forecastDecompositionData?.map(
        (item) => item.totalTradePromotionsQuantity
      ),
    },
    {
      name: "Customer Promotions",
      values: forecastDecompositionData?.map(
        (item) => item.totalCustomerPromotionsQuantity
      ),
    },
    {
      name: "Holiday Events",
      values: forecastDecompositionData?.map(
        (item) => item.totalHolidaysEventsQuantity
      ),
    },
    {
      name: "Fixed Elements",
      values: forecastDecompositionData?.map(
        (item) => item.totalFixedElementsQuantity
      ),
    },
    {
      name: "Trade Partners",
      values: forecastDecompositionData?.map(
        (item) => item.totalTradePartnersQuantity
      ),
    },
    {
      name: "Macro Economics",
      values: forecastDecompositionData?.map(
        (item) => item.totalMacroEconomicsQuantity
      ),
    },
    {
      name: "Merchandising",
      values: forecastDecompositionData?.map(
        (item) => item.totalMerchandisingQuantity
      ),
    },
    {
      name: "Sales Incentives",
      values: forecastDecompositionData?.map(
        (item) => item.totalSalesIncentivesQuantity
      ),
    },
    {
      name: "Advertising",
      values: forecastDecompositionData?.map(
        (item) => item.totalAdvertisingQuantity
      ),
    },
    {
      name: "Weather",
      values: forecastDecompositionData?.map(
        (item) => item.totalWeatherQuantity
      ),
    },
    {
      name: "Perfect Store",
      values: forecastDecompositionData?.map(
        (item) => item.totalPerfectStoreQuantity
      ),
    },
    {
      name: "Direct Point of Sales",
      values: forecastDecompositionData?.map(
        (item) => item.totalDirectPointOfSalesQuantity
      ),
    },
    {
      name: "Innovation NPD",
      values: forecastDecompositionData?.map(
        (item) => item.totalInnovationNPDQuantity
      ),
    },
    {
      name: "List Price",
      values: forecastDecompositionData?.map(
        (item) => item.totalListPriceQuantity
      ),
    },
    {
      name: "DA Rate",
      values: forecastDecompositionData?.map(
        (item) => item.totalDARateQuantity
      ),
    },
    {
      name: "Market Rate",
      values: forecastDecompositionData?.map(
        (item) => item.totalMarketShareQuantity
      ),
    },
    {
      name: "Internal Inventory Availability",
      values: forecastDecompositionData?.map(
        (item) => item.totalInternalInventoryAvailabilityQuantity
      ),
    },
    {
      name: "External Inventory Availability",
      values: forecastDecompositionData?.map(
        (item) => item.totalExternalInventoryAvailabilityQuantity
      ),
    },
    {
      name: "Raw Material Inventory",
      values: forecastDecompositionData?.map(
        (item) => item.totalRawMaterialInventoryQuantity
      ),
    },
    {
      name: "Purchase Orders",
      values: forecastDecompositionData?.map(
        (item) => item.totalPurchaseOrdersQuantity
      ),
    },
    {
      name: "Mobility",
      values: forecastDecompositionData?.map(
        (item) => item.totalMobilityQuantity
      ),
    },
  ];

  const currentTableData = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <div className="p-6 text-white">
      <div className="fd-table-holder">
        <table className="text-xs font-bold">
          <thead>
            <tr className="bg-ternary-bg">
              <th className="px-4 py-4 border border-primary-border">
                Drivers
              </th>
              {weekLabels?.map((week) => (
                <th
                  key={uniqueKey('decomposition_key_week_label')}
                  className="px-4 py-2 border border-primary-border"
                >
                  {week?.toUpperCase()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentTableData?.map((row) => (
              <tr key={uniqueKey('decomposition_rowIndex')} className="bg-ternary-bg">
                <td className="border-l border-t border-b border-primary-border p-4">
                  {row?.name}
                </td>
                {row?.values?.map((value) => (
                  <td
                  key={uniqueKey('decomposition_colIndex')}
                    className="border-l border-t border-b border-primary-border p-4"
                  >
                    {typeof value === "number" ? Math.trunc(value) : value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ForecastDecomposeTable;
