import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setResultVisualizationExpFilter } from "../../store/filterSlice";
import { Chip, ListItemText, ThemeProvider } from "@mui/material";
import { filterOptions, theme } from "../../components/Filters/FiltersCommon";

type InputTypeProps = {
  itemList?: string[];
  itemType: string;
};

export default function MultipleSelectVisualizationExp(
  itemsProps: Readonly<InputTypeProps>
) {
  const dispatch = useDispatch();
  const filter = useSelector(
    (state: RootState) => state.resultVisualizationExpFilter
  );

  const handleChange = (event: React.SyntheticEvent, value: string[]) => {
    let processedValue = value;
    if (itemsProps.itemType === "keyAccount") {
      processedValue = value.map((option) => {
        const hyphenIndex = option.indexOf("-");
        return hyphenIndex > -1
          ? option.substring(hyphenIndex + 1).trim()
          : option;
      });
    }
    let itemKey = itemsProps.itemType as keyof typeof filter;
    if (itemKey === "keyAccount") {
      dispatch(
        setResultVisualizationExpFilter({
          key: "keyAccountLabel",
          value: value,
        })
      );
    }

    dispatch(
      setResultVisualizationExpFilter({ key: itemKey, value: processedValue })
    );
  };

  const selectedCount =
    filter[itemsProps.itemType as keyof typeof filter]?.length || 0;

  React.useEffect(() => {
    let itemKey = itemsProps?.itemType as keyof typeof filter;
    if (filter[itemKey]?.length > 0) {
      dispatch(
        setResultVisualizationExpFilter({
          key: itemKey,
          value: filter[itemsProps.itemType as keyof typeof filter],
        })
      );
    }
  }, [filter, itemsProps?.itemType, dispatch]);

  return (
    <div className="flex">
      <ThemeProvider theme={theme}>
        <Autocomplete
          role="select-autocomplete"
          multiple
          // limitTags={1}
          filterOptions={filterOptions}
          options={itemsProps?.itemList ?? []}
          value={
            itemsProps?.itemType === "keyAccount"
              ? filter?.keyAccountLabel
              : filter[itemsProps?.itemType as keyof typeof filter] ?? []
          }
          onChange={handleChange}
          disableCloseOnSelect
          renderTags={(selected, getTagProps) => {
            const displayTags = selected.slice(0, 5);
            const hiddenCount = selected.length - 5;

            return [
              ...displayTags.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
              )),
              hiddenCount > 0 && (
                <Chip
                  key="more"
                  label={`+${hiddenCount}`}
                  sx={{
                    backgroundColor: "#2196F3",
                    color: "white",
                    borderRadius: "8px",
                    padding: "4px",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                />
              ),
            ];
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option}>
              <Checkbox checked={selected} />
              <ListItemText primary={option} />
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select"
              InputProps={{
                ...params.InputProps,
              }}
              sx={{
                "& .MuiInputBase-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#616161",
                },
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "none",
                  },
              }}
            />
          )}
          sx={{
            width: "90%",
            "& .MuiAutocomplete-tag": {
              color: "white",
            },
          }}
        />
      </ThemeProvider>
    </div>
  );
}
