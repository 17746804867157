import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { ApexOptions } from "apexcharts";

interface SeriesData {
  name: string;
  type: "column" | "line";
  data: number[];
  color: string;
}

interface LineColumnChartProps {
  seriesData?: SeriesData[];
  columnWidth?: string;
  labels?: string[];
}

const LineColumnChartExp: React.FC<LineColumnChartProps> = ({
  seriesData = [],
  columnWidth = "50%",
  labels = [],
}) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      // Reverse the data of seriesData
      const reversedSeriesData = seriesData.map((series) => ({
        ...series,
        data: [...series.data],
      }));

      const options = {
        series:
          reversedSeriesData.length > 0
            ? reversedSeriesData
            : [{ name: "Default", data: [], type: "line", color: "#fff" }],
        chart: {
          height: 250,
          type: "line",
          background: "#1D1D1D",
        },
        plotOptions: {
          bar: {
            columnWidth: columnWidth,
            borderRadius: 3,
            borderRadiusApplication: "top",
          },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: labels.length > 0 ? labels : ["N/A"],
        },
        yaxis: [
          {
            labels: {
              style: {
                colors: "white",
              },
              formatter: (value: number) => `${value}%`,
            },
            title: {
              text: "Percentage",
            },
            opposite: true,
          },
          {
            title: {
              text: "Volume",
            },
          },
        ],
        theme: {
          mode: "dark",
        },
        grid: {
          borderColor: "#616161",
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          labels: {
            colors: "white",
          },
          markers: {
            shape: "circle",
            strokeWidth: 0,
            offsetX: -2,
          },
        },

        tooltip: {
          y: {
            formatter: (
              value: number,
              { seriesIndex, w }: { seriesIndex: number; w: any }
            ) => {
              const seriesYAxisIndex = w.config.series[seriesIndex].yAxisIndex;
              if (seriesYAxisIndex === 0) {
                return `${value}%`;
              }
              return value;
            },
          },
        },
      };

      try {
        const chart = new ApexCharts(chartRef.current, options);
        chart.render();

        return () => {
          chart.destroy();
        };
      } catch (error) {
        console.error("Error initializing chart:", error);
      }
    }
  }, [seriesData, columnWidth, labels]);

  return <div className="pt-3" ref={chartRef}></div>;
};

export default LineColumnChartExp;
